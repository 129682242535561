

export const DEFUALT_AVATAR = "https://www.svgrepo.com/show/446483/avatar.svg";

export const BRAND = {
  LOGO: require("@assets/logo.png"),
  LOGO_WHITE: require("@assets/white-logo.png"),
  FOOTER_LOGO: require("@assets/footer-image.png"),
  NAME: "Brand Name",
  SHORT_NAME: "BN",
};

export const INPUT_TYPES = [
  "Text Input",
  "Number Input",
  "Select",
  "Text Area",
  "Date",
  "Checkbox",
  // "File",
  "Switch",
  // "Multiselect",
  "RichText"
];

export const INPUT_OPTIONS = {
  TEXT_INPUT: "Text Input",
  NUMBER_INPUT: "Number Input",
  SELECT: "Select",
  TEXT_AREA: "Text Area",
  DATE: "Date",
  CHECKBOX: "Checkbox",
  FILE: "File",
  SWITCH: "Switch",
  MULTISELECT: "Multiselect",
  RICHTEXT : "RichText"
};

export interface farmerType {
  name:string,
  code:string
}
export const FARMERS: farmerType[] = [
  {name:"ALHASSAN",code:"AL"},
  {name:"ESTHER",code:"ES"},
  {name:"KABILE",code:"KA"},
  {name:"KUMAH (NIMDEE HYEREN)",code:"KU"},
  {name:"SAMPA",code:"SA"},
  {name:"STEPHEN",code:"ST"},
  {name:"USIBRAS",code:"UR"},
];
