import { ADMIN_NAVIGATOR, RoutePropType } from "@/constants/navigators";
import { privilegeType } from "@/graphql/API";

/**
 * check the role priviledges against the predefined routes.
 * if user role-privilege id match route id
 * and if user-privilege selected=true. render the route in the sidebar
 * @param rolePrivileges 
 * @returns 
 */
export const renderNavigator = ( rolePrivileges: privilegeType[]) :RoutePropType[] => {
  return ADMIN_NAVIGATOR.filter( (route:RoutePropType , index, routeArray) => {

    const routeIndex:number =rolePrivileges.findIndex( privilege => privilege.id === route.id);

    
    if( routeIndex !== -1 && rolePrivileges[routeIndex].selected){
      return true;
    }else{
      return false
    }
  })
 
};
