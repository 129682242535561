import React, { ChangeEvent } from "react";
import {
  Checkbox as CheckInput,
  CheckboxChangeEvent,
  CheckboxProps,
} from "primereact/checkbox";
import { useField } from "formik";
import { classNames } from "primereact/utils";

interface Props {
  id: string;
  label?: string;
  isRequired?: boolean;
  icon?: string;
  helperText?: string;
}

type InputProps = Props & CheckboxProps;

const Checkbox = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { id, label, isRequired, icon, helperText, } = props;

    const [field, meta] = useField(id);
    const { value, onBlur, onChange } = field;
    const { error, touched } = meta;


    return (
      <div className="flex flex-column gap-2 p-fluid col">
        <div className='flex gap-3'>
          <label
            htmlFor={id}
            className={classNames({
              "p-error": touched && error,
            })}
          >
            {label} {isRequired && <span className="p-error">*</span>}
          </label>

          <span className={`${!!icon && "p-input-icon-left"}`}>
            <i className={icon} />
            <CheckInput
              {...props}
              checked={(value === 'true' || value === true) ? true : false}
              onBlur={onBlur(id)}
              onChange={(e) => {
                onChange({
                  target: {
                    id,
                    value: e.checked,
                  },
                } as unknown as ChangeEvent<CheckboxChangeEvent>);
              }}
           />
          </span>
        </div>
        {helperText && <small className="p-d-block">{helperText}</small>}
        {touched && error && <small className="p-error">{error}</small>}
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";
export default Checkbox;
