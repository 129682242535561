import Input from "@/components/forms/Input";
import Select from "@/components/forms/Select";
import { UserValidationSchema } from "@/constants/validationSchema";
import { CreateUsersInput, UpdateUsersInput ,users} from "@/graphql/API";
import { useCombinedStores } from "@/zustand/stores/index";
import { USER_ACTION_TYPES_ENUM, UserType } from "@/zustand/types/userTypes";
import { FormikProvider, useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import React from "react";
import { toast } from "react-toastify";

interface Props {
  show: boolean;
  onHide: () => void;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EditUser({ show, onHide, setShowEdit }: Props) {

  const USER_ACTION_TYPE = useCombinedStores( (state) => state.USER_ACTION_TYPE );
  const roles = useCombinedStores( (state) => state.roles );
  const user = useCombinedStores( (state) => state.user );
  const getUsers = useCombinedStores( (state) => state.getUsers );
  const addUser = useCombinedStores( (state) => state.addUser );
  const updateUser = useCombinedStores( (state) => state.updateUser );
  const isAddUserLoading = useCombinedStores( (state) => state.isAddUserLoading );
  const isUpdateUserLoading = useCombinedStores( (state) => state.isUpdateUserLoading );
  const errorMessageUser = useCombinedStores( (state) => state.errorMessageUser );
  const resetActionTypeUser = useCombinedStores( (state) => state.resetActionTypeUser );
 
  const isUpdate:boolean = user.email ? true : false;

  const data = {
    firstname: user?.first_name ?? "",
    lastname: user?.last_name ?? "",
    email: user.email ?? "",
    phone: user.phone ?? "",
    role_id: user?.role_id ?? "2",
    isActive: user.is_active ?? true
  };

  const formik = useFormik({
    initialValues: {
      first_name: data?.firstname ,
      last_name: data?.lastname ,
      email: data?.email ,
      phone: data?.phone ,
      role_id: data?.role_id ,
      is_active: data?.isActive

    },

    validationSchema: UserValidationSchema,
    enableReinitialize: true,
    onSubmit: (values: UserType) => {
      //if updating
      if(isUpdate){
        const updatedUserObject:UpdateUsersInput ={
          email:user.email,
          ...values
        };
        updateUser(updatedUserObject);

      }else{
        //if adding new user
        const newUserObject:CreateUsersInput ={
          
          ...values,
          email: values.email
        };
        
        addUser(newUserObject);
      }
     
    },
  });
  const { handleSubmit, values, errors } = formik;

  

  //listener for AUTH_ACTION_TYPE
  React.useEffect(()=>{
    switch (USER_ACTION_TYPE) {
      case USER_ACTION_TYPES_ENUM.ADD_USER_SUCCESS:
        getUsers();
        setShowEdit(false);
        toast.success(`User ${isUpdate ? "Updated" : "Added"} Successfully`);
        resetActionTypeUser();
        break;
      case USER_ACTION_TYPES_ENUM.ADD_USER_ERROR:
        toast.error(errorMessageUser);
        resetActionTypeUser();
        break;
      case USER_ACTION_TYPES_ENUM.UPDATE_USER_SUCCESS:
        // setShowEdit(false);
        toast.success(`User ${isUpdate ? "Updated" : "Added"} Successfully`);
        resetActionTypeUser();
        break;
      case USER_ACTION_TYPES_ENUM.UPDATE_USER_ERROR:
        toast.error(errorMessageUser);
        resetActionTypeUser();
        break;
    
      default:
        break;
    }
  },[USER_ACTION_TYPE]);

  return (
    <Dialog
      header={isUpdate ? "Edit User" : "Add User"}
      visible={show}
      onHide={onHide}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      blockScroll={true}
    >
      <FormikProvider value={formik}>
        <div className="flex flex-column gap-4">
          <section className="flex flex-row gap-4 grid">
            <Input
              id="first_name"
              label="First Name"
              placeholder="eg. John"
              isRequired
            />
            <Input
              id="last_name"
              label="Last Name"
              placeholder="eg. John"
              isRequired
            />
          </section>

          <Input
            id="email"
            icon="pi pi-envelope"
            label="Email"
            placeholder="eg. name@company.com"
            isRequired
          />

          <Input
            id="phone"
            icon="pi pi-phone"
            label="Phone Number"
            placeholder="eg. 0200000000"
            isRequired
            type="tel"
            pattern="[0-9]{10}"
            maxLength={10}
          />

          <section className="flex flex-row gap-5 align-items-center">
            <Select
              id="role_id"
              placeholder="Select Role"
              label="Role"
              isRequired
              options={roles}
              optionLabel="name"
              optionValue="id"
            />

            <div className="col flex flex-column gap-2">
              <label htmlFor="status" className="p-d-block">
                Status
              </label>

              <div className="flex flex-row gap-4 align-items-center">
                <p>{values.is_active  ? "Active" : "Inactive"}</p>
                <InputSwitch
                  id="is_active"
                  checked={values.is_active}
                  defaultChecked={values.is_active ? true : false}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "is_active",
                      e.value ? true : false
                    );
                  }}
                />
              </div>
            </div>
          </section>

          <Button
            label={isUpdate ? "Update User" : "Add User"}
            className="text-white mt-5"
            loading={isAddUserLoading || isUpdateUserLoading}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          />
        </div>
      </FormikProvider>
    </Dialog>
  );
}
