import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

//slices
import { createAuthSlice } from "../slices/authSlice";
import { createUserSlice } from "@/zustand/slices/userSlice";
import { createRoleSlice } from "@/zustand/slices/roleSlice";
import { createCteSlice } from "@/zustand/slices/cteSlice";
import { createProductSlice } from "@/zustand/slices/productSlice";
import { createFlowSlice } from "@/zustand/slices/flowSlice";
import { createBatchSlice } from "@/zustand/slices/batchSlice";

import { CombinedStoresTypes } from "../types";

export const useCombinedStores = create<CombinedStoresTypes>()(
  persist(
    (...set) => ({
      ...createAuthSlice(...set),
      ...createUserSlice(...set),
      ...createRoleSlice(...set),
      ...createProductSlice(...set),
      ...createCteSlice(...set),
      ...createFlowSlice(...set),
      ...createBatchSlice(...set),
    }),
    {
      name: "globalStore",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
