import { farmerType } from "@/constants/options";
import { ProductType } from "@/zustand/types/productTypes";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React from "react";

interface Props {
  products: ProductType[];
  farmers: farmerType[];
  show: boolean;
  onHide: any;
  onCreate: (date:Date ,farmer:farmerType, product:ProductType)=>void;
  isLoading: boolean
}
/**
 * Modal component for creatig a new traceability batch
 * @param param0 
 * @returns 
 */
export default function NewBatch({
  products,
  farmers,
  show,
  onHide,
  onCreate,
  isLoading
}: Props) {

  const [selectedProduct, setSelectedProduct] = React.useState<ProductType>();
  const [selectedFarmer, setSelectedFarmer] = React.useState<farmerType>();
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());

  return (
    <Dialog
      header="Create A New Tracking Batch"
      visible={show}
      onHide={onHide}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      blockScroll={true}
    >
      <div className="flex flex-column gap-4">
        <div className="p-fluid flex flex-column gap-2">
          <label>Select Date</label>
        <Calendar
          showIcon
          value={ selectedDate}
          onChange={(e: { value: Date }) => {
            setSelectedDate(e.value);
          }}
          
          showButtonBar
          touchUI
        />
        </div>

        <div className="p-fluid flex flex-column gap-2">
          <label>Select Farmer</label>

          <Dropdown
            value={selectedFarmer}
            options={farmers}
            onChange={(e) =>{ 
              setSelectedFarmer(e.value)
            }}
            optionLabel="name"
          />
        </div>
        <div className="p-fluid flex flex-column gap-2">
          <label>Select Product</label>

          <Dropdown
          
            value={selectedProduct}
            options={products}
            onChange={(e) => {
              setSelectedProduct(e.value)
            }}
            itemTemplate={(option:ProductType)=><>{option?.name} - {option?.variety_name}</>}
            valueTemplate={(option:ProductType)=><>{option?.name} - {option?.variety_name}</>}
            optionLabel="name"
            filter
          />
        </div>

        <div className="p-fluid flex flex-column gap-2">
          
        </div>

        <Button
          label="Create"
          loading={isLoading}
          className="p-button-raised text-white mt-5"
          disabled={!selectedProduct || !selectedFarmer || !selectedDate}
          onClick={()=> onCreate(selectedDate, selectedFarmer, selectedProduct)}
        />
      </div>
    </Dialog>
  );
}
