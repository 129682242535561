import { createBrowserRouter, Navigate } from "react-router-dom";

// layout
import AppLayout from "@/layouts/AppLayout";
// pages
import Dashboard from "@/pages/app/misc/Dashboard";
import ManageUsers from "@/pages/app/users/Users";
import Roles from "@/pages/app/roles/Roles";
import CreateEditRole from "@/pages/app/roles/CreateEditRole";
import Products from "@/pages/app/products/Products";
import Flow from "@/pages/app/flows/Flow";
import CreateEditFlow from "@/pages/app/flows/CreateEditFlow";
import CTES from "@/pages/app/ctes/CTES";
import CreateEditCte from "@/pages/app/ctes/CreateEditCte";
import History from "@/pages/app/history/History";
import HistoryDetails from "@/pages/app/history/HistoryDetails";
import Tracking from "@/pages/app/dataEntry/Tracking";
import TrackingDetails from "@/pages/app/dataEntry/TrackingDetails";

export const AppRouter = createBrowserRouter([
  {
    path: "admin",
    element: <AppLayout />,
    children: [
      {
        path: "",
        element: <Dashboard />
      }, {
        path: "users",
        element: <ManageUsers />
      }, {
        path: "roles",
        element: <Roles />
      }, {
        path: "roles/:id",
        element: <CreateEditRole />
      }, {
        path: "products",
        element: <Products />
      }, {
        path: "flow",
        element: <Flow />
      }, {
        path: "flow/:id",
        element: <CreateEditFlow />
      }, {
        path: "cte",
        element: <CTES />
      }, {
        path: "cte/:id",
        element: <CreateEditCte />
      }, {
        path: "tracking",
        element: <Tracking />
      }, {
        path: "tracking/:id",
        element: <TrackingDetails />
      }, 
      
      {
        path: "history",
        element: <History />
      }, {
        path: "history/:id",
        element: <HistoryDetails />
      }
    ]
  }, {
    path: "*",
    element: <Navigate to="admin" replace />
  }
]);
