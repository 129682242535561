import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";

dayjs.extend(dayOfYear);

export const getBatchNumberByDate = (date: Date) => {
  const currentDay = dayjs(date);
  const year = currentDay.year().toString();
  
  let dayInNumber = currentDay.dayOfYear().toString();
  if (dayInNumber.length < 3) {
    dayInNumber= dayInNumber.padStart(3, "0");
  }

  return year.substring(year.length -2)+"-" + dayInNumber;
};
