import React from "react";
import Header from "@/components/shared/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Details from "@/components/history/Details";
import Timelines from "@/components/history/Timelines";
import TraceabilityHistory from "@/components/history/TraceabilityHistory";
import { useCombinedStores } from "@/zustand/stores";
import Container from "@/components/shared/Container";
import { BATCH_ACTION_TYPES_ENUM } from "@/zustand/types/batchTypes";
import { toast } from "react-toastify";

export default function HistoryDetails() {
  const params = useParams();

  //zustand hooks
  const BATCH_ACTION_TYPE = useCombinedStores((state) => state.BATCH_ACTION_TYPE);
  const batch = useCombinedStores((state) => state.batch);
  const getBatchById = useCombinedStores((state) => state.getBatchById);
  const isGetBatchLoading = useCombinedStores((state) => state.isGetBatchLoading);
  const resetActionTypeBatch = useCombinedStores((state) => state.resetActionTypeBatch);
  const errorMessageBatch = useCombinedStores((state) => state.errorMessageBatch);

  const navigate = useNavigate();

  React.useEffect(() => {
    getBatchById(params.id)
  }, [params.id]);

  React.useEffect(() => {
    switch (BATCH_ACTION_TYPE) {
      case BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_SUCCESS:
        // toast.success("Batch  successfully");
        resetActionTypeBatch();
        break;
      case BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_ERROR:
        toast.error(errorMessageBatch);
        resetActionTypeBatch();
        break;

      default:
        break;
    }

  }, [BATCH_ACTION_TYPE]);

  return (
    <Container loading={isGetBatchLoading}>

      <div className="flex flex-column lg:flex-row">
        <div className="w-full lg:w-7 lg:min-h-screen lg:border-right-1 border-gray-400">
          <Header title="History Details" onBack={() => navigate(-1)} />
          <Details item={batch} />
          <TraceabilityHistory item={batch?.batchKdeData?.items} />
        </div>

        <div className="w-full lg:w-5">
          <Header title="Timelines" />
          <Timelines item={batch?.batchKdeData?.items} />
        </div>
      </div>
    </Container>
  );
}
