import React from "react";
// biome-ignore lint/style/useImportType: <explanation>
import { Editor, EditorTextChangeEvent, EditorProps } from "primereact/editor";
import { useField } from "formik";
import { classNames } from "primereact/utils";

interface Props {
	id: string;
	label?: string;
	isRequired?: boolean;
	icon?: string;
	helperText?: string;
}

type InputProps = Props & EditorProps;

const RichText = React.forwardRef<HTMLInputElement, InputProps>(
	(props, ref) => {
		const { id, label, isRequired, icon, helperText } = props;

		const [field, meta] = useField(id);
		const { value, onBlur, onChange } = field;
		const { error, touched } = meta;

		console.log(value);

		return (
			<div className="flex flex-column gap-2 p-fluid col">
				<label
					htmlFor={id}
					className={classNames({
						"p-error": touched && error,
					})}
				>
					{label} {isRequired && <span className="p-error">*</span>}
				</label>

				<span className={`${!!icon && "p-input-icon-left"}`}>
					<i className={icon} />
					<Editor
						{...props}
						value={value}
						name={id}
						onTextChange={(e: EditorTextChangeEvent) => {
							onChange({
								target: {
									id,
									value: e.htmlValue,
								},
							});
						}}
						onBlur={onBlur}
						className={classNames({
							"p-invalid": touched && error,
						})}
						style={{ height: "320px" }}
					/>
				</span>
				{helperText && <small className="p-help text-500">{helperText}</small>}
				{touched && error && <small className="p-error">{error}</small>}
			</div>
		);
	},
);

RichText.displayName = "Input";
export default RichText;
