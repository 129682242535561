import { StateCreator } from "zustand";

import {
  CreateProductsInput,
  DeleteProductsInput,
  DeleteProductsMutation,
  UpdateProductsInput,
} from "@/graphql/API";
import * as mutations from "@/graphql/mutations";
import * as queries from "@/graphql/queries";
import * as customQueries from "@/graphql/customQueries";
import {
  amplifyCreateData,
  amplifyDeleteData,
  amplifyGetAllData,
  amplifyUpdateData,
} from "@/utils/amplify/api";
import { CombinedStoresTypes } from "@/zustand/types";
import zukeeper from "zukeeper";
import {
  PRODUCT_ACTION_TYPES_ENUM,
  ProductSliceInterface,
  ProductType,
} from "@/zustand/types/productTypes";

export const createProductSlice: StateCreator<
  CombinedStoresTypes,
  [],
  [],
  ProductSliceInterface
> = zukeeper((set, get) => ({
  PRODUCT_ACTION_TYPE: PRODUCT_ACTION_TYPES_ENUM.INITIAL_VALUE,

  isGetProductsLoading: false,
  isAddProductLoading: false,
  isUpdateProductLoading: false,
  isDeleteProductLoading: false,
  errorMessageProduct: "",
  nextTokenProduct: "",

  product: {},
  products: [],

  resetActionTypeProduct: () => {
    set({
      PRODUCT_ACTION_TYPE: PRODUCT_ACTION_TYPES_ENUM.INITIAL_VALUE,
    });
  },

  updateZustandStateProduct: (object: Partial<ProductSliceInterface>) => {
    set(object);
  },

  getProducts: async () => {
    set({
      isGetProductsLoading: true,
    });

    try {
      const success = await amplifyGetAllData(customQueries.listProducts);
      const { items, nextToken } = success.data?.listProducts;
      set({
        isGetProductsLoading: false,
        errorMessageProduct: "",
        PRODUCT_ACTION_TYPE: PRODUCT_ACTION_TYPES_ENUM.GET_PRODUCTS_SUCCESS,
        products: items,
        nextTokenProduct: nextToken,
      });
    } catch (error) {
      set({
        isGetProductsLoading: false,
        errorMessageProduct: error.message,
        PRODUCT_ACTION_TYPE: PRODUCT_ACTION_TYPES_ENUM.GET_PRODUCTS_ERROR,
      });
    }
  },

  addProduct: (newProduct: CreateProductsInput) => {
    set({
      isAddProductLoading: true,
    });

    //add product to cognito
    amplifyCreateData(mutations.createProducts, newProduct)
      .then((successValue) => {
        const result = successValue.data.createProducts;


        set({
          isAddProductLoading: false,
          PRODUCT_ACTION_TYPE: PRODUCT_ACTION_TYPES_ENUM.ADD_PRODUCT_SUCCESS,
          product: result,
        });
      })
      .catch((error) => {
        set({
          isAddProductLoading: false,
          PRODUCT_ACTION_TYPE: PRODUCT_ACTION_TYPES_ENUM.ADD_PRODUCT_ERROR,
          errorMessageProduct: error.message,
        });
      });
  },
  updateProduct: async (product: UpdateProductsInput) => {
    set({
      isUpdateProductLoading: true,
    });

    try {
      const successValue = await amplifyUpdateData(
        mutations.updateProducts,
        product
      );
      const result = successValue.data.updateProducts;
      const newProducts = updateProductStore(get().products, result);
      set({
        isUpdateProductLoading: false,
        PRODUCT_ACTION_TYPE: PRODUCT_ACTION_TYPES_ENUM.UPDATE_PRODUCT_SUCCESS,
        product: result,
        products: newProducts,
      });
      return result;
    } catch (error) {
      const errorMessage = error.errors
        ? error.errors[0].message
        : error.message;

      set({
        isUpdateProductLoading: false,
        PRODUCT_ACTION_TYPE: PRODUCT_ACTION_TYPES_ENUM.UPDATE_PRODUCT_ERROR,
        errorMessageProduct: errorMessage,
      });
    }
  },

  // deleteProduct: (product: DeleteProductsInput) => { },
  deleteProduct: async (product: DeleteProductsInput) => {
    set({
      isDeleteProductLoading: true,
    });
    try {
      const response = await amplifyDeleteData<DeleteProductsMutation>(
        mutations.deleteProducts,
        product.id
      );
      set({
        isDeleteProductLoading: false,
        PRODUCT_ACTION_TYPE: PRODUCT_ACTION_TYPES_ENUM.DELETE_PRODUCT_SUCCESS,
        errorMessageProduct: "",
      });
    } catch (error) {
      set({
        isDeleteProductLoading: false,
        PRODUCT_ACTION_TYPE: PRODUCT_ACTION_TYPES_ENUM.DELETE_PRODUCT_ERROR,
        errorMessageProduct: error.message,
      });
    }
  },
}));

/**
 * This function locally updates the zustand store. so changes can relect in real time without reloading
 * @param items
 * @param updatedItem
 * @returns
 */
const updateProductStore = (items: ProductType[], updatedItem: ProductType) => {
  const index = items.findIndex((item) => {
    return item.id === updatedItem.id;
  });
  if (index == -1) return items;

  // Update the item in the local list
  const newitems = [...items];
  newitems[index] = updatedItem;
  return newitems;
};
