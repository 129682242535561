import {
  products,
  CreateProductsInput,
  UpdateProductsInput,
  DeleteProductsInput,
} from "@/graphql/API";

export const enum PRODUCT_ACTION_TYPES_ENUM {
  INITIAL_VALUE = 200,

  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,

  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,

  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_ERROR,

  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_ERROR,

  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,
}

export type ProductType = Partial<products>;

export interface ProductSliceInterface {
  PRODUCT_ACTION_TYPE: PRODUCT_ACTION_TYPES_ENUM;

  isGetProductsLoading: boolean;
  isGetProductLoading: boolean;
  isAddProductLoading: boolean;
  isUpdateProductLoading: boolean;
  isDeleteProductLoading: boolean;

  product: ProductType;
  products: ProductType[];
  errorMessageProduct: string;
  nextTokenProduct: string;

  updateZustandStateProduct: (object: Partial<ProductSliceInterface>) => void;
  getProducts: () => void;
  getProductById: (id: String) => void;
  addProduct: (product: CreateProductsInput) => void;
  updateProduct: (product: UpdateProductsInput) => void;
  deleteProduct: (product: DeleteProductsInput) => void;

  resetActionTypeProduct: () => void;
}
