import { CreateRolesInput, UpdateRolesInput,DeleteRolesInput, roles } from "@/graphql/API"

export const enum ROLE_ACTION_TYPES_ENUM  {
    INITIAL_VALUE = 250,
   
    GET_ROLES_SUCCESS,
    GET_ROLES_ERROR,
    
    GET_ROLE_SUCCESS,
    GET_ROLE_ERROR,
   
    ADD_ROLE_SUCCESS,
    ADD_ROLE_ERROR,

    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_ERROR,

    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_ERROR,
    
}

export type RoleType = Partial<roles>

export interface RoleSliceInterface {
    ROLE_ACTION_TYPE: ROLE_ACTION_TYPES_ENUM,

    isGetRolesLoading: boolean,
    isGetRoleLoading: boolean,
    isAddRoleLoading: boolean,
    isUpdateRoleLoading: boolean,
    isDeleteRoleLoading: boolean,

    role: RoleType,
    roles: RoleType[],
    errorMessageRole:string,
    nextTokenRole:string,
  
    updateZustandStateRole : (object :Partial<RoleSliceInterface>) => void
    getRoles: () => void
    getRoleById: (id:String) => void
    addRole: (role: CreateRolesInput) => void
    updateRole: (role:UpdateRolesInput) => void
    deleteRole: (role: DeleteRolesInput) => void

    resetActionTypeRole: ()=>void
   
  }