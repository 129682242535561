import { Footer } from "@/components/shared/website/Footer";
import Navbar from "@/components/shared/website/Navbar";
import React from "react";

export default function CustomerLayout({
  children,
  isWhiteNav
}: {
    children: React.ReactNode;
    isWhiteNav?: boolean|undefined;
}) {
  return (
    <div className="max-w-screen"  >
      <Navbar isWhiteNav={isWhiteNav} />
      <main
        role="main"
        className=" w-full"
        aria-labelledby="main"
        
      >
        {children}
      </main>
      <Footer />
    </div>
  );
}
