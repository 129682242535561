import { KdeMetaDataInput, PrivilegeTypeInput, kdeMetaData } from "@/graphql/API";
import { Placeholder } from "@aws-amplify/ui-react";

/**
 * Cast the objects of the aray into PrivilegeTypeInput
 * @param items 
 * @returns 
 */
export const createPrivilegeTypeInputArray = (items: any[]): PrivilegeTypeInput[] => {
  // const requiredTypeArray: PrivilegeTypeInput[] = [];

  const requiredTypeArray =  items.map(item => {
    let requiredTypeObject: PrivilegeTypeInput = {
      id: item.id,
      name: item.name,
      selected: item.selected

    }
    return requiredTypeObject
  });

  return requiredTypeArray;
}

export const createKdeMetaDataInputArray = (items: any[]): KdeMetaDataInput[] => {
  // const requiredTypeArray: KdeMetaDataInput[] = [];

  const requiredTypeArray =  items.map((item,index) => {
    let requiredTypeObject: KdeMetaDataInput = {
      index:index,
      input_id: (item?.input_id )? item?.input_id : (new Date().getTime() + index).toString() ,
      default_value: item?.default_value,
      help_message: item?.help_message,
      input_type: item?.input_type,
      is_required: item?.is_required,
      label: item?.label,
      options: item?.options,
      place_holder: item?.place_holder,
      value:item?.value

    }
    
    return requiredTypeObject
  });

  
  return requiredTypeArray;
}

/**
 * KDE data with value. 
 * 
 */
export const createKdeDataWithValuesArray = (
  kdeMetaDataArrayy: kdeMetaData[] , 
  kdeMetaDataKeyValueObject:{[key:string]:string}): kdeMetaData[] =>{
  return kdeMetaDataArrayy.map((kma, index , kdeArray) =>{

    kdeArray[index].value = kdeMetaDataKeyValueObject[kma.input_id]
    return kdeArray[index];

  } )
}