import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};


export const getUsers = /* GraphQL */ `query GetUsers($email: ID!) {
  getUsers(email: $email) {
    email
    search_key
    first_name
    last_name
    phone
    role_id
    role {
      id
      name
      is_active
      privileges {
        id
        name
        selected
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    status
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUsersQueryVariables, APITypes.GetUsersQuery>;

export const listRoles = /* GraphQL */ `query ListRoles(
    $filter: ModelRolesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        is_active
        privileges {
            id
            name
            selected
            __typename
            }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
  ` as GeneratedQuery<
  APITypes.ListRolesQueryVariables,
  APITypes.ListRolesQuery
>;


export const listUsers = /* GraphQL */ `query ListUsers(
  $email: ID
  $filter: ModelUsersFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      search_key
      first_name
      last_name
      phone
      role_id
      role{
        name
      }
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;

export const listCtes =
  /* GraphQL */ `query ListCtes($filter: ModelCtesFilterInput, $limit: Int, $nextToken: String) {
  listCtes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      index
      name
      kde_metadata {
        index
        input_id
        is_required
        label
        place_holder
        default_value
        value
        input_type
        help_message
        options
        __typename
      }
    createdAt
    updatedAt
    __typename
  }
}
  }
` as GeneratedQuery<APITypes.ListCtesQueryVariables, APITypes.ListCtesQuery>;

export const listTraceabilityFlows = /* GraphQL */ `query ListTraceabilityFlows(
  $filter: ModelTraceabilityFlowFilterInput
  $limit: Int
  $nextToken: String
) {
  listTraceabilityFlows(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      is_active
      observers
      inputters
      cteFlow {
        nextToken
        items{
          id
          cte_id
          traceability_flow_id
          position
          cte{
            id
            name
          }
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTraceabilityFlowsQueryVariables,
  APITypes.ListTraceabilityFlowsQuery
>;

export const listProducts = /* GraphQL */ `query ListProducts(
  $filter: ModelProductsFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      code
      variety_name
      variety_code
      traceability_flow_id
      traceability_flow {
        name
      }
      is_active
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsQueryVariables,
  APITypes.ListProductsQuery
>;

export const listBatches = /* GraphQL */ `query ListBatches(
  $filter: ModelBatchFilterInput
  $limit: Int
  $nextToken: String
) {
  listBatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      batch_number
      batch_date
      product_id
      product{
        name
        variety_name
        traceability_flow{
          name
        }
      }
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBatchesQueryVariables,
  APITypes.ListBatchesQuery
>;

export const getBatch = /* GraphQL */ `query GetBatch($id: ID!) {
  getBatch(id: $id) {
    id
    batch_number
    batch_date
    product_id
    status
    createdAt
    updatedAt
    __typename
    batchKdeData {
      items{
        id
        position
        inputted_by
        inputter_id
        cte_id
        createdAt
        updatedAt
        cte{
          name
        }
        
        kdes{
          label
          input_id
          value
        }
      }
    }
    product {
      id
      name
      variety_name
      traceability_flow{
          name
          observers
          inputters
          cteFlow{
            items{
              id
              position
              cte{
                id
                name
                kde_metadata{
                  label
                  input_id
                  input_type
                  is_required
                  place_holder
                  help_message
                  options
                }
              }
            }
          }
        }
      }
    }
    
  }

` as GeneratedQuery<APITypes.GetBatchQueryVariables, APITypes.GetBatchQuery>;

export const batchesByBatch_numberAndCreatedAt = /* GraphQL */ `query BatchesByBatch_numberAndCreatedAt(
  $batch_number: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelbatchFilterInput
  $limit: Int
  $nextToken: String
) {
  batchesByBatch_numberAndCreatedAt(
    batch_number: $batch_number
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
    id
    batch_number
    product_id
    status
    createdAt
    updatedAt
    __typename
    batchKdeData {
      items{
        id
        position
        inputted_by
        inputter_id
        cte_id
        createdAt
        updatedAt

        cte{
          name
        }
        
        kdes{
          label
          input_id
          value
        }
      }
    }
    product {
      id
      name
      variety_name
      traceability_flow{
          name
          observers
          inputters
          cteFlow{
            items{
              id
              position
              cte{
                id
                name
                kde_metadata{
                  label
                  input_id
                  input_type
                  is_required
                  place_holder
                  help_message
                  options
                }
              }
            }
          }
        }
      }
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BatchesByBatch_numberAndCreatedAtQueryVariables,
  APITypes.BatchesByBatch_numberAndCreatedAtQuery
>;