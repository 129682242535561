import React from "react";
import {
  Calendar,
  CalendarProps,
  CalendarViewChangeEvent,
} from "primereact/calendar";
import { useField } from "formik";
import { classNames } from "primereact/utils";

interface Props {
  id: string;
  label?: string;
  isRequired?: boolean;
  icon?: string;
  helperText?: string;
}

type InputProps = Props & CalendarProps;

const Date = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { id, label, isRequired, icon, helperText } = props;

  const [field, meta] = useField(id);
  const { value, onBlur, onChange } = field;
  const { error, touched } = meta;


  return (
    <div className="flex flex-column gap-2 p-fluid col">
      <label
        htmlFor={id}
        className={classNames({
          "p-error": touched && error,
        })}
      >
        {label} {isRequired && <span className="p-error">*</span>}
      </label>

      <span className={`${!!icon && "p-input-icon-left"}`}>
        <i className={icon} />
        {/* @ts-ignore */}
        <Calendar
          {...props}
          showIcon
          value={value ? new window.Date(value) : new window.Date()}
          onChange={(e: { value: Date }) => {
            onChange({
              target: {
                id,
                value: e.value,
              },
            }) as unknown as React.ChangeEvent<CalendarViewChangeEvent>;
          }}
          onBlur={onBlur(id)}
          className={classNames({
            "p-invalid": touched && error,
          })}
          showButtonBar
          touchUI
        />
      </span>
      {helperText && <small className="p-help text-500">{helperText}</small>}
      {touched && error && <small className="p-error">{error}</small>}
    </div>
  );
});

Date.displayName = "Date";
export default Date;
