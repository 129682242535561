import { CreateBatchKdeDataInput, UpdateBatchKdeDataInput, batchKdeData, kdeMetaData } from "@/graphql/API";
import { FormikProvider, useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import renderFormInput from "../shared/renderFormInput";
import { useCombinedStores } from "@/zustand/stores";
import { createKdeDataWithValuesArray } from "@/utils/graphqlInputArrayConversions";
import { BATCH_ACTION_TYPES_ENUM } from "@/zustand/types/batchTypes";
import { toast } from "react-toastify";
import * as Yup from "yup";

interface InputFormProps {
  batch_id: string,
  cte_id: string,
  batch_number: string,
  inputted_by: string,
  inputter_id: string,
  position: number,
  kdeMetadataArray: kdeMetaData[],
  batchKdeData: batchKdeData
};

/**
 * 
 *This component Render a list of input forms using the CTE metadata
 and populated the input fields using BatchKDE data if it exists
 * 
 */
export default function KDEInputForm({ cte_id, batch_id, batch_number, inputted_by, inputter_id, position, kdeMetadataArray, batchKdeData }: InputFormProps) {

  //zustand hooks
  const BATCH_ACTION_TYPE = useCombinedStores((state) => state.BATCH_ACTION_TYPE);
  const addKDEData = useCombinedStores((state) => state.addKDEData);
  const updateKDEData = useCombinedStores((state) => state.updateKDEData);
  const isAddKDEDataLoading = useCombinedStores((state) => state.isAddKDEDataLoading);
  const isUpdateKDEDataLoading = useCombinedStores((state) => state.isUpdateKDEDataLoading);
  const resetActionTypeBatch = useCombinedStores((state) => state.resetActionTypeBatch);
  const errorMessageBatch = useCombinedStores((state) => state.errorMessageBatch);



  /*
    Constructs initial values object from the kdeData
  */
  const kdeInitialValues = React.useMemo(() => {
    const obj: any = {};

    for (let index = 0; index < kdeMetadataArray.length; index++) {
      const KDEmetaData = kdeMetadataArray[index];

      //check if a value exists for this kdeMetaData in batchKdeData
      const correspondingKde = batchKdeData?.kdes.find(k => k.input_id === KDEmetaData.input_id)

      const id = KDEmetaData.input_id;
      const val = (correspondingKde) ? correspondingKde.value : KDEmetaData.default_value;

      obj[id] = val;
    }

    return obj;
  }, [batchKdeData?.kdes, kdeMetadataArray]);

  /*
   Constructs validation schema object from the kdes array
 */
  const validationObject = React.useMemo(() => {
    const obj: any = {};
    kdeMetadataArray.forEach((item: kdeMetaData) => {
      if (item.is_required) {
        const id = item.input_id;

        obj[id] = Yup.string().required(`${item.label} is required`);
      }
    });
    
    return Yup.object().shape(obj);

  }, [kdeMetadataArray]);


  const formik = useFormik({
    initialValues: kdeInitialValues,
    validationSchema: validationObject,
    enableReinitialize: true,
    onSubmit: (values: { [key: string]: string }) => {

      if (batchKdeData?.id) {

        const kdeData: UpdateBatchKdeDataInput = {
          id: batchKdeData.id,
          inputted_by: inputted_by,
          inputter_id: inputter_id,
          batch_number: batch_number,
          position: position,
          kdes: createKdeDataWithValuesArray(kdeMetadataArray, values)
        }

        updateKDEData(kdeData);

      } else {

        const kdeData: CreateBatchKdeDataInput = {
          batch_id: batch_id,
          inputted_by: inputted_by,
          inputter_id: inputter_id,
          batch_number: batch_number,
          cte_id: cte_id,
          position: position,
          kdes: createKdeDataWithValuesArray(kdeMetadataArray, values)
        }

        addKDEData(kdeData);
      }

    },
  });

  const { handleSubmit } = formik;


  console.log("formik", formik.values)


  //listener for BATCH_ACTION_TYPE
  React.useEffect(() => {

    switch (BATCH_ACTION_TYPE) {
      case BATCH_ACTION_TYPES_ENUM.ADD_KDE_DATA_SUCCESS:
        toast.success("Data capture successfull");
        resetActionTypeBatch();

        break;
      case BATCH_ACTION_TYPES_ENUM.UPDATE_KDE_DATA_SUCCESS:
        toast.success("Data updated successfull");
        resetActionTypeBatch();

        break;
      case BATCH_ACTION_TYPES_ENUM.ADD_KDE_DATA_ERROR:
        toast.error(errorMessageBatch);
        resetActionTypeBatch();
        break;

      case BATCH_ACTION_TYPES_ENUM.UPDATE_KDE_DATA_ERROR:
        toast.error(errorMessageBatch);
        resetActionTypeBatch();
        break;

      default:
        break;
    }
  }, [BATCH_ACTION_TYPE]);

  return (
    <div>

      <div className="flex flex-column align-items-center my-3 ">

        <FormikProvider value={formik}>
          <div className="flex flex-column  gap-3 my-5 w-11 lg:w-8 ">

            {kdeMetadataArray?.map((kdeMetadata: kdeMetaData, index: number) => {
              return (
                <div key={index}>

                  {renderFormInput({
                    type: kdeMetadata.input_type,
                    id: kdeMetadata.input_id,
                    label: kdeMetadata.label,
                    isRequired: kdeMetadata.is_required,
                    placeholder: kdeMetadata.place_holder,
                    options: kdeMetadata.options,
                    helperText: kdeMetadata.help_message,
                    value: kdeMetadata?.value ?? kdeMetadata.default_value,
                  })}
                </div>
              );
            })}
          </div>

        </FormikProvider>
      </div>
      <div className="flex flex-column align-items-center gap-3 mt-4">
        <Button
          loading={isAddKDEDataLoading || isUpdateKDEDataLoading}
          label={batchKdeData?.id ? "Update Form" : "Submit Form"}
          className="text-white"
          onClick={() => {
            handleSubmit()
          }}
        />

      </div>
    </div>
  );
}
