import { UserType } from "./userTypes"

export const enum AUTH_ACTION_TYPES_ENUM  {
    INITIAL_VALUE = 0,

    LOGIN_WITH_PASSWORD_START,
    LOGIN_WITH_PASSWORD_SUCCESS,
    LOGIN_WITH_PASSWORD_ERROR,
   
    CONFIRM_SIGNUP_START,
    CONFIRM_SIGNUP_SUCCESS,
    CONFIRM_SIGNUP_ERROR,

    CONFIRM_SIGNIN_START,
    CONFIRM_SIGNIN_SUCCESS,
    CONFIRM_SIGNIN_ERROR,
    
    GET_AUTHENTICATED_USER_SUCCESS,
    GET_AUTHENTICATED_USER_ERROR,
    
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    
    FORGOT_PASSWORD_OTP_CONFIRM_SUCCESS,
    FORGOT_PASSWORD_OTP_CONFIRM_ERROR,


    SIGNOUT_SUCCESS,
    SIGNOUT_ERROR,

    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
   
}

export interface AuthSliceInterface {
    AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM,

    isChangePasswordLoading: boolean,
    isConfirmSignupLoading: boolean,
    isConfirmSigninLoading: boolean,
    isForgotPasswordLoading: boolean,
    isForgotPasswordOTPPConfirmLoading: boolean,
    isLoginWithPasswordLoading: boolean,
    isSignoutLoading: boolean,
    user_id:string,
    authUser: UserType,
    errorMessageAuth: string,
    isGetAuthenticatedUserLoading : boolean,

    getAuthenticatedUser:  (email:String)=> void,
    reseActionTypeAuth : () =>void
    updateZustandStateAuth : (object : Partial< AuthSliceInterface>)=>void
  
    confirmSignup: (username: string, otp:string) => void
    confirmSignin: (username: string, newPassword:string) => void
    forgotPassword: (email: string) => void
    changePassword: (oldPassword: string, newPassword: string) => void
    forgotPasswordOTPConfirm: (email: string, code: string, newPassword:string) => void
    loginWithPassword: (email: string, password: string) => void
    signout: () => void
  }