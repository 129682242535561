import {
  CreateCtesInput,
  UpdateCtesInput,
  DeleteCtesInput,
  ctes,
} from "@/graphql/API";

export const enum CTE_ACTION_TYPES_ENUM {
  INITIAL_VALUE = 100,

  GET_CTES_SUCCESS,
  GET_CTES_ERROR,

  GET_CTE_SUCCESS,
  GET_CTE_ERROR,
  GET_CTE_EMPTY,

  ADD_CTE_SUCCESS,
  ADD_CTE_ERROR,

  UPDATE_CTE_SUCCESS,
  UPDATE_CTE_ERROR,

  DELETE_CTE_SUCCESS,
  DELETE_CTE_ERROR,
}

export type CteType = Partial<ctes>;

export interface CteSliceInterface {
  CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM;

  isGetCtesLoading: boolean;
  isAddCteLoading: boolean;
  isUpdateCteLoading: boolean;
  isDeleteCteLoading: boolean;

  cte: CteType;
  ctes: CteType[];
  errorMessageCte: string;
  nextTokenCte: string;

  updateZustandStateCte: (object: Partial<CteSliceInterface>) => void;
  getCtes: () => void;
  getCteById: (id: String) => void;
  addCte: (cte: CreateCtesInput) => void;
  updateCte: (cte: UpdateCtesInput) => void;
  deleteCte: (cte: DeleteCtesInput) => void;
  updateCteStatus: (id: string, status: true | false) => void;

  resetActionTypeCte: () => void;
}
