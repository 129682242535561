import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import If from "../../../components/shared/If";
import { useCombinedStores } from "@/zustand/stores/index";

interface Props {
  show: boolean;
  onHide: () => void;
  onEdit: () => void;
}

export default function UserDetails({ show, onHide, onEdit }: Props) {

  const user = useCombinedStores( (state) => state.user );

  /*
   *
   * //TODO: Replace data object with correct user object
   *
   */

  const data = {
    ID: user?.id,
    Name: user?.first_name + " "+user?.last_name,
    Email: user?.email,
    "Phone Number": user?.phone,
    status: user?.status,
  };

  const assigedTo = [
    {
      product: "Cashew",
      ctes: ["CTE 1", "CTE 2"],
    },
    {
      product: "Choclate",
      ctes: ["CTE 2"],
    },
    {
      product: "Banana",
      ctes: ["CTE 3"],
    },
  ];

  return (
    <Dialog
      header="User Details"
      visible={show}
      onHide={onHide}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      blockScroll={true}
    >
      <section className="flex flex-column gap-3">
        {Object.values(data).map((value, index) => {
          return (
            <div
              key={index}
              className="flex flex-row justify-content-between align-items-center gap-5"
            >
              <span>{Object.keys(data)[index]}</span>
              <span
                className={`
             
              ${
                value === "Active"
                  ? "bg-green-100 text-green-600 py-1 px-3 border-round"
                  : value === "Inactive"
                  ? "bg-red-100 text-red-600 py-1 px-3 border-round"
                  : "bg-transparent text-color "
              }
              `}
              >
                {value}
              </span>
            </div>
          );
        })}
      </section>

      <div className="border-none w-full mt-5 mb-4 p-1 border-round bg-gray-400 align-self-center" />

      <section>
        <h3>Assigned To</h3>

        <div className="flex flex-column gap-3">
          <If
            condition={assigedTo.length > 0}
            then={
              <React.Fragment>
                {assigedTo.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-row justify-content-between align-items-center gap-5"
                    >
                      <span>{value.product}</span>
                      <div className="flex gap-2">
                        {value.ctes.map((cte: any, index: number) => {
                          return (
                            <span
                              key={index}
                              className="bg-gray-200 text-color py-1 px-3 border-round"
                            >
                              {cte}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            }
            else={<div>Looks like there are no assignments to this user</div>}
          />
        </div>

        <div className="flex flex-row justify-content-center mt-5">
          <Button
            className="align-self-center p-button-outlined"
            label="Edit User"
            icon="pi pi-pencil"
            onClick={onEdit}
          />
        </div>
      </section>
    </Dialog>
  );
}
