import * as Yup from "yup";

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(
      "Email is invalid. Please enter a valid email address (e.g. name@domain.com)"
    )
    .required("Email is invalid. Please enter a valid email address"),
  password: Yup.string().required("Required"),
});

export const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(
      "Email is invalid. Please enter a valid email address (e.g. name@domain.com)"
    )
    .required("Email is invalid. Please enter a valid email address"),
});

export const UserValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email(
      "Email is invalid. Please enter a valid email address (e.g. name@domain.com)"
    )
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(
      /^\d{10}$/,
      "Phone number is invalid. Please enter a valid phone number (e.g. 1234567890)"
    ),

  role_id: Yup.string().required("Role is required"),
  is_active: Yup.boolean().required("Status is required.")
});

export const RoleValidationSchema = Yup.object().shape({
  name: Yup.string().required("Role name is required"),
  is_active: Yup.boolean().required("Status is required."),
  privileges: Yup.array().required("Privilege is required"),
 
});

export const OTPValidationSchema = Yup.object().shape({
  otp: Yup.string().required("Enter OTP"),
  password: Yup.string().required("Required"),
});
export const OTPSignupValidationSchema = Yup.object().shape({
  otp: Yup.string().required("Enter OTP"),
  email: Yup.string().required("Required"),
});

export const ChangePasswordValidationSchema = Yup.object().shape({
  old_password: Yup.string().required("Current Password is required"),
  new_password: Yup.string().required("New Password is required"),
  confirm_password: Yup.string().required("Confirm your New Password"),
});

export const ConfirmNewSigninValidationSchema = Yup.object().shape({
  new_password: Yup.string().required("New Password is required"),
  confirm_password: Yup.string().required("Password Confirmation is required")
     .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
});
