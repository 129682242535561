import React from "react";
import Sidebar from "@components/shared/SideBar";
import NavBar from "@/components/shared/NavBar";
import { Outlet, useNavigate } from "react-router-dom";
import Profile from "@/components/shared/Profile";
import useSettingsStore from "@/theme/settings";
import { useCombinedStores } from "@/zustand/stores";

export default function AppLayout() {

   //zustand hooks
   const authUser = useCombinedStores((state) => state.authUser);
   const signout = useCombinedStores((state) => state.signout);
 
  const [showProfile, toggleProfile] = React.useReducer((s) => !s, false);
  const setSession = useSettingsStore((state) => state.setSession);
  const navigate = useNavigate();
  const data = {
    badgelabel: 1,
    name: authUser?.first_name +" " +authUser?.last_name,
    firstname: authUser?.first_name,
    lastname: authUser?.last_name,
    email: authUser?.email,
    initials: authUser?.first_name?.substring(0,1)+authUser?.last_name?.substring(0,1),
    role: authUser?.role?.name ?? "admin",
    phone: authUser?.phone,
  };

  /*
   *
   * //TODO: Replace with logout logic
   * //! always naviagte to login page
   */
  const handleLogout = () => {
    signout()
    setSession("inactive");
    navigate("admin/login");
  };

  return (
    <div className="min-h-screen">
      <NavBar
        badgeValue={data.badgelabel}
        name={data.name}
        email={data.email}
        initials={data.initials}
        role={data.role}
        onViewProfile={toggleProfile}
        onLogout={handleLogout}
      />
      <div className="h-full flex flex-column lg:flex-row">
        <div>
          <Sidebar role={data.role} />
        </div>
        <div className="flex-1 h-full w-full">
          <Outlet />
        </div>
      </div>

      <Profile show={showProfile} onHide={toggleProfile} user={data} />
    </div>
  );
}
