// import { navMenus } from "@/constants/content";
import { BRAND } from "@/constants/options";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
// import { Menubar } from "primereact/menubar";
import { useNavigate } from "react-router-dom";
import React from "react";

const Navbar = ({ isWhiteNav }: { isWhiteNav?: boolean | undefined }) => {
  // const start = (
  //   <Image src={isWhiteNav ? BRAND.LOGO : BRAND.LOGO_WHITE} height="40rem" />
  // );

  const navigate = useNavigate();

  return (
    <div
      className="w-full flex justify-content-between px-3 md:px-5 lg:px-8 py-2"
      style={{
        backgroundColor: `${isWhiteNav === true ? "white" : "#EA088B"}`,
      }}
    >
      <Image src={isWhiteNav ? BRAND.LOGO : BRAND.LOGO_WHITE} height="40rem" />

      <Button
        label="Login"
        icon="pi pi-user"
        className={`p-button-outlined ${
          isWhiteNav ? "bg-primary" : "bg-white"
        }`}
        onClick={() => navigate("/admin/login")}
      />
      {/* <Menubar
        model={navMenus}
        start={start}
        color="white"
        pt={{
          action: ({ props, state, context }) => ({
            className: `${
              isWhiteNav
                ? "hover:text-primary-400"
                : "text-white-alpha-90 hover:text-black-alpha-90"
            } bg-transparent`,
          }),
          label: ({ props, state, context }) => ({
            className: `${
              isWhiteNav
                ? "text-black-alpha-90 hover:text-primary-400"
                : state.mobileActive
                ? "text-black-alpha-90 hover:text-primary-400"
                : "text-white-alpha-90 hover:text-black-alpha-90"
            } bg-transparent`,
          }),
          popupIcon: ({ props, state, context }) => ({
            className: `${
              isWhiteNav ? "text-black-alpha-90 " : "text-white-alpha-90"
            }`,
            style: {
              backgroundColor: `${isWhiteNav === true ? "white" : "#EA088B"}`,
            },
          }),
        }}
        className="md:px-8 px-4 justify-content-between w-full   border-none border-noround"
        style={{
          backgroundColor: `${isWhiteNav === true ? "white" : "#EA088B"}`,
        }}
      /> */}
    </div>
  );
};

export default Navbar;
