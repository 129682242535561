// dependencies
import { Button } from "primereact/button";
import React from "react";
import { Sidebar as Drawer } from "primereact/sidebar";
import { Image } from "primereact/image";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import SideBar from "./SideBar";
// constants
import { BRAND } from "@/constants/options";
// import ThemeSwitcher from "./ThemeSwitcher";
import { Menu } from "primereact/menu";

interface NavBarProps {
  badgeValue: number;
  initials: string;
  role: string;
  onViewProfile: () => void;
  onLogout: () => void;
  name: string;
  email: string;
}

export default function NavBar({
  badgeValue,
  initials,
  role,
  onViewProfile,
  onLogout,
  name,
  email,
}: NavBarProps) {
  const [openDrawer, toggleDrawer] = React.useReducer((open) => !open, false);
  const [showMenu, toggleMenu] = React.useReducer((open) => !open, false);

  const menu = React.useRef<Menu>(null);

  const items = [
    {
      template: (item, options) => {
        return (
          <div className="p-3 flex gap-2 align-items-center ">
            <Avatar
              label={initials}
              className="bg-gray-500 text-white"
              shape="circle"
            />
            <div className="flex flex-column">
              <span className="text-md font-medium">{name ?? "username"}</span>
              <span className="text-xs">{email ?? "name@company.com"}</span>
            </div>
          </div>
        );
      },
    },
    {
      separator: true,
    },
    {
      label: "Profile",
      icon: "pi pi-user",
      command: () => {
        setTimeout(() => {
          onViewProfile();
        }, 200);
      },
    },
    {
      separator: true,
    },
    {
      label: "Logout",
      icon: "pi pi-sign-out",
      command: () => {
        onLogout();
        toggleMenu();
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="py-2 px-3 col border-gray-400 border-bottom-1 flex flex-row justify-content-between align-items-center">
        <div className="flex flex-row gap-1 lg:gap-3 align-items-center">
          <Button
            className="px-1 p-button-text lg:hidden"
            icon="pi pi-bars text-xl text-color"
            onClick={toggleDrawer}
          />
          <Image src={BRAND.LOGO} imageClassName='h-2rem md:h-3rem' />
        </div>

        <div className="flex flex-row gap-2 lg:gap-3 align-items-center">
          {/* <ThemeSwitcher /> */}
          {/* <div className="relative">
            <Button
              className="border-circle border-0 bg-gray-500 text-white"
              icon="pi pi-bell text-xl"
            />
            {badgeValue > 0 && (
              <Badge
                className="absolute text-white"
                style={{
                  top: "-5px", 
                  right: "-5px",
                }}
                value={badgeValue}
              />
            )}
          </div> */}

          <div
            className="flex gap-1 align-items-center select-none cursor-pointer"
            onClick={(e) => {
              menu.current?.toggle(e);
              toggleMenu();
            }}
          >
            <Avatar
              label={initials}
              className="bg-gray-500 text-white"
              shape="circle"
              size="large"
            />

            <i
              className={`pi pi-chevron-${showMenu ? "up" : "down"} text-xs`}
            />

            <Menu model={items} popup ref={menu} />
          </div>
        </div>
      </div>

      <Drawer
        className="lg:hidden p-0"
        visible={openDrawer}
        onHide={toggleDrawer}
        showCloseIcon={false}
      >
        <SideBar show={true} role={role} onMenuClick={toggleDrawer} />
      </Drawer>
    </React.Fragment>
  );
}
