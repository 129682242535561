import { StateCreator } from "zustand";

import {
  amplifyConfirmResetPassword,
  amplifyConfirmSignIn,
  amplifyConfirmSignUp,
  amplifyForgotPassword,
  amplifySignIn,
  amplifySignOut,
  amplifyUpdatePassword
} from "@/utils/amplify/auth";

import { CombinedStoresTypes } from "@/zustand/types";
import {
  AUTH_ACTION_TYPES_ENUM,
  AuthSliceInterface
} from "@/zustand/types/authTypes";

import * as customQueries from "@/graphql/customQueries";
import { amplifyGetDataById } from "@/utils/amplify/api";
import { ConfirmSignInOutput, ConfirmSignUpOutput, ResetPasswordOutput, SignInOutput } from "aws-amplify/auth";
import zukeeper from "zukeeper";

export const createAuthSlice: StateCreator<
  CombinedStoresTypes,
  [],
  [],
  AuthSliceInterface
> = zukeeper((set, get) => ({
  AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.INITIAL_VALUE,

  isLoginWithPasswordLoading: false,
  isConfirmSignupLoading: false,
  isConfirmSigninLoading: false,
  isForgotPasswordLoading: false,
  isForgotPasswordOTPPConfirmLoading: false,
  isSignoutLoading: false,
  user_id: "",
  authUser: {},
  errorMessageAuth: "",
  isGetAuthenticatedUserLoading: false,
  isChangePasswordLoading: false,

  getAuthenticatedUser: async (email: string) => {

    set({
      isGetAuthenticatedUserLoading: true,
      authUser: {}
    });

    try {
      const result = await amplifyGetDataById(customQueries.getUsers, {email:email});
      
      set({
        isGetAuthenticatedUserLoading: false,
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.GET_AUTHENTICATED_USER_SUCCESS,
        authUser: result.data.getUsers,
      });
    } catch (error) {
      
      set({
        isGetAuthenticatedUserLoading: false,
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.GET_AUTHENTICATED_USER_ERROR,
        errorMessageAuth: error.message
      });

    }
  },
  
  loginWithPassword: async (email: string, password: string) => {
    set({
      isLoginWithPasswordLoading: true,
    });

    try {

      const results: SignInOutput = await amplifySignIn({ username: email, password });

      
      switch (results.nextStep.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
          set({
            isLoginWithPasswordLoading: false,
            AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.CONFIRM_SIGNIN_START,
            user_id: email
          });
          break;
        case "CONFIRM_SIGN_UP":
          set({
            isLoginWithPasswordLoading: false,
            AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.CONFIRM_SIGNUP_START,
            user_id: email
          });
          break;
        case "DONE":
          set({
            isLoginWithPasswordLoading: false,
            AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.LOGIN_WITH_PASSWORD_SUCCESS,
            user_id: email
          });
          break;
      
        default:
          set({
            isLoginWithPasswordLoading: false,
            AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.LOGIN_WITH_PASSWORD_ERROR,
            errorMessageAuth: "We couldn't log you in, try again later"
          });
          break;
      }

    } catch (error) {

      set({
        isLoginWithPasswordLoading: false,
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.LOGIN_WITH_PASSWORD_ERROR,
        errorMessageAuth: error.message
      });
    }
  },

  forgotPassword: async (email: string) => {
    set({
      isForgotPasswordLoading: true
    });

    try {
      const result: ResetPasswordOutput = await amplifyForgotPassword(email);

      set({
        isForgotPasswordLoading: false,
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.FORGOT_PASSWORD_SUCCESS,
        user_id: email
      });
    } catch (error) {
      set({
        isForgotPasswordLoading: false,
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.FORGOT_PASSWORD_ERROR,
        errorMessageAuth: error.message
      });
    }
  },

  confirmSignup: async (username: string, otp:string) => {
    set({
      isConfirmSignupLoading: true
    });

    try {
      const result:ConfirmSignUpOutput  = await amplifyConfirmSignUp({username:username, confirmationCode:otp});

      
      set({
        isConfirmSignupLoading: false,
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.CONFIRM_SIGNUP_SUCCESS,
        user_id: username
      });
    } catch (error) {
      set({
        isConfirmSignupLoading: false,
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.CONFIRM_SIGNUP_ERROR,
        errorMessageAuth: error.message
      });
    }
  },

  confirmSignin: async (username: string, newPassword:string) => {
    set({
      isConfirmSigninLoading: true
    });

    try {
      const result:ConfirmSignInOutput  = await amplifyConfirmSignIn({newpassword:newPassword});

      console.log("-----amplifyConfirmSignIn");
      console.log(result);
      
      
      set({
        isConfirmSigninLoading: false,
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.CONFIRM_SIGNIN_SUCCESS,
        user_id: username
      });
    } catch (error) {
      set({
        isConfirmSigninLoading: false,
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.CONFIRM_SIGNIN_ERROR,
        errorMessageAuth: error.message
      });
    }
  },

  forgotPasswordOTPConfirm: async (
    email: string,
    code: string,
    newPassword: string
  ) => {
    set({
      isForgotPasswordOTPPConfirmLoading: true
    });

    try {
      await amplifyConfirmResetPassword({ username: email, confirmationCode: code, newPassword: newPassword });
      set({
        isForgotPasswordOTPPConfirmLoading: false,
        AUTH_ACTION_TYPE:
          AUTH_ACTION_TYPES_ENUM.FORGOT_PASSWORD_OTP_CONFIRM_SUCCESS
      });
    } catch (error) {
      set({
        isForgotPasswordOTPPConfirmLoading: false,
        AUTH_ACTION_TYPE:
          AUTH_ACTION_TYPES_ENUM.FORGOT_PASSWORD_OTP_CONFIRM_ERROR,
        errorMessageAuth: error.message
      });
    }
  },


  changePassword: async (oldPassword: string, newPassword: string) => {
    set({
      isChangePasswordLoading: true
    })

    try {
      
      await amplifyUpdatePassword({ oldPassword, newPassword });
      set({
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.CHANGE_PASSWORD_SUCCESS,
        isChangePasswordLoading: false
      })
    } catch (error) {
      set({
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.CHANGE_PASSWORD_ERROR,
        isChangePasswordLoading: false,
        errorMessageAuth: error.message
      })
    }
  },

  reseActionTypeAuth: () => {
    set({
      AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.INITIAL_VALUE,
      errorMessageAuth: ""
    });
  },

  updateZustandStateAuth: (object: Partial<AuthSliceInterface>) => {
    set(object)
  },


  signout: async () => {
    set({
      isSignoutLoading: true
    });

    try {
      await amplifySignOut();
      // await SecureStore.deleteItemAsync("user_id");
      // await SecureStore.deleteItemAsync("LOGIN_TYPE");
      set({
        isSignoutLoading: false,
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.SIGNOUT_SUCCESS
      });
    } catch (error) {

      set({
        isSignoutLoading: false,
        AUTH_ACTION_TYPE: AUTH_ACTION_TYPES_ENUM.SIGNOUT_ERROR,
        errorMessageAuth: error.message
      });
    }
  }
}));

//zukeeper specific settings
declare global {
  interface Window {
    store: any;
  }
}
window.store = createAuthSlice;
export default createAuthSlice;