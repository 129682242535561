import { CreateBatchInput, UpdateBatchInput,DeleteBatchInput, batch, CreateBatchKdeDataInput, UpdateBatchKdeDataInput } from "@/graphql/API"

export const enum BATCH_ACTION_TYPES_ENUM  {
    INITIAL_VALUE = 50,
   
    GET_BATCHES_SUCCESS,
    GET_BATCHES_ERROR,
    
    GET_BATCH_BY_ID_SUCCESS,
    GET_BATCH_BY_ID_ERROR,
    GET_BATCH_BY_ID_EMPTY,
   
    ADD_KDE_DATA_SUCCESS,
    ADD_KDE_DATA_ERROR,
    
    UPDATE_KDE_DATA_SUCCESS,
    UPDATE_KDE_DATA_ERROR,

    ADD_BATCH_SUCCESS,
    ADD_BATCH_ERROR,

    UPDATE_BATCH_SUCCESS,
    UPDATE_BATCH_ERROR,

    DELETE_BATCH_SUCCESS,
    DELETE_BATCH_ERROR,
    
}

export type BatchType = Partial<batch>

export interface BatchSliceInterface {
    BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM,

    isGetBatchesLoading: boolean,
    isGetBatchLoading: boolean,
    isAddBatchLoading: boolean,
    isAddKDEDataLoading: boolean,
    isUpdateKDEDataLoading: boolean,
    isUpdateBatchLoading: boolean,
    isDeleteBatchLoading: boolean,

    batch: BatchType,
    batches: BatchType[],
    errorMessageBatch:string,
    nextTokenBatch:string,
  
    updateZustandStateBatch : (object :Partial<BatchSliceInterface>) => void
    getBatches: () => void
    getBatchById: (id:String) => void
    getBatchByNumber: (batchNumber:String) => void
    addBatch: (batch: CreateBatchInput) => void
    addKDEData: (kdeData: CreateBatchKdeDataInput) => void
    updateKDEData: (kdeData: UpdateBatchKdeDataInput) => void
    updateBatch: (batch:UpdateBatchInput) => void
    deleteBatch: (batch: DeleteBatchInput) => void

    resetActionTypeBatch: ()=>void
   
  }
