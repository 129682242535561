import { useCombinedStores } from "@/zustand/stores";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import If from "../../../components/shared/If";

interface Props {
  show: boolean;
  onHide: () => void;
  onEdit: () => void;
}

export default function CteDetails({ show, onHide,  onEdit }: Props) {
  const cte = useCombinedStores((state) => state.cte);

  const data = {
    ID: cte.id,
    Name: cte.name,
  };


  return (
    <Dialog
      header="CTE Details"
      visible={show}
      onHide={onHide}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      blockScroll={true}
    >
      <section className="flex flex-column gap-3">
        {Object.values(data).map((value, index) => {
          return (
            <div
              key={index}
              className="flex flex-row justify-content-between align-items-center gap-5"
            >
              <span>{Object.keys(data)[index]}</span>
              <span>{value}</span>
            </div>
          );
        })}
      </section>

     

      <section className="flex flex-column gap-3 mt-5">
        {/* show assigned kdes  */}
        <h3 className="font-bold mb-1">Assigned KDES</h3>

        <If
          condition={cte?.kde_metadata?.length === 0}
          then={<p>No KDES assigned</p>}
          else={
            <div className="flex flex-column gap-3">
              {cte?.kde_metadata?.map((kde, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-row bg-blue-600 px-3 py-2 border-round justify-content-between align-items-center gap-2"
                  >
                    <span className="text-white">{kde.label}</span>

                    <span className="px-3 p-tag bg-white text-gray-800">
                      {kde.input_type}
                    </span>
                  </div>
                );
              })}
            </div>
          }
        />
      </section>

      <div className='flex flex-column'>
        <Button
          className="align-self-center p-button-outlined mt-5 align-self-center"
          label="Edit CTE"
          onClick={onEdit}
        />
      </div>
    </Dialog>
  );
}
