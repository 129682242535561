// To get typescript while using Zukeeper i followed this guide:
// https://github.com/oslabs-beta/Zukeeper/issues/37
// https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#middleware-that-doesnt-change-the-store-type
import { StateCreator, StoreMutatorIdentifier } from "zustand";
import zukeeper from "zukeeper";

// zuKeeper type definition
type ZukeeperTS = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = []
>(
  f: StateCreator<T, Mps, Mcs>
) => StateCreator<T, Mps, Mcs>;

type ZukeeperTSImplType = <T>(
  f: StateCreator<T, [], []>
) => StateCreator<T, [], []>;

const zukeeperTs: ZukeeperTSImplType = (...a) => {
  return zukeeper(...a);
};

export const zukeeperLogger = zukeeperTs as unknown as ZukeeperTS;
