import Contact from "@/components/customer/Contact";
import FAQs from "@/components/customer/FAQs";
import Hero from "@/components/customer/Hero";
import CustomerLayout from "@/layouts/CustomerLayout";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Outlet } from "react-router-dom";

export default function Track() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  return (
    <CustomerLayout>
      <Hero />
      {!isTabletOrMobile ? (
        <div
          className="grid w-full  h-full p-8 gap-8 "
      
        >
          <div className="col-8 col h-full flex flex-column justify-content-center">
            <FAQs />
          </div>
          <div className="col">
            <Contact />
          </div>
        </div>
      ) : (
        <div className="w-full h-full  px-4 py-8" >
          <div className=" h-full flex flex-column justify-content-center">
            <FAQs />
          </div>
          <div className="pt-6">
            <Contact />
          </div>
        </div>
      )}
      <Outlet />
    </CustomerLayout>
  );
}
