import FormOtpInput from "@/components/forms/FormOtpInput";
import Password from "@/components/forms/Password";
import { verifyOtpInitialValues } from "@/constants/initialValues";
import { OTPValidationSchema } from "@/constants/validationSchema";
import AuthLayout from "@/layouts/AuthLayout";
import { useCombinedStores } from "@/zustand/stores";
import { AUTH_ACTION_TYPES_ENUM } from "@/zustand/types/authTypes";
import { FormikProvider, useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const VerifyOtp = () => {
  //zustand hooks
  const AUTH_ACTION_TYPE = useCombinedStores((state) => state.AUTH_ACTION_TYPE);
  const user_id = useCombinedStores((state) => state.user_id);
  const forgotPasswordOTPConfirm = useCombinedStores( (state) => state.forgotPasswordOTPConfirm );
  const isForgotPasswordOTPPConfirmLoading = useCombinedStores( (state) => state.isForgotPasswordOTPPConfirmLoading );
  const errorMessageAuth = useCombinedStores( (state) => state.errorMessageAuth );
  const reseActionTypeAuth = useCombinedStores( (state) => state.reseActionTypeAuth );


  const navigate = useNavigate();
  
  const formik = useFormik({
    initialValues: verifyOtpInitialValues,
    validationSchema: OTPValidationSchema,
    onSubmit: (values) => {

      forgotPasswordOTPConfirm(
        user_id ,
        values.otp,
        values.password
        )
    },
  });

  const { values, handleSubmit } = formik;

  //listener for AUTH_ACTION_TYPE
  React.useEffect(()=>{
    switch (AUTH_ACTION_TYPE) {
      case AUTH_ACTION_TYPES_ENUM.FORGOT_PASSWORD_OTP_CONFIRM_SUCCESS:
        toast.success("Password reset successful, please login to continue");
        navigate("/admin/login")
        
        reseActionTypeAuth();
        break;
      case AUTH_ACTION_TYPES_ENUM.FORGOT_PASSWORD_OTP_CONFIRM_ERROR:

          toast.error(errorMessageAuth);
        reseActionTypeAuth();
        break;
      default:
        break;
    }
  },[AUTH_ACTION_TYPE]);
  return (
    <AuthLayout>
      <div className="flex flex-column gap-3">
         <div>
          <Button
            className="text-white border-circle"
            icon="pi pi-arrow-left"
            onClick={() => navigate(-1)}
          />
        </div>
        <section>
          <h1>Verify OTP</h1>
          <p>An OTP has been sent to your email: <span className="text-gray-500">{user_id}</span></p>
        </section>

        <FormikProvider value={formik}>
          <div className="flex flex-column gap-5">
            <FormOtpInput label="Enter OTP" id="otp" isRequired />

            <Password
              lockicon
              id="password"
              label="Password"
              placeholder="Enter New Password"
              feedback={false}
            />

            <Button
              loading={isForgotPasswordOTPPConfirmLoading}
              type="submit"
              label="Submit"
              className="w-full text-white"
              disabled={!formik.isValid}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                handleSubmit();
              }}
            />
          </div>
        </FormikProvider>
      </div>
    </AuthLayout>
  );
};

export default VerifyOtp;
