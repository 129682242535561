import { traceHistory } from "@/constants/dummy";
import { useContainerWidth } from "@/hooks/useContainerWidth";
import { TabPanel, TabView } from "primereact/tabview";
import { useNavigate } from "react-router-dom";
import If from "../shared/If";
// biome-ignore lint/style/useImportType: <explanation>
import { batchKdeData, kdeMetaData } from "@/graphql/API";

export default function TraceabilityHistory({
	item,
	showButton,
}: {
	item: batchKdeData[];
	showButton?: boolean;
}) {
	/***
	 *  item: {batch_number, status, product, flow, history}
	 *  history: cte, kdes[label, value], assigned_inputer []
	 *
	 ***/

	const tabContainerWidth = useContainerWidth("tabcontainer-history");

	return (
		<div
			className="flex flex-column align-items-center mx-2"
			id="tabcontainer-history"
		>
			<TabView
				className="my-3 border-round"
				style={{
					width: tabContainerWidth,
				}}
			>
				{item?.map((item: batchKdeData, index) => {
					return (
						<TabPanel
							key={index}
							header={item.cte?.name ?? "No Data"}
							// leftIcon={
							//   item.assigned_inputer === user ? "pi pi-pencil pr-3" : ""
							// }
						>
							<If
								condition={item?.kdes?.length > 0}
								then={
									<div className="flex flex-column gap-3">
										{item.kdes.map((kde: kdeMetaData, index) => {
											return (
												<div
													key={index}
													className="flex flex-row justify-content-between align-items-center gap-2"
												>
													<span className="text-left">{kde.label}</span>
													<span className="text-right p-tag  bg-primary-100 text-primary-700">
														<div
															dangerouslySetInnerHTML={{
																__html: kde?.value ?? "N/A",
															}}
														/>
													</span>
												</div>
											);
										})}
									</div>
								}
								else={
									<div className="flex flex-column align-items-center gap-3">
										<span className="text-center my-5">No data available</span>
									</div>
								}
							/>
						</TabPanel>
					);
				})}
			</TabView>
		</div>
	);
}
