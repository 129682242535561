import DataTable, { createTheme, TableProps, IDataTableProps } from "react-data-table-component";
import If from "./If";
import useSettingsStore from "@/theme/settings";

interface Props {
  data: any[];
  columns: any[];
  resetPagination?: boolean;
  pagination?: boolean;
  isRecent?: boolean
  emptyIcon?: string;
  emptyMessage?: string;
}

type TablePropsType = Props & TableProps<Props>;

// optional to override the default dark theme
createTheme(
  "solarized",
  {
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
    background: {
      default: "#002b36",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const Table = ({
  data,
  columns,
  resetPagination,
  emptyIcon,
  emptyMessage,
  pagination = true,
  isRecent,
  defaultSortFieldId,
  defaultSortAsc=true
}: TablePropsType) => {


  const theme = useSettingsStore((state) => state.theme);

  return (
    // <div className={`overflow-hidden`${isRecent} ? " md:p-0" : "md:p-5"`}>
    <div className={`overflow-hidden ${isRecent ? " md:p-0" : "md:p-5" }`} >
      <If
        condition={data?.length > 0}
        
        then={
          <DataTable
            data={data}
            theme = {theme === "light" ? "light" : "dark"}
            columns={columns}
            fixedHeader
            fixedHeaderScrollHeight="72vh"
            sortIcon={<i className="pi pi-sort-alt" />}
            pointerOnHover
            pagination={pagination}
            responsive
            paginationResetDefaultPage={resetPagination}
            paginationPerPage={30}
            defaultSortFieldId={defaultSortFieldId}
            defaultSortAsc={defaultSortAsc}
          />
        }
        else={
          <div className="flex flex-column gap-5 align-items-center justify-center-center p-5 mt-8 text-gray-500">
            <i className={`pi ${emptyIcon} text-5xl text-primary`} />
            <span className="text-lg">{emptyMessage}</span>
          </div>
        }
      />
    </div>
  );
};

export default Table;
