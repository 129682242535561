import { UserType } from "@/zustand/types/userTypes";

export const filterUsers = (data: UserType[], filter: string) => {
  return data.filter((user: UserType) => {
    return (
      user?.first_name?.toLowerCase().includes(filter.toLowerCase()) ||
      user?.last_name?.toLowerCase().includes(filter.toLowerCase()) ||
      user?.email?.toLowerCase().includes(filter.toLowerCase())
     
    );
  });
};

export const filterByName = (
  data: any[],
  filter: string,
  nameLabel: string = "name"
) => {
  return data?.filter((item: any) => {
    return item[nameLabel]?.toLowerCase().includes(filter?.toLowerCase());
  });
};

export const filterByStartandEndDate = (
  data: any[],
  startDate: Date,
  endDate: Date,
  dateLabel: string = "date"
) => {
  return data.filter((item: any) => {
    // convert values to date and  filter by date
    const itemDate = new Date(item[dateLabel]);
    const start = new Date(startDate);
    const end = new Date(endDate);

    return itemDate >= start && itemDate <= end;
  });
};

export const filterByColumns = (
  data: any[],
  filter: string,
  columns: string[]
) => {
  return data.filter((item: any) => {
    return columns.some((column) => {
      return item[column]?.toLowerCase().includes(filter?.toLowerCase());
    });
  });
};
