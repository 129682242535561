export const contacts = [
  {
    name: "Phone",
    icon: <i className="pi pi-phone" style={{ fontSize: "1.5rem" }}></i>,
    value: ["+233 302443 824", "+233 546 044 523"],
  },
  {
    name: "Email",
    icon: <i className="pi pi-envelope" style={{ fontSize: "1.5rem" }}></i>,
    value: ["info@myrosswood.com", "sales@myrosswood.com"],
  },
  {
    name: "Address",
    icon: <i className="pi pi-home" style={{ fontSize: "1.5rem" }}></i>,
    value: ["East Legon", "Accra Ghana"],
  },
];

export const faqs = [
  {
    header: "What does the lot number look like?",
    panel:
      "<p>The lot number will be a 9 digit number with 3 hyphens. eg. <em>ES-24-090-CH</em></p>",
  },
  // {
  //   header: "How to use the traceability platform?",
  //   panel:
  //     "<ol><li>1.Batch Details will be available with each trace on each step of the manufactoring process.</li><li>2.Data captured during the manufacturing process will also be accessible.</li></ol>",
  // },
  {
    header: "How do I trace a product?",
    panel:
      "<ol><li>Enter the lot number of the product you want to trace</li> <li>Click Track to view the trace history of the product.</li></ol>",
  },
  {
    header: "Where can i find the lot number?",
    panel: "<p>The lot number will be on the product packaging.</p>",
  },
  {
    header: "How can you contact us?",
    panel: "<p>You can contact us from <a href='https://myrosswood.com/contact-us/'>https://myrosswood.com/contact-us/</a>.</p>",
  },
  
];

export const customerTraceHistory = [
  {
    cte: "Buying from farmer",
    kdes: [
      {
        label: "Name of Farmer",
        value: "John Doe",
      },
      {
        label: "Location of farm",
        value: "Bogoso",
      },
      {
        label: "Date of harvest",
        value: "2021-09-01",
      },
      {
        label: "Quantity of harvest",
        value: "1000",
      },
      {
        label: "Unit of harvest",
        value: "kg",
      },
      {
        label: "Name of crop",
        value: "Cocoa",
      },
      {
        label: "Variety of crop",
        value: "Cocoa",
      },
      {
        label: "Date of harvest",
        value: "2021-09-01",
      },
    ],
  },
  {
    cte: "Transporting to Warehouse",
    kdes: [
      {
        label: "Location of Warehouse",
        value: "Accra",
      },
      {
        label: "Arrival Date",
        value: "2021-09-01",
      },
      {
        label: "Condition of harvest",
        value: "Good",
      },
    ],
  },
  {
    cte: "Processing",
    kdes: [],
  },
  {
    cte: "Packaging",
    kdes: [],
  },
];

export const emptyStates = [
  {
    title: "RECENT CRITICAL TRACKING EVENT",
    icon: "pi pi-bolt",
    route: "",
    desc: "Currently, there are no CTEs added. CTEs will be displayed here once they are added.",
  },
  {
    title: "RECENT KEY DATA ELEMENT",
    icon: "pi pi-th-large",
    route: "",
    desc: "Currently, there are no KDEs added. KDEs will be displayed here once they are added.",
  },
  {
    title: "RECENT PRODUCTS",
    icon: "pi pi-box",
    route: "",
    desc: "Currently, there are no products added. Products will be displayed here once they are added.",
  },
  {
    title: "RECENT USERS",
    icon: "pi pi-users",
    route: "",
    desc: "Currently, there are no users added. Users will be displayed here once they are added.",
  },
];

export const statistics = (data: any) => [
  {
    icon: "pi pi-users",
    title: "USERS",
    color: "#008F4B",
    bgColor: "#E1F5E1",
    value: data?.users?.length,
    activeValue: data?.users?.active,
    inActiveValue: data?.users?.inactive,
  },
  {
    icon: "pi pi-sitemap",
    title: "ROLES",
    color: "#FBB33A",
    bgColor: "#FAF5E1",
    value: data?.roles?.length,
    activeValue: data?.roles?.active,
    inActiveValue: data?.roles?.inactive,
  },
  {
    icon: "pi pi-sitemap",
    title: "PRODUCTS",
    color: "#0063EA",
    bgColor: "#E5EEFF",
    value: data?.products?.length,
    activeValue: data?.products?.active,
    inActiveValue: data?.products?.inactive,
  },
  {
    icon: "pi pi-history",
    title: "TRACEABILITY FLOWS",
    color: "#E31333",
    bgColor: "#FDEAEA",
    value: data?.flows?.length,
    activeValue: data?.flows?.active,
    inActiveValue: data?.flows?.inactive,
  },
  {
    icon: "pi pi-bolt",
    title: "CRITICAL TRACKING EVENTS",
    color: "#008F4B",
    bgColor: "#E1F5E1",
    value: data?.ctes?.length,
    activeValue: data?.ctes?.active,
    inActiveValue: data?.ctes?.inactive,
  },
  {
    icon: "pi pi-th-large",
    title: "BATCH NUMBERS",
    color: "#FBB33A",
    bgColor: "#FAF5E1",
    value: data?.batches?.length,
    activeValue: data?.batches?.active,
    inActiveValue: data?.batches?.inactive,
  },
];

export const navMenus = [
  {
    label: "Home",
    url: "/home",
  },
  {
    label: "About Us",
    url: "/about-us",
  },
  {
    label: "Our Products",
    url: "/our-products",
  },
  {
    label: "Gallery",
    url: "/gallery",
  },
  {
    label: "Stories",
    url: "/stories",
  },
  {
    label: "Track",
    url: "/tracking",
  },
  {
    label: "Contact Us",
    url: "/contact-us",
  },
];
