import { StateCreator } from "zustand";

import { CreateRolesInput, DeleteRolesInput, ListRolesQueryVariables, UpdateRolesInput } from "@/graphql/API";
import * as mutations from "@/graphql/mutations";
import * as queries from "@/graphql/queries";
import * as customQueries from "@/graphql/customQueries";
import { amplifyCreateData, amplifyGetAllData, amplifyGetAllDataWithFilter, amplifyUpdateData } from "@/utils/amplify/api";
import { CombinedStoresTypes } from "@/zustand/types";
import { ROLE_ACTION_TYPES_ENUM, RoleSliceInterface, RoleType } from "@/zustand/types/roleTypes";
import zukeeper from "zukeeper";

export const createRoleSlice: StateCreator<CombinedStoresTypes, [], [], RoleSliceInterface> = zukeeper((set, get) => ({
    ROLE_ACTION_TYPE: ROLE_ACTION_TYPES_ENUM.INITIAL_VALUE,

    isGetRolesLoading: false,
    isAddRoleLoading: false,
    isUpdateRoleLoading: false,
    isDeleteRoleLoading: false,
    errorMessageRole: "",
    nextTokenRole:"",

    role: {},
    roles: [],

    resetActionTypeRole: () => {
        set( {
            ROLE_ACTION_TYPE : ROLE_ACTION_TYPES_ENUM.INITIAL_VALUE
        });

    },

    updateZustandStateRole : (object :Partial<RoleSliceInterface>)=>{
        set(object)
      },

   
    getRoles: async () => {
        set({
            isGetRolesLoading: true
        });

      
        try {
            const success = await amplifyGetAllData(customQueries.listRoles);
            const { items, nextToken } = success.data?.listRoles;
            set({
                isGetRolesLoading: false,
                errorMessageRole: "",
                ROLE_ACTION_TYPE: ROLE_ACTION_TYPES_ENUM.GET_ROLES_SUCCESS,
                roles: items,
                nextTokenRole: nextToken
            })

        } catch (error) {
            set({
                isGetRolesLoading: false,
                errorMessageRole: error.message,
                ROLE_ACTION_TYPE: ROLE_ACTION_TYPES_ENUM.GET_ROLES_ERROR,
            })
        }
    },

    addRole: (newRole: CreateRolesInput) => {
        set( {
            isAddRoleLoading : true,
        });

        //add role to cognito
        amplifyCreateData(mutations.createRoles, newRole).then((successValue) => {

            const result = successValue.data.createRoles;


            set({
                isAddRoleLoading: false,
                ROLE_ACTION_TYPE: ROLE_ACTION_TYPES_ENUM.ADD_ROLE_SUCCESS,
                role: result
            });
        }).catch((error) => {
            set({
                isAddRoleLoading: false,
                ROLE_ACTION_TYPE: ROLE_ACTION_TYPES_ENUM.ADD_ROLE_ERROR,
                errorMessageRole: error.message
            });

        });
    },
    updateRole: async (role: UpdateRolesInput) => {
        set({
            isUpdateRoleLoading: true,
        });

        try {
            const successValue = await amplifyUpdateData(mutations.updateRoles, role);
            const result = successValue.data.updateRoles;
            const newRoles = updateRoleStore( get().roles , result);
            set({
                isUpdateRoleLoading: false,
                ROLE_ACTION_TYPE: ROLE_ACTION_TYPES_ENUM.UPDATE_ROLE_SUCCESS,
                role: result,
                roles: newRoles
            });
            return result;
        } catch (error) {
            const errorMessage = (error.errors)? error.errors[0].message : error.message;
            
            set({
                isUpdateRoleLoading: false,
                ROLE_ACTION_TYPE: ROLE_ACTION_TYPES_ENUM.UPDATE_ROLE_ERROR,
                errorMessageRole: errorMessage
            });

        }

    },

   

    deleteRole: (role: DeleteRolesInput) => { },
}));

/**
 * This function locally updates the zustand store. so changes can relect in real time without reloading
 * @param items 
 * @param updatedItem 
 * @returns 
 */
const updateRoleStore = ( items:RoleType[], updatedItem:RoleType) =>{
    const index = items.findIndex((item) => {
      return item.email === updatedItem.email;
    });
    if(index==-1) return items;

    // Update the item in the local list
    const newitems = [...items];
    newitems[index] = updatedItem;
    return newitems;
    
}