/***
 *
 * This filter menu helper is used to filter the users table
 *
 * */

export const usersFilterMenu = (setFilter: (arg0: string) => void) => {
  return [
    {
      label: "Roles",
      items: [
        {
          label: "Super Admin",
          command: () => {
            setFilter("Super Admin");
          },
        },
        {
          label: "Inputer",
          command: () => {
            setFilter("Inputer");
          },
        },
        {
          label: "Observer",
          command: () => {
            setFilter("Observer");
          },
        },
      ],
    },
    {
      label: "Status",
      items: [
        {
          label: "Active",
          command: () => {
            setFilter("Active");
          },
        },
        {
          label: "Inactive",
          command: () => {
            setFilter("Inactive");
          },
        },
      ],
    },
    {
      label: "Reset Filters",
      icon: "pi pi-times",
      command: () => {
        setFilter("");
      },
    },
  ];
};
