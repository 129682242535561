import { create, StateCreator, StoreMutatorIdentifier } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { zukeeperLogger } from "@/zustand/middlewares/ZukeeperLogger";

// settings type
type SettingsType = {
  theme: "dark" | "light";
  setTheme: (theme: "dark" | "light") => void;
  actionType: string;
  resetActionType: () => void;

  session: "active" | "inactive";
  setSession: (session: "active" | "inactive") => void;
};

type StoreState = SettingsType;

// create settings slice
const settingsSlice: StateCreator<StoreState, [], [], SettingsType> = (
  set
) => ({
  // initial states
  theme: "light",
  session: "inactive",
  actionType: "",
  setTheme: (theme) => {
    set({ theme: theme, actionType: "themeChanged" });
  },

  setSession: (session) => {
    set({ session: session });
  },

  resetActionType: () => {
    set({ actionType: "" });
  },
});

const useSettingsStore = create<StoreState>()(
  persist(
    zukeeperLogger((...a) => ({
      ...settingsSlice(...a),
    })),
    {
      name: "__settings",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

declare global {
  interface Window {
    store: any;
  }
}

window.store = useSettingsStore;

export default useSettingsStore;
