import { createBrowserRouter, Navigate } from "react-router-dom";

// pages
import Login from "@pages/auth/Login";
import ForgotPassword from "@pages/auth/ForgotPassword";
import Track from "@/pages/customer/Track";
import TrackingDetails from "@/pages/customer/TrackingDetails";
import VerifyOtp from "@/pages/auth/VerifyForgotPasswordOtp";
import VerifySignupOtp from "@/pages/auth/VerifySignupOtp";
import VerifySignin from "@/pages/auth/VerifySignin";

export const AuthRouter = createBrowserRouter([
  {
    path: "/",
    element: <Track />,
  },
  {
    path: "/tracking/:id",
    element: <TrackingDetails />,
  },
  { path: "admin/", element: <Login /> },
  { path: "admin/login", element: <Login /> },
  { path: "admin/forgot-password", element: <ForgotPassword /> },
  { path: "admin/verify-otp", element: <VerifyOtp /> },
  { path: "admin/verify-signin/:email", element: <VerifySignin /> },
  { path: "admin/verify-signup-otp/:email", element: <VerifySignupOtp /> },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);
