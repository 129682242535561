import { StateCreator } from "zustand";

import {
  CreateCtesInput,
  DeleteCtesInput,
  DeleteCtesMutation,
  ListCtesQueryVariables,
  UpdateCtesInput,
} from "@/graphql/API";
import * as mutations from "@/graphql/mutations";
import * as queries from "@/graphql/queries";
import * as customQueries from "@/graphql/customQueries";
import {
  amplifyDeleteData,
  amplifyGetAllData,
  amplifyCreateData,
  amplifyUpdateData,
  amplifyGetAllDataWithFilter,
} from "@/utils/amplify/api";
import { CombinedStoresTypes } from "@/zustand/types";
import {
  CTE_ACTION_TYPES_ENUM,
  CteSliceInterface,
  CteType,
} from "@/zustand/types/cteTypes";
import zukeeper from "zukeeper";

export const createCteSlice: StateCreator<
  CombinedStoresTypes,
  [],
  [],
  CteSliceInterface
> = zukeeper((set, get) => ({
  CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.INITIAL_VALUE,

  isGetCtesLoading: false,
  isGetCteByEmailLoading: false,
  isAddCteLoading: false,
  isUpdateCteLoading: false,
  isDeleteCteLoading: false,
  errorMessageCte: "",
  nextTokenCte: "",

  cte: {},
  ctes: [],

  resetActionTypeCte: () => {
    set({
      CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.INITIAL_VALUE,
    });
  },

  updateZustandStateCte: (object: Partial<CteSliceInterface>) => {
    set(object);
  },
  getCteByEmail: (email: string) => {

    set({
      isGetCteByEmailLoading: true,
      cte: {},
    });

    const filterVariable: ListCtesQueryVariables = {
      limit: 10000,
      filter: {
        email: {
          eq: email,
        },
      },
    };

    amplifyGetAllDataWithFilter(queries.listCtes, filterVariable)
      .then((successValue) => {
        const { items, nextToken } = successValue.data.listCtes;


        if (items.length === 0) {
          set({
            isGetCteByEmailLoading: false,
            CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.GET_CTE_BY_EMAIL_EMPTY,
          });
          return;
        } else if (items.length > 0) {
          set({
            isGetCteByEmailLoading: false,
            CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.GET_CTE_SUCCESS,
            cte: items[0],
          });
          return;
        }

        set({
          isGetCteByEmailLoading: false,
          CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.GET_CTE_SUCCESS,
        });
      })
      .catch((errorValue) => {

        set({
          isGetCteByEmailLoading: false,
          CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.GET_CTE_ERROR,
        });
      });
  },
  getCtes: async () => {
    set({
      isGetCtesLoading: true,
    });

    try {
      const success = await amplifyGetAllData(customQueries.listCtes);
      const { items, nextToken } = success.data?.listCtes;


      set({
        isGetCtesLoading: false,
        errorMessageCte: "",
        CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.GET_CTES_SUCCESS,
        ctes: items,
        nextTokenCte: nextToken,
      });
    } catch (error) {

      set({
        isGetCtesLoading: false,
        CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.GET_CTES_ERROR,
        errorMessageCte: error.message,
      });
    }
  },

  addCte: (newCte: CreateCtesInput) => {
    set({
      isAddCteLoading: true,
    });

    //add cte to cognito
    amplifyCreateData(mutations.createCtes, newCte)
      .then((successValue) => {
        const result = successValue.data.createCtes;

        set({
          isAddCteLoading: false,
          CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.ADD_CTE_SUCCESS,
          cte: result,
        });
      })
      .catch((error) => {

        set({
          isAddCteLoading: false,
          CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.ADD_CTE_ERROR,
          errorMessageCte: error.message,
        });
      });
  },
  updateCte: async (cte: UpdateCtesInput) => {
    set({
      isUpdateCteLoading: true,
    });

    try {
      const successValue = await amplifyUpdateData(mutations.updateCtes, cte);
      const result = successValue.data.updateCtes;
      const newCtes = updateCteStore(get().ctes, result);
      set({
        isUpdateCteLoading: false,
        CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.UPDATE_CTE_SUCCESS,
        cte: result,
        ctes: newCtes,
      });
      return result;
    } catch (error) {
      const errorMessage = error.errors
        ? error.errors[0].message
        : error.message;
      set({
        isUpdateCteLoading: false,
        CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.UPDATE_CTE_ERROR,
        errorMessageCte: errorMessage,
      });
    }
  },

  updateCteStatus: async (id: string, status: true | false) => {},

  deleteCte: async (cte: DeleteCtesInput) => {
    set({
      isDeleteCteLoading: true,
    });
    try {
      const response = await amplifyDeleteData<DeleteCtesMutation>(
        mutations.deleteCtes,
        cte.id
      );
      set({
        isDeleteCteLoading: false,
        CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.DELETE_CTE_SUCCESS,
        errorMessageCte: "",
      });
    } catch (error) {
      set({
        isDeleteCteLoading: false,
        CTE_ACTION_TYPE: CTE_ACTION_TYPES_ENUM.DELETE_CTE_ERROR,
        errorMessageCte: error.message,
      });
    }
  },
}));

const updateCteStore = (items: CteType[], updatedItem: CteType) => {
  const index = items.findIndex((item) => {
    return item.email === updatedItem.email;
  });
  if (index == -1) return items;

  // Update the item in the local list
  const newitems = [...items];
  newitems[index] = updatedItem;
  return newitems;
};
