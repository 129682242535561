import { FOOTER_NAVIGATOR } from "@/constants/navigators";
import { BRAND } from "@/constants/options";
import { Image } from "primereact/image";
import React from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";


const contacts = [
  "+233 302 443 824",
  "+233 546 044 523",
  "+233 502 316 233",
  "info@myrosswood.com",
];

export const socials = [
  {
    icon: <i className="pi pi-facebook text-white" style={{ fontSize: "1.5rem" }}></i>,
    link: "https://www.facebook.com/Rosswoodsnacks",
  },
  {
    icon: <i className="pi pi-instagram text-white" style={{ fontSize: "1.5rem" }}></i>,
    link: "https://www.instagram.com/rosswoodsnacks/",
  },
  {
    icon: <i className="pi pi-twitter text-white" style={{ fontSize: "1.5rem" }}></i>,
    link: "https://twitter.com/RosswoodSnacks",
  },

  {
    icon: <i className="pi pi-linkedin text-white" style={{ fontSize: "1.5rem" }}></i>,
    link: "https://www.linkedin.com/company/rosswood",
  },
];

export const Footer = () => {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });

  return (
    <div className="flex flex-column bg-black-alpha-90 w-full md:px-8 px-4"  style={{ paddingBottom:"2rem", gap:"2rem"}} >
      <div className="flex md:justify-content-center justify-content-between w-full" style={{ gap: `${isTabletOrMobile ? "0px" : "8.5rem" }`,  }}>
        <Image src={BRAND.FOOTER_LOGO} className="hidden md:flex" />
        <div className="flex flex-column justify-content-end pb-7 gap-2 ">
          {FOOTER_NAVIGATOR.map((link, index) => (
            <NavLink
              key={index}
              to={link?.to}
              className="no-underline "
              // onClick={onMenuClick}
            >
              <span className="text-white">{link?.label}</span>
            </NavLink>
          ))}
        </div>
        <div className="flex flex-column text-white justify-content-end pb-7">
          <p className="text-5xl">Hello</p>
          <div className="flex flex-column  -mt-4  gap-2">
            {contacts.map((contact, index) => (
              <span key={index.toString()}>{contact} </span>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-content-center lg:justify-content-end gap-4">
        {socials?.map((social, index) => (
          <a
            target="_blank"
            href={social.link}
            key={index}
            rel="noopener noreferrer"
          >
            {social.icon}
            
                 
          </a>
        ))}
      </div>
      <div className="flex md:flex-row flex-column md:align-items-start align-items-center">
        <span className="text-white text-sm text-center">
          Copyright &copy; {new Date().getFullYear()} Rosswood Company Limited
          2023
        </span>
        <NavLink
        target="_blank"
          to="https://heyphord.com"
          className="no-underline text-white text-sm flex align-items-center"
        >
          {"   "}
          <div className="md:flex hidden">| {" "}</div> Developed by HEYPHORD I.T SOLUTIONS
        </NavLink>
      </div>
    </div>
  );
};
