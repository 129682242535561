import Header from "@/components/shared/Header";
import React from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import Container from "@/components/shared/Container";
import Table from "@/components/shared/Table";
import { useResetPagination } from "@/hooks/useResetPagination";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import { Button } from "primereact/button";
import { trackingColumns } from "@/constants/columns";
import { useNavigate } from "react-router-dom";
import NewBatch from "./NewBatch";
import { useCombinedStores } from "@/zustand/stores";
import { CreateBatchInput } from "@/graphql/API";
import { BATCH_ACTION_TYPES_ENUM } from "@/zustand/types/batchTypes";
import { toast } from "react-toastify";
import { getBatchNumberByDate } from "@/utils/misc";
import { FARMERS, farmerType } from "@/constants/options";
import { ProductType } from "@/zustand/types/productTypes";
import { filterByName } from "@/utils/filters";
import { pivot } from "@/utils/helpers/pivot";

const { convertArrayToCSV } = require('convert-array-to-csv');

export default function Tracking() {
  //zustand hooks
  const BATCH_ACTION_TYPE = useCombinedStores( (state) => state.BATCH_ACTION_TYPE);
  const products = useCombinedStores((state) => state.products);
  const batches = useCombinedStores((state) => state.batches);
  const batch = useCombinedStores((state) => state.batch);
  const getProducts = useCombinedStores((state) => state.getProducts);
  const getBatches = useCombinedStores((state) => state.getBatches);
  const deleteBatch = useCombinedStores((state) => state.deleteBatch);
  const addBatch = useCombinedStores((state) => state.addBatch);
  const isAddBatchLoading = useCombinedStores((state) => state.isAddBatchLoading);
  const isGetBatchesLoading = useCombinedStores( (state) => state.isGetBatchesLoading );
  const updateZustandStateBatch = useCombinedStores((state) => state.updateZustandStateBatch );
  const errorMessageBatch = useCombinedStores( (state) => state.errorMessageBatch );
  const resetActionTypeBatch = useCombinedStores( (state) => state.resetActionTypeBatch );


  const datePanel = React.useRef<any>(null);
  const navigate = useNavigate();

  const [dates, setDates] = React.useState<Nullable<(Date | null)[]>>(null);
  const [showSearch, toggleSearch] = React.useReducer((state) => !state, false);
  const [filter, setFilter] = React.useState<string>("");
  const resetPagination = useResetPagination(filter);
  const [showCreate, toggleCreate] = React.useReducer((state) => !state, false);

  const filteredData = filterByName(batches, filter, "batch_number");



  React.useEffect(() => {
    getProducts();
    getBatches();
  }, []);

  React.useEffect(()=>{
    switch (BATCH_ACTION_TYPE) {
      case BATCH_ACTION_TYPES_ENUM.ADD_BATCH_SUCCESS:
        toast.success("Batch created successfully");
        resetActionTypeBatch();
        navigate(`/admin/tracking/${batch.id}`);  
        break;
      case BATCH_ACTION_TYPES_ENUM.ADD_BATCH_ERROR:
        toast.error(errorMessageBatch);
        resetActionTypeBatch();
        break;
      case BATCH_ACTION_TYPES_ENUM.GET_BATCHES_ERROR:
        toast.error(errorMessageBatch);
        resetActionTypeBatch();
        break;
      
      default:
        break;
    }

  },[BATCH_ACTION_TYPE]);


  console.log(filteredData)

  return (
    <div>
      <Header
        title="Data Entry"
        // onFilter={(e) => datePanel.current.toggle(e)}
        onDownload={() => {
          const csv = convertArrayToCSV(pivot(batches));
          const a = document.createElement("a");
          a.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
          a.setAttribute("download", "batches.csv");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }}
        showSearch={showSearch}
        onSearch={toggleSearch}
        extraAction="Create Batch"
        extraActionIcon="pi pi-plus"
        onExtraAction={() => toggleCreate()}
        value={filter}
        onChange={(e: any) => setFilter(e.target.value)}
        onCloseSearch={() => {
          toggleSearch();
          setFilter("");
        }}
        menuComponent={
          <OverlayPanel ref={datePanel}>
            <div className="flex flex-column gap-2">
              <label>Select date range</label>

              <Calendar
                value={dates}
                onChange={(e) => setDates(e.value)}
                selectionMode="range"
                readOnlyInput
              />

              {/* reset filter */}
              <Button
                label="Reset"
                className="p-button-secondary"
                onClick={() => {
                  setDates(null);
                }}
              />
            </div>
          </OverlayPanel>
        }
      />

      <Container loading={isGetBatchesLoading}>
        <Table
          //@ts-ignore
          data={filteredData}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          //@ts-ignore
          columns={[
            ...trackingColumns(),
            {
              selector: (row: any) => row.id,
              grow: 1,
              center: true,
              cell: (row: any) => (
                <Button
                  icon="pi pi-pencil"
                  className="p-button-text"
                  onClick={() =>{

                    updateZustandStateBatch({batch:row})
                    navigate(`/admin/tracking/${row.id}`);
                  }
                  }
                />
              ),
            },
          ]}
          resetPagination={resetPagination}
          emptyIcon="pi-history"
          emptyMessage="There are no captured traceability process to show at the moment"
        />

        <NewBatch
          show={showCreate}
          onHide={toggleCreate}
          onCreate={(newDate:Date,farmer:farmerType, product:ProductType) => {
            
            const PRODUCT_CODE = (product?.code) ? product?.code.toUpperCase()+"-" : "";
            const VARIETY_CODE = (product?.variety_code) ? "-"+product.variety_code.toUpperCase() : "";
            const DAYS_COUNT = getBatchNumberByDate( newDate);
            const FARMER_CODE = farmer.code.toUpperCase() + "-";

            const BATCH_NUMBER = FARMER_CODE + DAYS_COUNT + VARIETY_CODE;
            const BATCH_ID = PRODUCT_CODE+BATCH_NUMBER;


            const newBatch : CreateBatchInput={
              id:BATCH_ID,
              product_id: product.id,
              batch_number: BATCH_NUMBER,
              batch_date: newDate.toISOString()
            };
            
            addBatch(newBatch);

            
          
          }}
          products={products}
          farmers={FARMERS}
          isLoading={isAddBatchLoading}
        />
      </Container>
    </div>
  );
}
