import Input from "@/components/forms/Input";
import Header from "@/components/shared/Header";
import PRIVILEGES from '@/constants/privileges';
import { RoleValidationSchema } from "@/constants/validationSchema";
import { CreateRolesInput, PrivilegeTypeInput, UpdateRolesInput } from "@/graphql/API";
import { createPrivilegeTypeInputArray } from "@/utils/graphqlInputArrayConversions";
import { useCombinedStores } from "@/zustand/stores";
import { ROLE_ACTION_TYPES_ENUM } from "@/zustand/types/roleTypes";
import { FormikProvider, useFormik } from "formik";
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from "primereact/inputswitch";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function EditRole() {
  //zustand hooks
  const ROLE_ACTION_TYPE = useCombinedStores( (state) => state.ROLE_ACTION_TYPE );
  const role = useCombinedStores( (state) => state.role );
  const getRoles = useCombinedStores( (state) => state.getRoles );
  const addRole = useCombinedStores( (state) => state.addRole );
  const updateRole = useCombinedStores( (state) => state.updateRole );
  const isAddRoleLoading = useCombinedStores( (state) => state.isAddRoleLoading );
  const isUpdateRoleLoading = useCombinedStores( (state) => state.isUpdateRoleLoading );
  const errorMessageRole = useCombinedStores( (state) => state.errorMessageRole );
  const resetActionTypeRole = useCombinedStores( (state) => state.resetActionTypeRole );
 
  const isUpdate:boolean = role?.id ? true : false;

  const navigate = useNavigate();

  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
  const listwrapper =
    "border-1 border-gray-400 p-4 flex flex-row align-items-center border-round";

  const formik = useFormik({
    initialValues: {
      name: role.name ?? "",
      is_active: role.is_active ?? true,
      privileges: role.privileges ??  PRIVILEGES,
    },

    validationSchema: RoleValidationSchema,
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {};
      const selectedPrivileges =values.privileges.filter( privilege=> privilege.selected==true );

      if( selectedPrivileges.length < 1){
        errors.privileges = "Select at least 1 privilege";
      }
      
      return errors;
    },
    onSubmit: (values) => {
      if (isUpdate) {

        const updatedRole : UpdateRolesInput ={
          ...values,
          id: role.id,
          //when updating an array of types, you need to cast the objects in to that specified type
          privileges: createPrivilegeTypeInputArray(values.privileges)
        };
        updateRole( updatedRole);
       
      } else {

        const newRole : CreateRolesInput ={
          ...values
        }

        addRole(newRole)
       
      }
    },
  });

  const { values, handleSubmit, setFieldValue ,errors } = formik;

//listener for AUTH_ACTION_TYPE
React.useEffect(()=>{
  switch (ROLE_ACTION_TYPE) {
    case ROLE_ACTION_TYPES_ENUM.ADD_ROLE_SUCCESS:
      getRoles();
      toast.success(`Role ${isUpdate ? "Updated" : "Added"} Successfully`);
      resetActionTypeRole();
      break;
    case ROLE_ACTION_TYPES_ENUM.ADD_ROLE_ERROR:
      toast.error(errorMessageRole);
      resetActionTypeRole();
      break;
    case ROLE_ACTION_TYPES_ENUM.UPDATE_ROLE_SUCCESS:
      // setShowEdit(false);
      toast.success(`Role ${isUpdate ? "Updated" : "Added"} Successfully`);
      resetActionTypeRole();
      break;
    case ROLE_ACTION_TYPES_ENUM.UPDATE_ROLE_ERROR:
      toast.error(errorMessageRole);
      resetActionTypeRole();
      break;
  
    default:
      break;
  }
},[ROLE_ACTION_TYPE]);

  return (
    <div>
      <Header
        onBack={() => navigate(-1)}
        title={isUpdate ? "Edit Role" : "Create Role"}
        extraAction="Save"
        extraActionIcon="pi pi-save"
        onExtraAction={() => handleSubmit()}
        extraActionLoading={isAddRoleLoading || isUpdateRoleLoading}
      />

      <FormikProvider value={formik}>
        <div className="flex flex-column gap-4 p-3 md:p-5">
          <section className={`${listwrapper} gap-5 flex-wrap`}>
            <div className="col-12 md:col-6">
              <Input id="name" label="Role Name" placeholder="eg. Admin" />
            </div>

            <div
              className="flex flex-row align-items-center gap-2 pl-3 md:pl-0 md:mt-4"
             
            >
              <span>Mark as active</span>
              <InputSwitch
                checked={values.is_active}
                onChange={(e) => {
                  setFieldValue("is_active", e.value);
                }}
              />
            </div>
          </section>

          <section className={`${listwrapper} gap-6 flex-wrap`}>
            

            {values.privileges.map((priviledge, index, priviledesArray:PrivilegeTypeInput[]) => (
              <div className="flex flex-row align-items-center gap-3">
                <label htmlFor={priviledge.name} className="text-lg">
                  {priviledge.name}
                </label>

                <Checkbox
                  checked={priviledge.selected}
                  icon="pi pi-check text-white"
                  onChange={(e) => {
                    priviledesArray[index].selected=!priviledge.selected

                    setFieldValue("privileges", priviledesArray );
                  }}
                />
              </div>
            ))}
          </section>
          <small className="p-error">{errors.privileges}</small>
        </div>
      </FormikProvider>
    </div>
  );
}
