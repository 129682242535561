import Header from "@/components/customer/Header";
import Details from "@/components/history/Details";
import Timeslines from "@/components/history/Timelines";
import TraceabilityHistory from "@/components/history/TraceabilityHistory";
import Container from "@/components/shared/Container";
import { useCombinedStores } from "@/zustand/stores";
import { BATCH_ACTION_TYPES_ENUM } from "@/zustand/types/batchTypes";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const TrackingDetails = () => {
  const params = useParams();

    
  //zustand hooks
  const BATCH_ACTION_TYPE = useCombinedStores( (state) => state.BATCH_ACTION_TYPE);
  const batch = useCombinedStores((state) => state.batch);
  const getBatchByNumber = useCombinedStores((state) => state.getBatchByNumber);
  const isGetBatchLoading = useCombinedStores((state) => state.isGetBatchLoading);
  const resetActionTypeBatch = useCombinedStores((state) => state.resetActionTypeBatch);
  const errorMessageBatch = useCombinedStores((state) => state.errorMessageBatch);


  React.useEffect(()=>{
    getBatchByNumber(params.id)
  },[params]);

  React.useEffect(()=>{
    
    switch (BATCH_ACTION_TYPE) {
      case BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_SUCCESS:
        // toast.success("Batch  successfully");
        resetActionTypeBatch();
        break;
      case BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_EMPTY:
        toast.error(errorMessageBatch);
        resetActionTypeBatch();
        break;
      case BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_ERROR:
        toast.error(errorMessageBatch);
        resetActionTypeBatch();
        break;
      
      default:
        break;
    }

  },[BATCH_ACTION_TYPE]);
  

    return (
      <Container loading={isGetBatchLoading}>
  
      {/* <CustomerLayout isWhiteNav={true}> */}
        <Header />
        <div className="flex lg:flex-row flex-column">
          <div className="md:border-right-1 border-gray-400  px-1 col-12 lg:w-7  lg:px-6">
            <Details item={batch} />
  
            <div className="px-2 md:px-4 lg:px-6">
              <Timeslines item={batch?.batchKdeData?.items} />
            </div>
          </div>
          <div className="w-full lg:w-5">
            <h2 className="border-bottom-1 border-gray-400 pb-4 pl-4">
              Data Entry
            </h2>
            <TraceabilityHistory item={batch?.batchKdeData?.items} />
  
          </div>
        </div>
      {/* </CustomerLayout> */}
      </Container>
    );
  }

export default TrackingDetails;
