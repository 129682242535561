import {
  traceabilityFlow,
  CreateTraceabilityFlowInput,
  UpdateTraceabilityFlowInput,
  DeleteTraceabilityFlowInput,
  CreateCteFlowInput,
  UpdateCteFlowInput,
  DeleteCteFlowInput,
} from "@/graphql/API";

export const enum FLOW_ACTION_TYPES_ENUM {
  INITIAL_VALUE = 150,

  GET_FLOWS_SUCCESS,
  GET_FLOWS_ERROR,

  GET_FLOW_SUCCESS,
  GET_FLOW_ERROR,
  GET_FLOW_EMPTY,

  ADD_FLOW_SUCCESS,
  ADD_FLOW_ERROR,
  
  ADD_CTE_FLOW_SUCCESS,
  ADD_CTE_FLOW_ERROR,

  UPDATE_FLOW_SUCCESS,
  UPDATE_FLOW_ERROR,
  
  UPSERT_CTE_FLOW_SUCCESS,
  UPSERT_CTE_FLOW_ERROR,

  DELETE_CTE_FLOW_SUCCESS,
  DELETE_CTE_FLOW_ERROR,

  DELETE_FLOW_SUCCESS,
  DELETE_FLOW_ERROR,
}

export type FlowType = Partial<traceabilityFlow>;

export interface FlowSliceInterface {
  FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM;

  isGetFlowsLoading: boolean;
  isAddFlowLoading: boolean;
  isAddCTEFlowLoading: boolean;
  isUpsertCTEFlowLoading: boolean;
  isDeleteCTEFlowLoading: boolean;
  isUpdateFlowLoading: boolean;
  isDeleteFlowLoading: boolean;

  flow: FlowType;
  flows: FlowType[];
  errorMessageFlow: string;
  nextTokenFlow: string;

  updateZustandStateFlow: (object: Partial<FlowSliceInterface>) => void;
  getFlows: () => void;
  getFlowById: (id: String) => void;
  addFlow: (flow: CreateTraceabilityFlowInput) => void;
  updateFlow: (flow: UpdateTraceabilityFlowInput) => void;
  addCTEFlow: (CTEflow: CreateCteFlowInput[]) => void;
  /**
   * Crate/update the CTEFlow table.
   */
  upsertCTEFlow: (CTEflow: UpdateCteFlowInput[], existingCTeFlowIds: string[]) => void;
  deleteCTEFlow: (cteFlow: DeleteCteFlowInput) => void;
  deleteFlow: (flow: DeleteTraceabilityFlowInput) => void;
  updateFlowStatus: (id: string, status: true | false) => void;

  resetActionTypeFlow: () => void;
}
