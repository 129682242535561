// dependencies
import React from "react";
import { useFormik, FormikProvider } from "formik";
// constants
import { loginInitialValues } from "@constants/initialValues";
import { LoginValidationSchema } from "@constants/validationSchema";
import { ILogin } from "@constants/interfaces";
import Input from "@/components/forms/Input";
import Password from "@/components/forms/Password";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import useSettingsStore from "@/theme/settings";
import AuthLayout from "@/layouts/AuthLayout";

import { useCombinedStores } from "../../zustand/stores/index";
import { AUTH_ACTION_TYPES_ENUM } from "@/zustand/types/authTypes";
export default function Login() {
  //zustand hooks
  const AUTH_ACTION_TYPE = useCombinedStores((state) => state.AUTH_ACTION_TYPE);
  const loginWithPassword = useCombinedStores( (state) => state.loginWithPassword );
  const isLoginWithPasswordLoading = useCombinedStores( (state) => state.isLoginWithPasswordLoading );
  const errorMessageAuth = useCombinedStores( (state) => state.errorMessageAuth );
  const reseActionTypeAuth = useCombinedStores( (state) => state.reseActionTypeAuth );
  const getAuthenticatedUser = useCombinedStores( (state) => state.getAuthenticatedUser );
  const user_id = useCombinedStores( (state) => state.user_id );


  const navigate = useNavigate();

  const setSession = useSettingsStore((state) => state.setSession);

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: LoginValidationSchema,
    onSubmit: (values: ILogin) => {
      
      loginWithPassword(values.email, values.password);
     
    },
  });

  const { values, handleSubmit } = formik;

  //listener for AUTH_ACTION_TYPE
  React.useEffect(()=>{
    switch (AUTH_ACTION_TYPE) {
      case AUTH_ACTION_TYPES_ENUM.CONFIRM_SIGNIN_START:
        navigate("/admin/verify-signin/"+values.email)
        reseActionTypeAuth();
        break;
      case AUTH_ACTION_TYPES_ENUM.CONFIRM_SIGNUP_START:
        navigate("/admin/verify-signup-otp/"+values.email)
        reseActionTypeAuth();
        break;
      case AUTH_ACTION_TYPES_ENUM.LOGIN_WITH_PASSWORD_SUCCESS:

      //get details of logged in user
        getAuthenticatedUser(user_id);
        
        // toast.success("Login successful");
        reseActionTypeAuth();
        setSession("active");
        break;
      case AUTH_ACTION_TYPES_ENUM.LOGIN_WITH_PASSWORD_ERROR:
        toast.error(errorMessageAuth);
        reseActionTypeAuth();
        break;
    
      default:
        break;
    }
  },[AUTH_ACTION_TYPE]);

  return (
    <AuthLayout>
      <div className="flex flex-column gap-3">
        <h1>Sign in</h1>

        <FormikProvider value={formik}>
          <div className="flex flex-column gap-5">
            <Input
              icon="pi pi-envelope"
              label="Email"
              id="email"
              placeholder="example@company.com"
              isRequired
            />

            <Password
              lockicon
              id="password"
              label="Password"
              placeholder="Enter Password"
              feedback={false}
            />

            <span className="flex justify-content-end">
              <Link
                to="/admin/forgot-password"
                className="no-underline font-medium text-primary hover:text-primary-700"
              >
                Forgot password?
              </Link>
            </span>

            <Button
              type="submit"
              loading={isLoginWithPasswordLoading}
              label="Sign in"
              className="w-full text-white"
              disabled={!formik.isValid}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                handleSubmit();
              }}
            />
          </div>
        </FormikProvider>
      </div>
    </AuthLayout>
  );
}
