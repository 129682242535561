import Input from "@/components/forms/Input";
import Select from "@/components/forms/Select";
import { useFormik, FormikProvider } from "formik";
import { Dialog } from "primereact/dialog";
import React from "react";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { InputSwitch } from "primereact/inputswitch";
import { useCombinedStores } from "@/zustand/stores";
import {
  PRODUCT_ACTION_TYPES_ENUM,
  ProductType,
} from "@/zustand/types/productTypes";
import { CreateProductsInput, UpdateProductsInput } from "@/graphql/API";


interface Props {
  show: boolean;
  onHide: () => void;
  // product: any;
}

export default function CreateEditProduct({ show, onHide }: Props) {
  //zustand hooks
  const PRODUCT_ACTION_TYPE = useCombinedStores(
    (state) => state.PRODUCT_ACTION_TYPE
  );
  const product = useCombinedStores((state) => state.product);
  const getProducts = useCombinedStores((state) => state.getProducts);
  const getFlows = useCombinedStores((state) => state.getFlows);
  const flows = useCombinedStores((state) => state.flows);

  const addProduct = useCombinedStores((state) => state.addProduct);
  const updateProduct = useCombinedStores((state) => state.updateProduct);
  const isAddProductLoading = useCombinedStores(
    (state) => state.isAddProductLoading
  );
  const isUpdateProductLoading = useCombinedStores(
    (state) => state.isUpdateProductLoading
  );
  const errorMessageProduct = useCombinedStores(
    (state) => state.errorMessageProduct
  );
  const resetActionTypeProduct = useCombinedStores(
    (state) => state.resetActionTypeProduct
  );

  const isUpdate: boolean = product?.id ? true : false;

  const formik = useFormik({
    initialValues: {
      name: product.name ?? "",
      code: product.code ?? "",
      traceability_flow_id: product.traceability_flow_id ?? "",
      is_active: product.is_active ?? true,
      variety_name: product.variety_name ?? "",
      variety_code: product.variety_code ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      code: Yup.string().required("Required"),
      variety_name: Yup.string().optional(),
      variety_code: Yup.string().when("variety_name",{
        is:  (varietyName:string) => !!varietyName,
        then:()=> Yup.string().required("Required"),
      }),
      // assigned_traceability: Yup.string().required("Required"),
    }),
    onSubmit: (values: ProductType) => {
      if (isUpdate) {
        const updatedProduct: UpdateProductsInput = {
          id: product?.id,
          name: values.name,
          code: values.code,
          variety_name: values.variety_name,
          variety_code: values.variety_code,
          traceability_flow_id: values.traceability_flow_id,
          is_active: values.is_active,
        };
        updateProduct(updatedProduct);
      } else {
        const newProduct: CreateProductsInput = {
          name: values.name,
          code: values.code,
          variety_name: values.variety_name,
          variety_code: values.variety_code,
          traceability_flow_id: values.traceability_flow_id,
          is_active: values.is_active,
        };

        addProduct(newProduct);
      }
    },
  });

  const { values, setFieldValue, handleSubmit } = formik;

  //listener for PRODUCT_ACTION_TYPE
  React.useEffect(() => {
    switch (PRODUCT_ACTION_TYPE) {
      case PRODUCT_ACTION_TYPES_ENUM.ADD_PRODUCT_SUCCESS:
        getProducts();
        onHide();
        toast.success(`Product ${isUpdate ? "Updated" : "Added"} Successfully`);
        resetActionTypeProduct();
        break;
      case PRODUCT_ACTION_TYPES_ENUM.ADD_PRODUCT_ERROR:
        toast.error(errorMessageProduct);
        resetActionTypeProduct();
        break;
      case PRODUCT_ACTION_TYPES_ENUM.UPDATE_PRODUCT_SUCCESS:
        onHide();
        toast.success(`Product ${isUpdate ? "Updated" : "Added"} Successfully`);
        resetActionTypeProduct();
        break;
      case PRODUCT_ACTION_TYPES_ENUM.UPDATE_PRODUCT_ERROR:
        toast.error(errorMessageProduct);
        resetActionTypeProduct();
        break;

      default:
        break;
    }
  }, [PRODUCT_ACTION_TYPE]);

  React.useEffect(() => {
    getFlows();
  }, []);

  return (
    <Dialog
      header={isUpdate ? "Edit Product" : "Add Product"}
      visible={show}
      onHide={onHide}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      blockScroll={true}
    >
      <FormikProvider value={formik}>
        <div className="flex flex-column gap-4">

          <div className="grid">
            <Input
              id="name"
              placeholder="eg. Cashew"
              label="Product Name"
              isRequired
            />
            <Input
              id="code"
              placeholder="eg. CAS"
              label="Product Code"
              isRequired
            />
          </div>

          <div className="grid">
            <Input
              id="variety_name"
              placeholder="eg. Salted"
              label="Variety Name"
              isRequired={false}
            />

            <Input
              id="variety_code"
              placeholder="eg. SA"
              label="Variety Code"
              isRequired={false}
            />
          </div>

          <Select
            id="traceability_flow_id"
            label="Assign Traceability Flow"
            value={values.traceability_flow_id}
            options={flows}
            optionLabel="name"
            optionValue="id"
          />

          <section className="flex flex-row gap-4">
            <span>Mark as active</span>

            <InputSwitch
              checked={values.is_active}
              onChange={(e) => {
                setFieldValue("is_active", e.value);
              }}
            />
          </section>

          <Button
            label={isUpdate ? "Update Product" : "Add Product"}
            className="text-white mt-5"
            loading={isAddProductLoading || isUpdateProductLoading}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          />
        </div>
      </FormikProvider>
    </Dialog>
  );
}
