import React from "react";
import { InputTextarea, InputTextareaProps } from "primereact/inputtextarea";
import { useField } from "formik";
import { classNames } from "primereact/utils";

interface Props {
  id: string;
  label?: string;
  isRequired?: boolean;
  icon?: string;
  helperText?: string;
}

type InputProps = Props & InputTextareaProps;

const Textarea = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { id, label, isRequired, icon, helperText } = props;

    const [field, meta] = useField(id);
    const { value, onBlur, onChange } = field;
    const { error, touched } = meta;

    return (
      <div className="flex flex-column gap-2 p-fluid col">
        <label
          htmlFor={id}
          className={classNames({
            "p-error": touched && error,
          })}
        >
          {label} {isRequired && <span className="p-error">*</span>}
        </label>

        <span className={`${!!icon && "p-input-icon-left"}`}>
          <i className={icon} />
          <InputTextarea
            {...props}
            autoResize
            value={value}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              onChange(id)(e.target.value);
            }}
            onBlur={onBlur(id)}
            className={classNames({
              "p-invalid": touched && error,
            })}
          />
        </span>
        {helperText && <small className="p-help text-500">{helperText}</small>}
        {touched && error && <small className="p-error">{error}</small>}
      </div>
    );
  }
);

Textarea.displayName = "Textarea";
export default Textarea;
