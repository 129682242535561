import { PrivilegeTypeInput } from "@/graphql/API";


const PRIVILEGES: PrivilegeTypeInput[] = [
    {
        id:1,
        name:"Dashboard",
        selected: true
    },
    {
        id:2,
        name:"Manage User",
        selected: false
    },
    {
        id:3,
        name:"Manage Role",
        selected: false
    },
    {
        id:4,
        name:"Manage Product",
        selected: false
    },
    {
        id:5,
        name:"Manage Flow",
        selected: false
    },
    {
        id:6,
        name:"Manage CTE",
        selected: false
    },
    {
        id:7,
        name:"Data Entry",
        selected: false
    },
    {
        id:8,
        name:"History",
        selected: false
    },
    
]

export default PRIVILEGES;