import { Button } from "primereact/button";
import { InputText, InputTextProps } from "primereact/inputtext";
import React from "react";
import If from "./If";

interface Props {
  title: string;
  onBack?: () => void;
  onDownload?: () => void;
  onSearch?: () => void;
  onFilter?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  extraAction?: string;
  extraActionIcon?: string;
  extraActionLoading?: boolean;
  onExtraAction?: () => void;
  showSearch?: boolean;
  onCloseSearch?: () => void;
  menuComponent?: React.ReactNode;
}

type HeaderProps = Props & InputTextProps;

export default function Header({
  onBack,
  title,
  onDownload,
  onSearch,
  onFilter,
  extraAction,
  onExtraAction,
  extraActionIcon,
  extraActionLoading,
  showSearch = false,
  onCloseSearch,
  menuComponent,
  ...inputProps
}: HeaderProps) {
  return (
    <div className="flex flex-column  md:flex-row flex-wrap justify-content-between align-items-start md:align-items-center py-2 px-3 border-bottom-1 border-gray-400">
      <div className="flex flex-row gap-3 align-items-center">
        <If
          condition={!!onBack}
          then={
            <Button
              className="p-button-text"
              icon="pi pi-arrow-left text-xl"
              onClick={onBack}
            />
          }
        />

        <h1 className="text-xl">{title}</h1>
      </div>

      <div className="flex flex-row gap-3 align-items-center">
        <If
          condition={showSearch}
          then={
            <div className="flex flex-row aling-items-center gap-3">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText {...inputProps} placeholder="Search..." />
              </span>

              <Button
                className="p-button-outlined border-gray-400 border-circle"
                icon="pi pi-times"
                onClick={onCloseSearch}
              />
            </div>
          }
          else={
            <div
              className={`
              flex flex-row gap-3 align-items-center 
              ${!!onDownload || !!onSearch || !!onFilter ? "mb-3" : "mb-0"} 
              md:mb-0`}
            >
              <span className="p-buttonset ">
                <If
                  condition={!!onDownload}
                  then={
                    <Button
                      className="p-button-outlined border-gray-400"
                      icon="pi pi-download"
                      onClick={onDownload}
                    />
                  }
                />
                <If
                  condition={!!onSearch}
                  then={
                    <Button
                      className="p-button-outlined border-gray-400"
                      icon="pi pi-search"
                      onClick={onSearch}
                    />
                  }
                />
                <If
                  condition={!!onFilter}
                  then={
                    <React.Fragment>
                      <Button
                        className="p-button-outlined border-gray-400"
                        icon="pi pi-filter"
                        onClick={onFilter}
                      />
                      <If condition={!!menuComponent} then={menuComponent} />
                    </React.Fragment>
                  }
                />
              </span>

              <If
                condition={!!extraAction}
                then={
                  <Button
                    className="text-white"
                    icon={extraActionIcon}
                    label={extraAction}
                    onClick={onExtraAction}
                    loading={extraActionLoading}
                  />
                }
              />
            </div>
          }
        />
      </div>
    </div>
  );
}
