import Doughnut from "@/components/dashboard/Doughnut";
import { Badge } from "primereact/badge";
import { Card } from "primereact/card";
import React from "react";

const StatisticsCard = ({item }:{ item: any }) => {
  return (
    <div className="flex justify-content-between p-3 border-2 border-gray-300 border-round-lg">
      <div className="flex flex-column gap-3">
        <div className="flex h-min gap-3">
          <div
            className="flex justify-content-center align-items-center p-2 border-round-lg h-min"
            style={{ backgroundColor: `${item.bgColor}`, }}
          >
            <i
              className={`${item.icon}  text-xl`}
              style={{ fontSize: "2rem", color: `${item.color}` }}
            ></i>
          </div>
          <div className=" flex flex-column h-full justify-content-between">
            <span className=" text-xs text-gray-500 font-semibold">
              {item.title}
            </span>
            <span className="font-semibold text-lg">{item.value}</span>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex align-items-center gap-1">
            <Badge severity="success"></Badge>
            <span>Active({item.activeValue})</span>
          </div>
          <div className="flex align-items-center gap-1">
            <Badge  style={{backgroundColor: "#FBB33A"}}></Badge>
            <span>Inactive({item.inActiveValue})</span>
          </div>
        </div>
      </div>
      <div>
        <Doughnut item={[item.activeValue, item.inActiveValue]} />
      </div>
    </div>
  );
};

export default StatisticsCard;
