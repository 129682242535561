import { generateClient } from 'aws-amplify/api';

const client = generateClient();

/**
 * Get All data from database
 * @param query 
 * @returns 
 */
export async function amplifyGetAllData(query: any) {
    const allData = await client.graphql({ query: query });

    return allData;
}


export async function amplifyGetAllDataWithFilter(query: any, filterVariable: object) {
    const allDataWithFilter = await client.graphql({
        query: query,
        variables: filterVariable
    });

    return allDataWithFilter;
}

export async function amplifyGetAllDataWithPagination(query: any, limit: number, nextToken: string = null) {
    const allDataWithPagination = await client.graphql({
        query: query,
        variables: {
            limit: limit,
            nextToken: nextToken
        }
    });

    return allDataWithPagination;
}

/**
 * Get data by id
 * @param query 
 * @param id 
 * @returns 
 */
export async function amplifyGetDataById(query: any, objectKey: object) {
    // Fetch a single record by its identifier
    const oneData = await client.graphql({
        query: query,
        // variables: { email: id }
        variables: objectKey
    });
    return oneData;


}

/**
 * 
 * @param createMutation 
 * @param mutationData 
 * @returns 
 */
export async function amplifyCreateData(createMutation: any, mutationData: any) {
    const newData = await client.graphql({
        query: createMutation,
        variables: { input: mutationData }
    });

    return newData;
}

export async function amplifyUpdateData(updateMutation: any, mutationData: any) {
    const updatedData = await client.graphql({
        query: updateMutation,
        variables: { input: mutationData }
    });

    return updatedData;
}



export async function amplifyDeleteData(deleteMutation: any, id: string) {
    const deletedData = await client.graphql({
        query: deleteMutation,
        variables: { input: {id: id} }
    });

    return deletedData;
}
