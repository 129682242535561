import React from "react";

import Container from "@/components/shared/Container";
import Header from "@/components/shared/Header";
import If from "@/components/shared/If";
import Table from "@/components/shared/Table";
import TableMenu from "@/components/shared/TableMenu";
import { productColumns } from "@/constants/columns";
import { DeleteProductsInput } from "@/graphql/API";
import { useResetPagination } from "@/hooks/useResetPagination";
import CreateEditProduct from "@/pages/app/products/CreateEditProduct";
import { filterByName } from "@/utils/filters";
import { useCombinedStores } from "@/zustand/stores";
import { PRODUCT_ACTION_TYPES_ENUM } from "@/zustand/types/productTypes";
import { ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { pivot } from "@/utils/helpers/pivot";

const { convertArrayToCSV } = require('convert-array-to-csv');

export default function Products() {
  //zustand hooks
  const PRODUCT_ACTION_TYPE = useCombinedStores(
    (state) => state.PRODUCT_ACTION_TYPE
  );
  const products = useCombinedStores((state) => state.products);
  const product = useCombinedStores((state) => state.product);
  const getProducts = useCombinedStores((state) => state.getProducts);
  const deleteProduct = useCombinedStores((state) => state.deleteProduct);
  const isGetProductsLoading = useCombinedStores(
    (state) => state.isGetProductsLoading
  );
  const updateZustandStateProduct = useCombinedStores(
    (state) => state.updateZustandStateProduct
  );
  const errorMessageProduct = useCombinedStores(
    (state) => state.errorMessageProduct
  );

  const resetActionTypeProduct = useCombinedStores(
    (state) => state.resetActionTypeProduct
  );

  const [showDelete, setShowDelete] = React.useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
  const [showSearch, toggleSearch] = React.useReducer((state) => !state, false);
  const [filter, setFilter] = React.useState<string>("");
  const resetPagination = useResetPagination(filter);
  const [showEdit, toggleEdit] = React.useReducer((s) => !s, false);
  const filteredProducts = filterByName(products, filter, "name");

  const handleDelete = React.useCallback(() => {
    // delete the discussion
    const selectedProduct: DeleteProductsInput = {
      id: product.id,
    };

    deleteProduct(selectedProduct);
  }, [product]);

  React.useEffect(() => {
    getProducts();
  }, []);

  //listener for PRODUCT_ACTION_TYPE
  React.useEffect(() => {
    switch (PRODUCT_ACTION_TYPE) {
      case PRODUCT_ACTION_TYPES_ENUM.DELETE_PRODUCT_SUCCESS:
        toast.success(`Product Deleted Successfully`);
        getProducts();
        resetActionTypeProduct();
        break;
      case PRODUCT_ACTION_TYPES_ENUM.DELETE_PRODUCT_ERROR:
        toast.error(errorMessageProduct);
        resetActionTypeProduct();
        break;
      default:
        break;
    }
  }, [PRODUCT_ACTION_TYPE]);

  return (
    <div>
      <Header
        title="Products"
        onDownload={() => {
          const csv = convertArrayToCSV(pivot(products));
          const a = document.createElement("a");
          a.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
          a.setAttribute("download", "products.csv");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }}
        extraAction="Create Product"
        extraActionIcon="pi pi-plus"
        onExtraAction={() => {
          updateZustandStateProduct({
            product: {},
          });
          toggleEdit();
        }}
        onSearch={toggleSearch}
        showSearch={showSearch}
        value={filter}
        onChange={(e: any) => setFilter(e.target.value)}
        onCloseSearch={() => {
          toggleSearch();
          setFilter("");
        }}
      />

      <Container loading={isGetProductsLoading}>
        <Table
          data={filteredProducts}
          // @ts-expect-error
          columns={[
            ...productColumns(),
            {
              name: "Active",
              selector: (row: any) => row.active,
              cell: (row: any) => (
                <span
                  className={`
                py-1 px-3 border-round
                bg-${row.is_active ? "green" : "red"}-100
                text-${row.is_active ? "green" : "red"}-600
                `}
                >
                  {row.is_active ? "ACTIVE" : "INACTIVE"}
                </span>
              ),
            },
            {
              selector: (row: any) => row.id,
              grow: 1,
              center: true,
              cell: (row: any) => (
                <If
                  condition={deleteLoading && row.id === product?.id}
                  then={<i className="pi pi-spin pi-spinner" />}
                  else={
                    <TableMenu
                      menuType="editDelete"
                      onEdit={() => {
                        updateZustandStateProduct({ product: row });
                        toggleEdit();
                      }}
                      onDelete={() => {
                        alert("Deleting Product has been temporarily disabled by Developer")
                        return;
                        updateZustandStateProduct({ product: row });
                        setShowDelete(true);
                      }}
                    />
                  }
                />
              ),
            },
          ]}
          resetPagination={resetPagination}
          emptyIcon="pi-sitemap"
          emptyMessage="There are no products to show at the moment"
        />

        <ConfirmDialog
          visible={showDelete}
          onHide={() => setShowDelete(false)}
          message="Are you sure you want to delete this product?"
          header="Delete Product"
          accept={handleDelete}
          reject={() => setShowDelete(false)}
        />
        <CreateEditProduct show={showEdit} onHide={toggleEdit} />
      </Container>
    </div>
  );
}
