import { faqs } from "@/constants/content";
import { Accordion, AccordionTab } from "primereact/accordion";
import React from "react";

const FAQs = () => {
  return (
    <Accordion activeIndex={0}>
      {faqs?.map((faq) => (
        <AccordionTab key={faq?.header} header={faq?.header}>
          <div dangerouslySetInnerHTML={{ __html: faq?.panel }} />
        </AccordionTab>
      ))}
    </Accordion>
  );
};

export default FAQs;
