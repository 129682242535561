import Password from '@/components/forms/Password';
import { changePasswordInitialValues } from '@/constants/initialValues';
import { IChangePassword } from '@/constants/interfaces';
import { ChangePasswordValidationSchema } from '@/constants/validationSchema';
import { useCombinedStores } from '@/zustand/stores';
import { AUTH_ACTION_TYPES_ENUM } from '@/zustand/types/authTypes';
import { FormikProvider, useFormik } from 'formik';
import { Button } from 'primereact/button';
import React from 'react'
import { toast } from "react-toastify";

const ChangePassword = () => {

   //zustand hooks
   const AUTH_ACTION_TYPE = useCombinedStores((state) => state.AUTH_ACTION_TYPE);
   const changePassword = useCombinedStores( (state) => state.changePassword );
   const isChangePasswordLoading = useCombinedStores( (state) => state.isChangePasswordLoading );
   const errorMessageAuth = useCombinedStores( (state) => state.errorMessageAuth );
   const reseActionTypeAuth = useCombinedStores( (state) => state.reseActionTypeAuth );
   const getAuthenticatedUser = useCombinedStores( (state) => state.getAuthenticatedUser );
   const user_id = useCombinedStores( (state) => state.user_id );
 
  const formik = useFormik({
    initialValues: changePasswordInitialValues,
    validationSchema: ChangePasswordValidationSchema,
    onSubmit: (values: IChangePassword) => {
      changePassword(values.old_password, values.new_password)
    },
  });

  const {  handleSubmit } = formik;

  //listener for AUTH_ACTION_TYPE
  React.useEffect(()=>{
    switch (AUTH_ACTION_TYPE) {
      case AUTH_ACTION_TYPES_ENUM.CHANGE_PASSWORD_SUCCESS:

        
        toast.success("Password udate successful");
        reseActionTypeAuth();
        break;
      case AUTH_ACTION_TYPES_ENUM.CHANGE_PASSWORD_ERROR:
        toast.error(errorMessageAuth);
        reseActionTypeAuth();
        break;
    
      default:
        break;
    }
  },[AUTH_ACTION_TYPE]);

    
  return (
    <div>
        <h3>Change Password</h3>
        <FormikProvider value={formik}>
          <div className="flex flex-column gap-4">
            <Password
              lockicon
              id="old_password"
              label="Current Password"
              placeholder="Enter Old Password"
              feedback={false}
            />

            <Password
              lockicon
              id="new_password"
              label="New Password"
              placeholder="Enter New Password"
              feedback={false}
            />

            <Password
              lockicon
              id="confirm_password"
              label="Confirm Password"
              placeholder="Reenter New Password"
              feedback={false}
            />

            <div className="flex justify-content-end">
              <Button
                  loading={isChangePasswordLoading}
                label="Submit"
                className=" text-white"
                disabled={!formik.isValid}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              />
            </div>
          </div>
        </FormikProvider>
      </div>
  )
}

export default ChangePassword
