import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import { PrimeReactProvider } from "primereact/api";

import App from "./App";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@/theme/custom.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const value = {
  ripple: true,
  cssTransition: true,
};

root.render(
  <React.StrictMode>
    <PrimeReactProvider value={value}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        draggable
        theme="colored"
        style={{
          fontFamily: "Open Sans",
        }}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </PrimeReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
