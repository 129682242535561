import { RouterProvider } from "react-router-dom";

import React from "react";
import { AuthRouter } from "@routers/AuthRouter";
import { AppRouter } from "@routers/AppRouter";
// import { usePreferedTheme } from "./hooks/usePreferedTheme";
import useSettingsStore from "./theme/settings";

//amplify configure
import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";
Amplify.configure(config);

function App() {
  // usePreferedTheme();

  const session = useSettingsStore((state) => state.session);

  if (session === "active") {
    return <RouterProvider router={AppRouter} />;
  }

  return <RouterProvider router={AuthRouter} />;
}

export default App;
