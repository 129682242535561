// dependencies
import { Image } from "primereact/image";
import React from "react";
// components
import AuthImage from "@components/shared/AuthImage";
// constants
import { BRAND } from "@/constants/options";

export default function AuthLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="grid grid-nogutter min-h-screen">
      <AuthImage />
      <div className="col md:col-6 lg:col-5">
        <section className="p-5">
          <Image src={BRAND.LOGO} alt="logo" height="40rem" />
        </section>

        <div className="px-5 py-3">{children}</div>
      </div>
    </div>
  );
}
