import { useContainerWidth } from "@/hooks/useContainerWidth";
import { useCombinedStores } from "@/zustand/stores";
import { CteType } from "@/zustand/types/cteTypes";
import { FlowType } from "@/zustand/types/flowTypes";
import { TabPanel, TabView } from "primereact/tabview";
import If from "../shared/If";
import KDEInputForm from "./KDEInputForm";
import { batchKdeData } from "@/graphql/API";

/**
 * This component 
 * @param traceabilityFlow this contains the ordered list of CTEs. I use it to render the TABS
 * @param batchKdeData this contains the actual recorded data. i use it to populate the inputs
 * @returns 
 */
export default function KDEInputs({
  traceabilityFlow,
  batchKdeData,
}: {
  traceabilityFlow: FlowType;
  batchKdeData: batchKdeData[],
}) {
 
  const ctes = traceabilityFlow?.cteFlow?.items?.map( cteFlow=>cteFlow.cte)
  
  const tabContainerWidth = useContainerWidth("tabcontainer-kdeinputs");

  const batch = useCombinedStores((state) => state.batch);
  const authUser = useCombinedStores((state) => state.authUser);

  
  return (
    <div className="flex flex-column align-items-center mx-2" id="tabcontainer-kdeinputs">
      <TabView
        className="my-3 border-round"
        style={{
          width: tabContainerWidth - 10
        }}
      >
        {ctes?.map((cte : CteType , index) => {
          // check if there is data for this cte
          const correspondingBatchData = batchKdeData.find( batchKde =>batchKde.cte_id === cte.id )
          const hasData = (correspondingBatchData)? true : false;

          return (
            <TabPanel
              key={index}
              header={cte?.name}
              leftIcon={
                //show pencil icon if user has access to edit
                // cte?.assigned_inputer === user ? "pi pi-pencil pr-3" : ""
               "pi pi-pencil pr-3"
              }
            >
              <If
                condition={cte?.kde_metadata?.length > 0}
                then={
                  <div className="flex flex-column gap-3">
                      <KDEInputForm
                        position={index}
                        cte_id={cte?.id}
                        batch_id={batch?.id}
                        batch_number={batch?.batch_number}
                        inputted_by={authUser?.first_name +" " +authUser?.last_name}
                        inputter_id={authUser?.email}
                        kdeMetadataArray={cte?.kde_metadata} //meta data to help render the forms
                        batchKdeData={correspondingBatchData} //actual data
                        />
                        
                  </div>
                }
                else={
                  <div className="flex flex-column align-items-center gap-3">
                    <span className="text-center my-5">No data available</span>
                  </div>
                }
              />
            </TabPanel>
          );
        })}
      </TabView>
    </div>
  );
}
