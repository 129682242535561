import { TableColumn } from "react-data-table-component";
import TableMenu from "@/components/shared/TableMenu";
import { UserSliceInterface } from "@/zustand/types/userTypes";
import { RoleType } from "@/zustand/types/roleTypes";
import { FlowType } from "@/zustand/types/flowTypes";
import { ProductType } from "@/zustand/types/productTypes";
import { BatchType } from "@/zustand/types/batchTypes";

export const userColumns = (
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>,
  setShowView: React.Dispatch<React.SetStateAction<boolean>>,
  setShowResetPassword: React.Dispatch<React.SetStateAction<boolean>>,
  updateZustandStateUser: (object: Partial<UserSliceInterface>) => void
) =>
  [
    {
      name: "Email",
      selector: (row: any) => row.email,
      grow: 2,
      sortable: true,
    },

    {
      name: "Name",
      selector: (row: any) => row?.first_name + " " + row?.last_name,
      grow: 2,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row: any) => row.phone,
      grow: 2,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row: any) => row.role?.name ?? "<empty>",
      grow: 2,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.status,
      cell: (row: any) => (
        <span
          className={`
        py-1 px-3 border-round
        bg-${row.is_active ? "green" : "red"}-100
        text-${row.is_active ? "green" : "red"}-600
        `}
        >
          {row.is_active ? "ACTIVE" : "INACTIVE"}
        </span>
      ),
      grow: 2,
      sortable: true,
    },
    {
      selector: (row: any) => row.id,
      grow: 1,
      center: true,
      cell: (row: any) => (
        <TableMenu
          menuType="viewEditReset"
          onView={() => {
            updateZustandStateUser({ user: row });
            setShowView(true);
            setShowEdit(false);
            setShowResetPassword(false);
          }}
          onEdit={() => {
            updateZustandStateUser({ user: row });

            setShowEdit(true);
            setShowView(false);
            setShowResetPassword(false);
          }}
          onReset={() => {
            updateZustandStateUser({ user: row });

            setShowResetPassword(true);
            setShowEdit(false);
            setShowView(false);
          }}
        />
      ),
    },
  ] as TableColumn<any>[];

export const recentUserColumns = 
  [
    {
      name: "Name",
      selector: (row: any) => row?.first_name + " " + row?.last_name,
      grow: 6,
      sortable: true,
    },

    {
      name: "Role",
      selector: (row: any) => row.role?.name ?? "<empty>",
      grow: 2,
      sortable: true,
    },

  
  ] as TableColumn<any>[];

export const roleColumns = () =>
  [
    {
      name: "ID",
      selector: (row: any) => row.id,
      grow: 2,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row: any) => row.name,
      grow: 2,
      sortable: true,
    },
    {
      name: "Assigned Privileges",
      selector: (row: RoleType) =>
        row.privileges?.filter((item) => item.selected == true)?.length +
        "/" +
        row.privileges.length,
      grow: 2,
      sortable: true,
    },
  ] as TableColumn<any>[];

export const productColumns = () =>
  [
    {
      name: "ID",
      selector: (row) => row.id,
      grow: 2,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.name,
      grow: 2,
      sortable: true,
    },
    {
      name: "Variety Name",
      selector: (row) => row.variety_name,
      grow: 2,
      sortable: true,
    },
    {
      name: "Assigned Traceability Flow",
      selector: (row) => row.traceability_flow?.name ?? "<empty>",
      grow: 2,
      sortable: true,
    },
  ] as TableColumn<ProductType>[];

export const recentProductColumns = () =>
  [
    {
      name: "Product Name",
      selector: (row: any) => row.name,
      grow: 4,
      sortable: true,
    },
    {
      name: "Assigned Traceability Flow",
      selector: (row: any) => row.traceability_flow?.name ?? "<empty>",
      grow: 6,
      sortable: true,
    },
  ] as TableColumn<any>[];

  export const flowColumns = ():TableColumn<FlowType>[] =>
  [
    {
      name: "ID",
      selector: (row) => row.id,
      grow: 2,
      sortable: true,
    },
    {
      name: "Flow Name",
      selector: (row) => row.name,
      grow: 2,
      sortable: true,
    },
    {
      name: "CTES",
      selector: (row) => row.cteFlow?.items?.length,
      grow: 2,
      sortable: true,
    },
    
  ] ;

export const cteColumns = () =>
  [
    {
      name: "ID",
      selector: (row: any) => row.id,
      grow: 2,
      sortable: true,
    },
    {
      name: "CTE Name",
      selector: (row: any) => row.name,
      grow: 2,
      sortable: true,
    },
    {
      name: "KDEs",
      selector: (row: any) => row.kde_metadata?.length,
      grow: 2,
      sortable: true,
    },
  ] as TableColumn<any>[];

export const recentCteColumns = () =>
  [
    {
      name: "CTE Name",
      selector: (row: any) => row.name,
      grow: 8,
      sortable: true,
    },
    {
      name: "KDEs",
      selector: (row: any) => row.kde_metadata?.length,
      grow: 2,
      sortable: true,
    },
  ] as TableColumn<any>[];


//using this column for both history and data entry table
export const trackingColumns = () =>
  [
    {
      name: "Batch Number",
      selector: (row) => row?.batch_number ?? "<empty>",
      grow: 2,
      sortable: true,
    },
    {
      name: "Product",
      selector: (row) => row?.product?.name,
      grow: 2,
      sortable: true,
    },
    {
      name: "Variety",
      selector: (row) => (row?.product?.variety_name) ? row?.product?.variety_name :  "<none>",
      grow: 2,
      sortable: true,
    },
    {
      name: "Flow",
      selector: (row) => row.product?.traceability_flow?.name,
      grow: 2,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.batch_date,
      grow: 2,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      grow: 2,
      sortable: true,
    },
  ] as TableColumn<BatchType>[];

export const recentTrackingColumns = () =>
  [
    {
      name: "Batch Number",
      selector: (row: any) => row?.batch_number ?? "<empty>",
      grow: 3,
      sortable: true,
    },
    {
      name: "Product",
      selector: (row: any) => row?.product?.name,
      grow: 4,
      sortable: true,
    },
  ] as TableColumn<any>[];
