import React from "react";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";
import { useField } from "formik";
import { classNames } from "primereact/utils";

interface Props {
  id: string;
  label?: string;
  isRequired?: boolean;
  icon?: string;
  helperText?: string;
}

type InputProps = Props & InputNumberProps;

const Number = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { id, label, isRequired, icon, helperText } = props;

  const [field, meta] = useField(id);
  const { value, onBlur, onChange } = field;
  const { error, touched } = meta;

  return (
    <div className="flex flex-column gap-2 p-fluid col">
      <label
        htmlFor={id}
        className={classNames({
          "p-error": touched && error,
        })}
      >
        {label} {isRequired && <span className="p-error">*</span>}
      </label>

      <span className={`${!!icon && "p-input-icon-left"}`}>
        <i className={icon} />

        <InputNumber
          {...props}
          inputId={id}
          value={value}
          onBlur={onBlur(id)}
          className={classNames({
            "p-invalid": touched && error,
          })}
          onValueChange={(e) =>
            onChange({
              target: {
                id,
                value: e.value,
              },
            })
          }
          useGrouping={false}
          minFractionDigits={2} maxFractionDigits={5}
        />
      </span>
      {helperText && <small className="p-help text-500">{helperText}</small>}
      {touched && error && <small className="p-error">{error}</small>}
    </div>
  );
});

Number.displayName = "Number";
export default Number;
