/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUsers = /* GraphQL */ `query GetUsers($email: ID!) {
  getUsers(email: $email) {
    email
    search_key
    first_name
    last_name
    phone
    role_id
    role {
      id
      name
      is_active
      createdAt
      updatedAt
      __typename
    }
    status
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUsersQueryVariables, APITypes.GetUsersQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $email: ID
  $filter: ModelUsersFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      search_key
      first_name
      last_name
      phone
      role_id
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getRoles = /* GraphQL */ `query GetRoles($id: ID!) {
  getRoles(id: $id) {
    id
    name
    is_active
    privileges {
      id
      name
      selected
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRolesQueryVariables, APITypes.GetRolesQuery>;
export const listRoles = /* GraphQL */ `query ListRoles(
  $filter: ModelRolesFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      is_active
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRolesQueryVariables, APITypes.ListRolesQuery>;
export const getProducts = /* GraphQL */ `query GetProducts($id: ID!) {
  getProducts(id: $id) {
    id
    name
    code
    variety_name
    variety_code
    traceability_flow_id
    traceability_flow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductsQueryVariables,
  APITypes.GetProductsQuery
>;
export const listProducts = /* GraphQL */ `query ListProducts(
  $filter: ModelProductsFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      code
      variety_name
      variety_code
      traceability_flow_id
      is_active
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsQueryVariables,
  APITypes.ListProductsQuery
>;
export const getCtes = /* GraphQL */ `query GetCtes($id: ID!) {
  getCtes(id: $id) {
    id
    index
    name
    kde_metadata {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCtesQueryVariables, APITypes.GetCtesQuery>;
export const listCtes = /* GraphQL */ `query ListCtes($filter: ModelCtesFilterInput, $limit: Int, $nextToken: String) {
  listCtes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCtesQueryVariables, APITypes.ListCtesQuery>;
export const getTraceabilityFlow = /* GraphQL */ `query GetTraceabilityFlow($id: ID!) {
  getTraceabilityFlow(id: $id) {
    id
    name
    is_active
    observers
    inputters
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTraceabilityFlowQueryVariables,
  APITypes.GetTraceabilityFlowQuery
>;
export const listTraceabilityFlows = /* GraphQL */ `query ListTraceabilityFlows(
  $filter: ModelTraceabilityFlowFilterInput
  $limit: Int
  $nextToken: String
) {
  listTraceabilityFlows(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTraceabilityFlowsQueryVariables,
  APITypes.ListTraceabilityFlowsQuery
>;
export const getCteFlow = /* GraphQL */ `query GetCteFlow($id: ID!) {
  getCteFlow(id: $id) {
    id
    traceability_flow_id
    cte_id
    traceabilityFlow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    position
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCteFlowQueryVariables,
  APITypes.GetCteFlowQuery
>;
export const listCteFlows = /* GraphQL */ `query ListCteFlows(
  $filter: ModelCteFlowFilterInput
  $limit: Int
  $nextToken: String
) {
  listCteFlows(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      traceability_flow_id
      cte_id
      position
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCteFlowsQueryVariables,
  APITypes.ListCteFlowsQuery
>;
export const getBatch = /* GraphQL */ `query GetBatch($id: ID!) {
  getBatch(id: $id) {
    id
    batch_number
    batch_date
    product_id
    status
    batchKdeData {
      nextToken
      __typename
    }
    product {
      id
      name
      code
      variety_name
      variety_code
      traceability_flow_id
      is_active
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBatchQueryVariables, APITypes.GetBatchQuery>;
export const listBatches = /* GraphQL */ `query ListBatches(
  $filter: ModelBatchFilterInput
  $limit: Int
  $nextToken: String
) {
  listBatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      batch_number
      batch_date
      product_id
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBatchesQueryVariables,
  APITypes.ListBatchesQuery
>;
export const getBatchKdeData = /* GraphQL */ `query GetBatchKdeData($id: ID!) {
  getBatchKdeData(id: $id) {
    id
    batch_id
    batch_number
    inputted_by
    inputter_id
    cte_id
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    inputter {
      email
      search_key
      first_name
      last_name
      phone
      role_id
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
    position
    kdes {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBatchKdeDataQueryVariables,
  APITypes.GetBatchKdeDataQuery
>;
export const listBatchKdeData = /* GraphQL */ `query ListBatchKdeData(
  $filter: ModelBatchKdeDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listBatchKdeData(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      batch_id
      batch_number
      inputted_by
      inputter_id
      cte_id
      position
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBatchKdeDataQueryVariables,
  APITypes.ListBatchKdeDataQuery
>;
export const cteFlowsByTraceability_flow_idAndPosition = /* GraphQL */ `query CteFlowsByTraceability_flow_idAndPosition(
  $traceability_flow_id: ID!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelcteFlowFilterInput
  $limit: Int
  $nextToken: String
) {
  cteFlowsByTraceability_flow_idAndPosition(
    traceability_flow_id: $traceability_flow_id
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      traceability_flow_id
      cte_id
      position
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CteFlowsByTraceability_flow_idAndPositionQueryVariables,
  APITypes.CteFlowsByTraceability_flow_idAndPositionQuery
>;
export const cteFlowsByCte_id = /* GraphQL */ `query CteFlowsByCte_id(
  $cte_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelcteFlowFilterInput
  $limit: Int
  $nextToken: String
) {
  cteFlowsByCte_id(
    cte_id: $cte_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      traceability_flow_id
      cte_id
      position
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CteFlowsByCte_idQueryVariables,
  APITypes.CteFlowsByCte_idQuery
>;
export const batchesByBatch_numberAndCreatedAt = /* GraphQL */ `query BatchesByBatch_numberAndCreatedAt(
  $batch_number: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelbatchFilterInput
  $limit: Int
  $nextToken: String
) {
  batchesByBatch_numberAndCreatedAt(
    batch_number: $batch_number
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      batch_number
      batch_date
      product_id
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BatchesByBatch_numberAndCreatedAtQueryVariables,
  APITypes.BatchesByBatch_numberAndCreatedAtQuery
>;
export const batchKdeDataByBatch_idAndPosition = /* GraphQL */ `query BatchKdeDataByBatch_idAndPosition(
  $batch_id: ID!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelbatchKdeDataFilterInput
  $limit: Int
  $nextToken: String
) {
  batchKdeDataByBatch_idAndPosition(
    batch_id: $batch_id
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      batch_id
      batch_number
      inputted_by
      inputter_id
      cte_id
      position
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BatchKdeDataByBatch_idAndPositionQueryVariables,
  APITypes.BatchKdeDataByBatch_idAndPositionQuery
>;
