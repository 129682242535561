import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React from "react";

export default function TableMenu({
  onView,
  onEdit,
  onDelete,
  onReset,
  menuType = "viewEdit",
}: Partial<{
  onView: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onReset : () => void;
  menuType:
    | "viewEdit"
    | "editDelete"
    | "viewEditDelete"
    | "viewEditReset";
}>) {
  const menu = React.useRef<Menu>(null);

  const items =
    menuType === "viewEdit"
      ? [
          {
            label: "View",
            icon: "pi pi-fw pi-eye",
            command: () => onView(),
          },
          {
            label: "Edit",
            icon: "pi pi-fw pi-pencil",
            command: () => onEdit(),
          },
        ]
      : menuType === "editDelete"
      ? [
          {
            label: "Edit",
            icon: "pi pi-fw pi-pencil",
            command: () => onEdit(),
          },
          {
            label: "Delete",
            icon: "pi pi-fw pi-trash",
            command: () => onDelete(),
          },
        ]
      : menuType === "viewEditDelete"
      ? [
          {
            label: "View",
            icon: "pi pi-fw pi-eye",
            command: () => onView(),
          },
          {
            label: "Edit",
            icon: "pi pi-fw pi-pencil",
            command: () => onEdit(),
          },
          {
            label: "Delete",
            icon: "pi pi-fw pi-trash",
            command: () => onDelete(),
          },
        ]
      : menuType === "viewEditReset"
      ? [
          {
            label: "View",
            icon: "pi pi-fw pi-eye",
            command: () => onView(),
          },
          {
            label: "Edit",
            icon: "pi pi-fw pi-pencil",
            command: () => onEdit(),
          },
          {
            label: "Reset Password",
            icon: "pi pi-fw pi-lock",
            command: () => onReset(),
          },
        ]
      : [];

  return (
    <React.Fragment>
      <Button
        className="p-button-text "
        icon="pi pi-ellipsis-v"
        onClick={(event) => menu.current?.toggle(event)}
      />

      <Menu ref={menu} model={items} popup />
    </React.Fragment>
  );
}
