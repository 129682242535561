import Container from "@/components/shared/Container";
import Header from "@/components/shared/Header";
import If from "@/components/shared/If";
import Table from "@/components/shared/Table";
import TableMenu from "@/components/shared/TableMenu";
import { flowColumns } from "@/constants/columns";
import { useResetPagination } from "@/hooks/useResetPagination";
import { filterByName } from "@/utils/filters";
import { useCombinedStores } from "@/zustand/stores";
import { ConfirmDialog } from "primereact/confirmdialog";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FlowDetails from "./FlowDetails";
import { pivot } from "@/utils/helpers/pivot";

const { convertArrayToCSV } = require('convert-array-to-csv');

export default function Flow() {
  //zustand hooks
  const flows = useCombinedStores((state) => state.flows);
  const flow = useCombinedStores((state) => state.flow);
  const getFlows = useCombinedStores((state) => state.getFlows);
  const isGetFlowsLoading = useCombinedStores((state) => state.isGetFlowsLoading);

  const updateZustandStateFlow = useCombinedStores(
    (state) => state.updateZustandStateFlow
  );


  const navigate = useNavigate();

  const [selectedFlow, setSelectedFlow] = React.useState<any>({});
  const [showDelete, setShowDelete] = React.useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
  const [showSearch, toggleSearch] = React.useReducer((state) => !state, false);
  const [filter, setFilter] = React.useState<string>("");
  const resetPagination = useResetPagination(filter);
  const filteredFlows = filterByName(flows, filter, "name");
  const [showDetails, togggleDetails] = React.useReducer(
    (state) => !state,
    false
  );

  const handleDelete = (id: any) => {
    // simulate delete
    setDeleteLoading(true);
    setTimeout(() => {
      setDeleteLoading(false);
      setShowDelete(false);
      toast.success("Flow deleted successfully");
    }, 500);
  };

  React.useEffect(()=>{
    getFlows()
  },[]);
  

  return (
    <div>
      <Header
        title="Traceability Flow"
        onDownload={() => {
          const csv = convertArrayToCSV(pivot(flows));
          const a = document.createElement("a");
          a.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
          a.setAttribute("download", "flows.csv");
          a.click();
        }}
        extraAction="Create Flow"
        extraActionIcon="pi pi-plus"
        onExtraAction={() => {
          updateZustandStateFlow({flow: {}})

          navigate("/admin/flow/new")
        }
        }
        onSearch={toggleSearch}
        showSearch={showSearch}
        value={filter}
        onChange={(e: any) => setFilter(e.target.value)}
        onCloseSearch={() => {
          toggleSearch();
          setFilter("");
        }}
      />

      <Container loading={isGetFlowsLoading}>
        <Table
          data={filteredFlows}
          // @ts-expect-error
          columns={[
            ...flowColumns(),
            {
              selector: (row: any) => row.id,
              grow: 1,
              center: true,
              cell: (row: any) => (
                <If
                  condition={deleteLoading && row.id === flow.id}
                  then={<i className="pi pi-spin pi-spinner" />}
                  else={
                    <TableMenu
                      menuType="editDelete"
                      onView={() => {
                        updateZustandStateFlow({flow: row})
                        setSelectedFlow(row);
                        togggleDetails();
                      }}
                      onEdit={() => {
                        updateZustandStateFlow({flow: row})
                        navigate(`/admin/flow/${row.id}`, { state: row });
                      }}
                      onDelete={() => {
                        alert("Deleting Flow has been temporarily disabled by Developer")
                        return;
                        updateZustandStateFlow({flow: row})
                        setShowDelete(true);
                      }}
                    />
                  }
                />
              ),
            },
          ]}
          resetPagination={resetPagination}
          emptyIcon="pi-refresh"
          emptyMessage="There are no traceabilty flows to show at the moment"
        />

        <ConfirmDialog
          visible={showDelete}
          onHide={() => setShowDelete(false)}
          message="Are you sure you want to delete this role?"
          header="Delete Role"
          accept={() => {
            handleDelete(selectedFlow.id);
          }}
          reject={() => setShowDelete(false)}
        />

        <FlowDetails
          show={showDetails}
          onHide={togggleDetails}
          flow={selectedFlow}
          onEdit={() => {
            navigate(`/admin/flow/${selectedFlow.id}`, { state: selectedFlow });
          }}
        />
      </Container>
    </div>
  );
}
