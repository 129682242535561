import { BatchType } from "@/zustand/types/batchTypes";

export default function Details({ item }: { item: BatchType }) {
  const listwrapper =
    "border-1 border-gray-400 p-4 flex flex-column gap-2 md:gap-3 border-round";

  const data = {
    batch_number: item?.batch_number ?? "N/A",
    status: item?.status ?? "N/A",
    product: item?.product?.name ?? "N/A",
    variety: item?.product?.variety_name ?? "N/A",
    flow: item?.product?.traceability_flow?.name ?? "N/A",
  };

  return (
    <div className={`${listwrapper} col mx-2 my-4 md:m-4 gap-4`}>
      <section className="flex flex-row justify-content-between align-items-center gap-3">
        <div className="flex flex-column gap-2 text-left">
          <span className="text-sm">Lot Number</span>
          <span>{data.batch_number}</span>
        </div>

        <div className="flex flex-column gap-2 text-right">
          <span className="text-sm">Variety</span>
          <span>{data.variety}</span>
        </div>
      </section>

      <section className="flex flex-row justify-content-between align-items-center gap-3">
        <div className="flex flex-column gap-2 text-left">
          <span className="text-sm">Product</span>
          <span>{data.product}</span>
        </div>

        <div className="flex flex-column gap-2 text-right">
          <span className="text-sm">Traceability Flow</span>
          <span>{data.flow}</span>
        </div>
      </section>
    </div>
  );
}
