import FormOtpInput from "@/components/forms/FormOtpInput";
import { OTPSignupValidationSchema } from "@/constants/validationSchema";
import AuthLayout from "@/layouts/AuthLayout";
import { useCombinedStores } from "@/zustand/stores";
import { AUTH_ACTION_TYPES_ENUM } from "@/zustand/types/authTypes";
import { FormikProvider, useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const VerifySignupOtp = () => {
     //zustand hooks
  const AUTH_ACTION_TYPE = useCombinedStores((state) => state.AUTH_ACTION_TYPE);
  const confirmSignup = useCombinedStores( (state) => state.confirmSignup );
  const isConfirmSignupLoading = useCombinedStores( (state) => state.isConfirmSignupLoading );
  const errorMessageAuth = useCombinedStores( (state) => state.errorMessageAuth );
  const reseActionTypeAuth = useCombinedStores( (state) => state.reseActionTypeAuth );
  const user_id = useCombinedStores( (state) => state.user_id );

    const params = useParams();

  const navigate = useNavigate();
  
  const formik = useFormik({
    initialValues: {
        email:params.email,
        otp:""
    },
    validationSchema: OTPSignupValidationSchema,
    onSubmit: (values) => {

      confirmSignup(user_id, values.otp);
      
    },
  });

  const { values, handleSubmit } = formik;

  //listener for AUTH_ACTION_TYPE
  React.useEffect(()=>{
    switch (AUTH_ACTION_TYPE) {
      case AUTH_ACTION_TYPES_ENUM.CONFIRM_SIGNUP_SUCCESS:
        toast.success("Account confirmation success, please login to continue");
        navigate("/admin/login")
        
        reseActionTypeAuth();
        break;
      case AUTH_ACTION_TYPES_ENUM.CONFIRM_SIGNUP_ERROR:

          toast.error(errorMessageAuth);
        reseActionTypeAuth();
        break;
      default:
        break;
    }
  },[AUTH_ACTION_TYPE]);

  return (
    <AuthLayout>
      <div className="flex flex-column gap-3">
         <div>
          <Button
            className="text-white border-circle"
            icon="pi pi-arrow-left"
            onClick={() => navigate(-1)}
          />
        </div>
        <section>
          <h1>Complet Sigup</h1>
          <p>An OTP was  sent to your email: <span className="text-gray-500">{params.email}</span></p>
        </section>

        <FormikProvider value={formik}>
          <div className="flex flex-column gap-5">
            <FormOtpInput label="Enter OTP " id="otp" isRequired />

           

            <Button
            type="submit"
                loading={isConfirmSignupLoading}
              label="Submit"
              className="w-full text-white"
              disabled={!formik.isValid}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                handleSubmit();
              }}
            />
          </div>
        </FormikProvider>
      </div>
    </AuthLayout>
  );
};

export default VerifySignupOtp;
