import { contacts } from "@/constants/content";
import React from "react";

const Contact = () => {
  return (
    <div className="border-1 border-gray-400 border-round-md p-4 ">
      {contacts?.map((contact,index) => (
        <div key={contact?.name} className={`flex  justify-content-between align-items-center gap-2 pb-1 pt-2 ${index % 2  ? "border-y-1 border-gray-400" : "border-0"}`}>
          <div >
            {contact?.icon}
            <p>{contact?.name} </p>
          </div>
            <div>
              {contact?.value?.map((item) => (
                <p key={item}>{item}</p>
              ))}
            </div>
        
        </div>
      ))}
    </div>
  );
};

export default Contact;
