import React from "react";

import Container from "@/components/shared/Container";
import Header from "@/components/shared/Header";
import If from "@/components/shared/If";
import Table from "@/components/shared/Table";
import TableMenu from "@/components/shared/TableMenu";
import { cteColumns } from "@/constants/columns";
import { useResetPagination } from "@/hooks/useResetPagination";
import { filterByName } from "@/utils/filters";
import { useCombinedStores } from "@/zustand/stores";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DeleteCtesInput } from "@/graphql/API";
import { CTE_ACTION_TYPES_ENUM } from "@/zustand/types/cteTypes";
import CteDetails from "@/pages/app/ctes/CteDetails";
import { pivot } from "@/utils/helpers/pivot";

const { convertArrayToCSV } = require('convert-array-to-csv');

export default function Cte() {
  const navigate = useNavigate();

  //zustand hooks
  const CTE_ACTION_TYPE = useCombinedStores((state) => state.CTE_ACTION_TYPE);
  const ctes = useCombinedStores((state) => state.ctes);
  const cte = useCombinedStores((state) => state.cte);
  const getCtes = useCombinedStores((state) => state.getCtes);
  const deleteCte = useCombinedStores((state) => state.deleteCte);
  const isGetCtesLoading = useCombinedStores((state) => state.isGetCtesLoading);
  const isDeleteCteLoading = useCombinedStores(
    (state) => state.isDeleteCteLoading
  );
  const updateZustandStateCte = useCombinedStores(
    (state) => state.updateZustandStateCte
  );
  const errorMessageCte = useCombinedStores((state) => state.errorMessageCte);

  const resetActionTypeCte = useCombinedStores(
    (state) => state.resetActionTypeCte
  );

  const [showDelete, setShowDelete] = React.useState<boolean>(false);
  const [showSearch, toggleSearch] = React.useReducer((state) => !state, false);
  const [filter, setFilter] = React.useState<string>("");
  const resetPagination = useResetPagination(filter);
  const [showDetails, togggleDetails] = React.useReducer(
    (state) => !state,
    false
  );
  const filteredCtes = filterByName(ctes, filter, "name");

  const handleDelete = React.useCallback(() => {
    // delete the discussion
    const selectedCte: DeleteCtesInput = {
      id: cte.id,
    };

    deleteCte(selectedCte);
  }, [cte]);

  React.useEffect(() => {
    getCtes();
  }, []);

  //listener for CTE_ACTION_TYPE
  React.useEffect(() => {
    switch (CTE_ACTION_TYPE) {
      case CTE_ACTION_TYPES_ENUM.DELETE_CTE_SUCCESS:
        toast.success(`Cte Deleted Successfully`);
        getCtes();
        resetActionTypeCte();
        break;
      case CTE_ACTION_TYPES_ENUM.DELETE_CTE_ERROR:
        toast.error(errorMessageCte);
        resetActionTypeCte();
        break;
      default:
        break;
    }
  }, [CTE_ACTION_TYPE]);

  return (
    <div>
      <Header
        title="Critical Tracking Events"
        onDownload={() => {
          const csv = convertArrayToCSV(pivot(ctes));
          const a = document.createElement("a");
          a.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
          a.setAttribute("download", "ctes.csv");
          a.click();
        }}
        extraAction="Create CTE"
        extraActionIcon="pi pi-plus"
        onExtraAction={() => {
          updateZustandStateCte({
            cte: {},
          });
          navigate("/admin/cte/new");
        }}
        onSearch={toggleSearch}
        showSearch={showSearch}
        value={filter}
        onChange={(e: any) => setFilter(e.target.value)}
        onCloseSearch={() => {
          toggleSearch();
          setFilter("");
        }}
      />

      <Container loading={isGetCtesLoading}>
        <Table
          data={filteredCtes}
          columns={[
            ...cteColumns(),
           
            {
              selector: (row: any) => row.id,
              grow: 1,
              center: true,
              cell: (row: any) => (
                <If
                  condition={isDeleteCteLoading && row.id === cte?.id}
                  then={<i className="pi pi-spin pi-spinner" />}
                  else={
                    <TableMenu
                      menuType="viewEditDelete"
                      onView={() => {
                        updateZustandStateCte({ cte: row });
                        togggleDetails();
                      }}
                      onEdit={() => {
                        updateZustandStateCte({ cte: row });
                        navigate(`/admin/cte/${cte.id}`, { state: cte });
                      }}
                      onDelete={() => {
                        alert("Deleting CTE has been temporarily disabled by Developer")
                        return;
                        updateZustandStateCte({ cte: row });
                        setShowDelete(true);
                      }}
                    />
                  }
                />
              ),
            },
          ]}
          resetPagination={resetPagination}
          emptyIcon="pi-sitemap"
          emptyMessage="There are no products to show at the moment"
        />

        <ConfirmDialog
          visible={showDelete}
          onHide={() => setShowDelete(false)}
          message="Are you sure you want to delete this cte?"
          header="Delete CTE"
          accept={handleDelete}
          reject={() => setShowDelete(false)}
        />
        <CteDetails
          show={showDetails}
          onHide={togggleDetails}
          onEdit={() => {
            navigate(`/admin/cte/${cte.id}`, { state: cte });
          }}
        />
      </Container>
    </div>
  );
}
