import { StateCreator } from "zustand";

import {
  CreateCteFlowInput,
  CreateTraceabilityFlowInput,
  DeleteCteFlowInput,
  DeleteTraceabilityFlowInput,
  DeleteTraceabilityFlowMutation,
  UpdateCteFlowInput,
  UpdateTraceabilityFlowInput,
} from "@/graphql/API";
import * as mutations from "@/graphql/mutations";
import * as queries from "@/graphql/queries";
import * as customQueries from "@/graphql/customQueries";
import {
  amplifyDeleteData,
  amplifyGetAllData,
  amplifyCreateData,
  amplifyUpdateData,
} from "@/utils/amplify/api";
import { CombinedStoresTypes } from "@/zustand/types";
import zukeeper from "zukeeper";
import {
  FLOW_ACTION_TYPES_ENUM,
  FlowSliceInterface,
  FlowType,
} from "@/zustand/types/flowTypes";

export const createFlowSlice: StateCreator<
  CombinedStoresTypes,
  [],
  [],
  FlowSliceInterface
> = zukeeper((set, get) => ({
  FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.INITIAL_VALUE,

  isGetFlowsLoading: false,
  isGetFlowByEmailLoading: false,
  isAddFlowLoading: false,
  isAddCTEFlowLoading: false,
  isUpdateFlowLoading: false,
  isUpsertCTEFlowLoading: false,
  isDeleteFlowLoading: false,
  isDeleteCTEFlowLoading: false,
  errorMessageFlow: "",
  nextTokenFlow: "",

  flow: {},
  flows: [],

  resetActionTypeFlow: () => {
    set({
      FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.INITIAL_VALUE,
    });
  },

  updateZustandStateFlow: (object: Partial<FlowSliceInterface>) => {
    set(object);
  },

  getFlows: async () => {
    set({
      isGetFlowsLoading: true,
    });

    try {
      const success = await amplifyGetAllData(customQueries.listTraceabilityFlows);
      const { items, nextToken } = success.data?.listTraceabilityFlows;

      set({
        isGetFlowsLoading: false,
        errorMessageFlow: "",
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.GET_FLOWS_SUCCESS,
        flows: items,
        nextTokenFlow: nextToken,
      });
    } catch (error) {

      set({
        isGetFlowsLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.GET_FLOWS_ERROR,
        errorMessageFlow: error.message,
      });
    }
  },

  addFlow: async (newFLow: CreateTraceabilityFlowInput) => {
    set({
      isAddFlowLoading: true,
    });
    try {
      
      //add create new tracablility flow
      const newTraceabilityFlow = await amplifyCreateData(mutations.createTraceabilityFlow, newFLow);
      const newTraceabilityFlowData = newTraceabilityFlow.data.createTraceabilityFlow;


      set({
        isAddFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.ADD_FLOW_SUCCESS,
        flow: newTraceabilityFlowData,
      });
    } catch (error) {
      
  
      set({
        isAddFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.ADD_FLOW_ERROR,
        errorMessageFlow: error.message,
      });
    }
  },

  deleteCTEFlow: async (cteFlow: DeleteCteFlowInput) => {
    set({
      isDeleteCTEFlowLoading: true,
    });
    try {
      const response = await amplifyDeleteData(
        mutations.deleteCteFlow,
        cteFlow.id
      );
      set({
        isDeleteCTEFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.DELETE_CTE_FLOW_SUCCESS,
        errorMessageFlow: "",
      });
    } catch (error) {
      set({
        isDeleteCTEFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.DELETE_CTE_FLOW_ERROR,
        errorMessageFlow: error.message,
      });
    }
  },

  //add new cteflow if this is not part of the database
  //update a cteflow if it is already part of the database
  //delete this database cte flow id if it no longer exist in the local list
  upsertCTEFlow: async (localCTEFlow: UpdateCteFlowInput[], databaseCTeFlows:string[]) => {
    set({
      isUpsertCTEFlowLoading: true,
    });
    try {
      
      //ADD OR UPDATE IF local CTE exist in the data
      for (const cteFlow of localCTEFlow) {

        if (databaseCTeFlows.includes(cteFlow.id)){

          const newTraceabilityFlow = await amplifyUpdateData(mutations.updateCteFlow, cteFlow);
        
        }else{
          const newTraceabilityFlow = await amplifyCreateData(mutations.createCteFlow, cteFlow);
        }
        
      }

      //delete database cte id if it doesnt exist in the local cte list
      
      for (const dbFLowId of databaseCTeFlows) {
        const dontDelete = localCTEFlow.some( localFlow => localFlow.id === dbFLowId)
        if(!dontDelete){

          await amplifyDeleteData(mutations.deleteCteFlow , dbFLowId );
        }
      }


      set({
        isUpsertCTEFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.ADD_CTE_FLOW_SUCCESS,
      });
    } catch (error) {
      
  
      set({
        isUpsertCTEFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.ADD_CTE_FLOW_ERROR,
        errorMessageFlow: error.message ?? error.errors[0].message,
      });
    }
  },
  addCTEFlow: async (newCTEFLow: CreateCteFlowInput[]) => {
    set({
      isAddCTEFlowLoading: true,
    });
    try {
      
      for (const cteFlow of newCTEFLow) {
        //add new CTE flow
        const newTraceabilityFlow = await amplifyCreateData(mutations.createCteFlow, cteFlow);
        const newTraceabilityFlowData = newTraceabilityFlow.data.createCteFlow;
        
      }


      set({
        isAddCTEFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.ADD_CTE_FLOW_SUCCESS,
      });
    } catch (error) {
      
  
      set({
        isAddCTEFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.ADD_CTE_FLOW_ERROR,
        errorMessageFlow: error.message ?? error.errors[0].message,
      });
    }
  },
  updateFlow: async (flow: UpdateTraceabilityFlowInput) => {
    set({
      isUpdateFlowLoading: true,
    });

    try {
      const successValue = await amplifyUpdateData(
        mutations.updateTraceabilityFlow,
        flow
      );
      const result = successValue.data.updateTraceabilityFlow;
      const newFlows = updateFlowStore(get().flows, result);
      set({
        isUpdateFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.UPDATE_FLOW_SUCCESS,
        flow: result,
        flows: newFlows,
      });
      return result;
    } catch (error) {
      const errorMessage = error.errors
        ? error.errors[0].message
        : error.message;
      set({
        isUpdateFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.UPDATE_FLOW_ERROR,
        errorMessageFlow: errorMessage,
      });
    }
  },

  updateFlowStatus: async (id: string, status: true | false) => {},

  deleteFlow: async (flow: DeleteTraceabilityFlowInput) => {
    set({
      isDeleteFlowLoading: true,
    });
    try {
      const response = await amplifyDeleteData<DeleteTraceabilityFlowMutation>(
        mutations.deleteTraceabilityFlow,
        flow.id
      );
      set({
        isDeleteFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.DELETE_FLOW_SUCCESS,
        errorMessageFlow: "",
      });
    } catch (error) {
      set({
        isDeleteFlowLoading: false,
        FLOW_ACTION_TYPE: FLOW_ACTION_TYPES_ENUM.DELETE_FLOW_ERROR,
        errorMessageFlow: error.message,
      });
    }
  },
}));

const updateFlowStore = (items: FlowType[], updatedItem: FlowType) => {
  const index = items.findIndex((item) => {
    return item.id === updatedItem.id;
  });
  if (index == -1) return items;

  // Update the item in the local list
  const newitems = [...items];
  newitems[index] = updatedItem;
  return newitems;
};
