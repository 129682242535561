/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUsersInput = {
  email: string,
  search_key?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  phone?: string | null,
  role_id?: string | null,
  status?: string | null,
  is_active?: boolean | null,
};

export type ModelUsersConditionInput = {
  email?: ModelIDInput | null,
  search_key?: ModelStringInput | null,
  first_name?: ModelStringInput | null,
  last_name?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  role_id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  and?: Array< ModelUsersConditionInput | null > | null,
  or?: Array< ModelUsersConditionInput | null > | null,
  not?: ModelUsersConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type users = {
  __typename: "users",
  email: string,
  search_key?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  phone?: string | null,
  role_id?: string | null,
  role?: roles | null,
  status?: string | null,
  is_active?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type roles = {
  __typename: "roles",
  id: string,
  name: string,
  is_active?: boolean | null,
  privileges?:  Array<privilegeType | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type privilegeType = {
  __typename: "privilegeType",
  id?: number | null,
  name?: string | null,
  selected?: boolean | null,
};

export type UpdateUsersInput = {
  email: string,
  search_key?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  phone?: string | null,
  role_id?: string | null,
  status?: string | null,
  is_active?: boolean | null,
};

export type DeleteUsersInput = {
  email: string,
};

export type CreateRolesInput = {
  id?: string | null,
  name: string,
  is_active?: boolean | null,
  privileges?: Array< PrivilegeTypeInput | null > | null,
};

export type PrivilegeTypeInput = {
  id?: number | null,
  name?: string | null,
  selected?: boolean | null,
};

export type ModelRolesConditionInput = {
  name?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  and?: Array< ModelRolesConditionInput | null > | null,
  or?: Array< ModelRolesConditionInput | null > | null,
  not?: ModelRolesConditionInput | null,
};

export type UpdateRolesInput = {
  id: string,
  name?: string | null,
  is_active?: boolean | null,
  privileges?: Array< PrivilegeTypeInput | null > | null,
};

export type DeleteRolesInput = {
  id: string,
};

export type CreateProductsInput = {
  id?: string | null,
  name: string,
  code?: string | null,
  variety_name?: string | null,
  variety_code?: string | null,
  traceability_flow_id?: string | null,
  is_active?: boolean | null,
};

export type ModelProductsConditionInput = {
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  variety_name?: ModelStringInput | null,
  variety_code?: ModelStringInput | null,
  traceability_flow_id?: ModelIDInput | null,
  is_active?: ModelBooleanInput | null,
  and?: Array< ModelProductsConditionInput | null > | null,
  or?: Array< ModelProductsConditionInput | null > | null,
  not?: ModelProductsConditionInput | null,
};

export type products = {
  __typename: "products",
  id: string,
  name: string,
  code?: string | null,
  variety_name?: string | null,
  variety_code?: string | null,
  traceability_flow_id?: string | null,
  traceability_flow?: traceabilityFlow | null,
  is_active?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type traceabilityFlow = {
  __typename: "traceabilityFlow",
  id: string,
  name: string,
  is_active?: boolean | null,
  observers?: Array< string | null > | null,
  inputters?: Array< string | null > | null,
  cteFlow?: ModelcteFlowConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelcteFlowConnection = {
  __typename: "ModelcteFlowConnection",
  items:  Array<cteFlow | null >,
  nextToken?: string | null,
};

export type cteFlow = {
  __typename: "cteFlow",
  id: string,
  traceability_flow_id: string,
  cte_id: string,
  traceabilityFlow?: traceabilityFlow | null,
  cte?: ctes | null,
  position?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ctes = {
  __typename: "ctes",
  id: string,
  index?: number | null,
  name: string,
  kde_metadata?:  Array<kdeMetaData | null > | null,
  cteFlow?: ModelcteFlowConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type kdeMetaData = {
  __typename: "kdeMetaData",
  index?: number | null,
  input_id?: string | null,
  is_required?: boolean | null,
  label?: string | null,
  place_holder?: string | null,
  default_value?: string | null,
  value?: string | null,
  input_type?: string | null,
  help_message?: string | null,
  options?: Array< string | null > | null,
};

export type UpdateProductsInput = {
  id: string,
  name?: string | null,
  code?: string | null,
  variety_name?: string | null,
  variety_code?: string | null,
  traceability_flow_id?: string | null,
  is_active?: boolean | null,
};

export type DeleteProductsInput = {
  id: string,
};

export type CreateCtesInput = {
  id?: string | null,
  index?: number | null,
  name: string,
  kde_metadata?: Array< KdeMetaDataInput | null > | null,
};

export type KdeMetaDataInput = {
  index?: number | null,
  input_id?: string | null,
  is_required?: boolean | null,
  label?: string | null,
  place_holder?: string | null,
  default_value?: string | null,
  value?: string | null,
  input_type?: string | null,
  help_message?: string | null,
  options?: Array< string | null > | null,
};

export type ModelCtesConditionInput = {
  index?: ModelIntInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelCtesConditionInput | null > | null,
  or?: Array< ModelCtesConditionInput | null > | null,
  not?: ModelCtesConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateCtesInput = {
  id: string,
  index?: number | null,
  name?: string | null,
  kde_metadata?: Array< KdeMetaDataInput | null > | null,
};

export type DeleteCtesInput = {
  id: string,
};

export type CreateTraceabilityFlowInput = {
  id?: string | null,
  name: string,
  is_active?: boolean | null,
  observers?: Array< string | null > | null,
  inputters?: Array< string | null > | null,
};

export type ModelTraceabilityFlowConditionInput = {
  name?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  observers?: ModelStringInput | null,
  inputters?: ModelStringInput | null,
  and?: Array< ModelTraceabilityFlowConditionInput | null > | null,
  or?: Array< ModelTraceabilityFlowConditionInput | null > | null,
  not?: ModelTraceabilityFlowConditionInput | null,
};

export type UpdateTraceabilityFlowInput = {
  id: string,
  name?: string | null,
  is_active?: boolean | null,
  observers?: Array< string | null > | null,
  inputters?: Array< string | null > | null,
};

export type DeleteTraceabilityFlowInput = {
  id: string,
};

export type CreateCteFlowInput = {
  id?: string | null,
  traceability_flow_id: string,
  cte_id: string,
  position?: number | null,
};

export type ModelCteFlowConditionInput = {
  traceability_flow_id?: ModelIDInput | null,
  cte_id?: ModelIDInput | null,
  position?: ModelIntInput | null,
  and?: Array< ModelCteFlowConditionInput | null > | null,
  or?: Array< ModelCteFlowConditionInput | null > | null,
  not?: ModelCteFlowConditionInput | null,
};

export type UpdateCteFlowInput = {
  id: string,
  traceability_flow_id?: string | null,
  cte_id?: string | null,
  position?: number | null,
};

export type DeleteCteFlowInput = {
  id: string,
};

export type CreateBatchInput = {
  id?: string | null,
  batch_number?: string | null,
  batch_date?: string | null,
  product_id: string,
  status?: string | null,
  createdAt?: string | null,
};

export type ModelBatchConditionInput = {
  batch_number?: ModelIDInput | null,
  batch_date?: ModelStringInput | null,
  product_id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBatchConditionInput | null > | null,
  or?: Array< ModelBatchConditionInput | null > | null,
  not?: ModelBatchConditionInput | null,
};

export type batch = {
  __typename: "batch",
  id: string,
  batch_number?: string | null,
  batch_date?: string | null,
  product_id: string,
  status?: string | null,
  batchKdeData?: ModelbatchKdeDataConnection | null,
  product?: products | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelbatchKdeDataConnection = {
  __typename: "ModelbatchKdeDataConnection",
  items:  Array<batchKdeData | null >,
  nextToken?: string | null,
};

export type batchKdeData = {
  __typename: "batchKdeData",
  id: string,
  batch_id: string,
  batch_number?: string | null,
  inputted_by?: string | null,
  inputter_id?: string | null,
  cte_id: string,
  cte?: ctes | null,
  inputter?: users | null,
  position: number,
  kdes?:  Array<kdeMetaData | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBatchInput = {
  id: string,
  batch_number?: string | null,
  batch_date?: string | null,
  product_id?: string | null,
  status?: string | null,
  createdAt?: string | null,
};

export type DeleteBatchInput = {
  id: string,
};

export type CreateBatchKdeDataInput = {
  id?: string | null,
  batch_id: string,
  batch_number?: string | null,
  inputted_by?: string | null,
  inputter_id?: string | null,
  cte_id: string,
  position: number,
  kdes?: Array< KdeMetaDataInput | null > | null,
};

export type ModelBatchKdeDataConditionInput = {
  batch_id?: ModelIDInput | null,
  batch_number?: ModelIDInput | null,
  inputted_by?: ModelStringInput | null,
  inputter_id?: ModelIDInput | null,
  cte_id?: ModelIDInput | null,
  position?: ModelIntInput | null,
  and?: Array< ModelBatchKdeDataConditionInput | null > | null,
  or?: Array< ModelBatchKdeDataConditionInput | null > | null,
  not?: ModelBatchKdeDataConditionInput | null,
};

export type UpdateBatchKdeDataInput = {
  id: string,
  batch_id?: string | null,
  batch_number?: string | null,
  inputted_by?: string | null,
  inputter_id?: string | null,
  cte_id?: string | null,
  position?: number | null,
  kdes?: Array< KdeMetaDataInput | null > | null,
};

export type DeleteBatchKdeDataInput = {
  id: string,
};

export type ModelUsersFilterInput = {
  email?: ModelIDInput | null,
  search_key?: ModelStringInput | null,
  first_name?: ModelStringInput | null,
  last_name?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  role_id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  and?: Array< ModelUsersFilterInput | null > | null,
  or?: Array< ModelUsersFilterInput | null > | null,
  not?: ModelUsersFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUsersConnection = {
  __typename: "ModelUsersConnection",
  items:  Array<users | null >,
  nextToken?: string | null,
};

export type ModelRolesFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  and?: Array< ModelRolesFilterInput | null > | null,
  or?: Array< ModelRolesFilterInput | null > | null,
  not?: ModelRolesFilterInput | null,
};

export type ModelRolesConnection = {
  __typename: "ModelRolesConnection",
  items:  Array<roles | null >,
  nextToken?: string | null,
};

export type ModelProductsFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  variety_name?: ModelStringInput | null,
  variety_code?: ModelStringInput | null,
  traceability_flow_id?: ModelIDInput | null,
  is_active?: ModelBooleanInput | null,
  and?: Array< ModelProductsFilterInput | null > | null,
  or?: Array< ModelProductsFilterInput | null > | null,
  not?: ModelProductsFilterInput | null,
};

export type ModelProductsConnection = {
  __typename: "ModelProductsConnection",
  items:  Array<products | null >,
  nextToken?: string | null,
};

export type ModelCtesFilterInput = {
  id?: ModelIDInput | null,
  index?: ModelIntInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelCtesFilterInput | null > | null,
  or?: Array< ModelCtesFilterInput | null > | null,
  not?: ModelCtesFilterInput | null,
};

export type ModelCtesConnection = {
  __typename: "ModelCtesConnection",
  items:  Array<ctes | null >,
  nextToken?: string | null,
};

export type ModelTraceabilityFlowFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  observers?: ModelStringInput | null,
  inputters?: ModelStringInput | null,
  and?: Array< ModelTraceabilityFlowFilterInput | null > | null,
  or?: Array< ModelTraceabilityFlowFilterInput | null > | null,
  not?: ModelTraceabilityFlowFilterInput | null,
};

export type ModelTraceabilityFlowConnection = {
  __typename: "ModelTraceabilityFlowConnection",
  items:  Array<traceabilityFlow | null >,
  nextToken?: string | null,
};

export type ModelCteFlowFilterInput = {
  id?: ModelIDInput | null,
  traceability_flow_id?: ModelIDInput | null,
  cte_id?: ModelIDInput | null,
  position?: ModelIntInput | null,
  and?: Array< ModelCteFlowFilterInput | null > | null,
  or?: Array< ModelCteFlowFilterInput | null > | null,
  not?: ModelCteFlowFilterInput | null,
};

export type ModelCteFlowConnection = {
  __typename: "ModelCteFlowConnection",
  items:  Array<cteFlow | null >,
  nextToken?: string | null,
};

export type ModelBatchFilterInput = {
  id?: ModelIDInput | null,
  batch_number?: ModelIDInput | null,
  batch_date?: ModelStringInput | null,
  product_id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBatchFilterInput | null > | null,
  or?: Array< ModelBatchFilterInput | null > | null,
  not?: ModelBatchFilterInput | null,
};

export type ModelBatchConnection = {
  __typename: "ModelBatchConnection",
  items:  Array<batch | null >,
  nextToken?: string | null,
};

export type ModelBatchKdeDataFilterInput = {
  id?: ModelIDInput | null,
  batch_id?: ModelIDInput | null,
  batch_number?: ModelIDInput | null,
  inputted_by?: ModelStringInput | null,
  inputter_id?: ModelIDInput | null,
  cte_id?: ModelIDInput | null,
  position?: ModelIntInput | null,
  and?: Array< ModelBatchKdeDataFilterInput | null > | null,
  or?: Array< ModelBatchKdeDataFilterInput | null > | null,
  not?: ModelBatchKdeDataFilterInput | null,
};

export type ModelBatchKdeDataConnection = {
  __typename: "ModelBatchKdeDataConnection",
  items:  Array<batchKdeData | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelcteFlowFilterInput = {
  id?: ModelIDInput | null,
  traceability_flow_id?: ModelIDInput | null,
  cte_id?: ModelIDInput | null,
  position?: ModelIntInput | null,
  and?: Array< ModelcteFlowFilterInput | null > | null,
  or?: Array< ModelcteFlowFilterInput | null > | null,
  not?: ModelcteFlowFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelbatchFilterInput = {
  id?: ModelIDInput | null,
  batch_number?: ModelIDInput | null,
  batch_date?: ModelStringInput | null,
  product_id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelbatchFilterInput | null > | null,
  or?: Array< ModelbatchFilterInput | null > | null,
  not?: ModelbatchFilterInput | null,
};

export type ModelbatchConnection = {
  __typename: "ModelbatchConnection",
  items:  Array<batch | null >,
  nextToken?: string | null,
};

export type ModelbatchKdeDataFilterInput = {
  id?: ModelIDInput | null,
  batch_id?: ModelIDInput | null,
  batch_number?: ModelIDInput | null,
  inputted_by?: ModelStringInput | null,
  inputter_id?: ModelIDInput | null,
  cte_id?: ModelIDInput | null,
  position?: ModelIntInput | null,
  and?: Array< ModelbatchKdeDataFilterInput | null > | null,
  or?: Array< ModelbatchKdeDataFilterInput | null > | null,
  not?: ModelbatchKdeDataFilterInput | null,
};

export type ModelSubscriptionUsersFilterInput = {
  email?: ModelSubscriptionIDInput | null,
  search_key?: ModelSubscriptionStringInput | null,
  first_name?: ModelSubscriptionStringInput | null,
  last_name?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  role_id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  is_active?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionUsersFilterInput | null > | null,
  or?: Array< ModelSubscriptionUsersFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionRolesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  is_active?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionRolesFilterInput | null > | null,
  or?: Array< ModelSubscriptionRolesFilterInput | null > | null,
};

export type ModelSubscriptionProductsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  variety_name?: ModelSubscriptionStringInput | null,
  variety_code?: ModelSubscriptionStringInput | null,
  traceability_flow_id?: ModelSubscriptionIDInput | null,
  is_active?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionProductsFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductsFilterInput | null > | null,
};

export type ModelSubscriptionCtesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  index?: ModelSubscriptionIntInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCtesFilterInput | null > | null,
  or?: Array< ModelSubscriptionCtesFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionTraceabilityFlowFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  is_active?: ModelSubscriptionBooleanInput | null,
  observers?: ModelSubscriptionStringInput | null,
  inputters?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTraceabilityFlowFilterInput | null > | null,
  or?: Array< ModelSubscriptionTraceabilityFlowFilterInput | null > | null,
};

export type ModelSubscriptionCteFlowFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  traceability_flow_id?: ModelSubscriptionIDInput | null,
  cte_id?: ModelSubscriptionIDInput | null,
  position?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionCteFlowFilterInput | null > | null,
  or?: Array< ModelSubscriptionCteFlowFilterInput | null > | null,
};

export type ModelSubscriptionBatchFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  batch_number?: ModelSubscriptionIDInput | null,
  batch_date?: ModelSubscriptionStringInput | null,
  product_id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBatchFilterInput | null > | null,
  or?: Array< ModelSubscriptionBatchFilterInput | null > | null,
};

export type ModelSubscriptionBatchKdeDataFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  batch_id?: ModelSubscriptionIDInput | null,
  batch_number?: ModelSubscriptionIDInput | null,
  inputted_by?: ModelSubscriptionStringInput | null,
  inputter_id?: ModelSubscriptionIDInput | null,
  cte_id?: ModelSubscriptionIDInput | null,
  position?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionBatchKdeDataFilterInput | null > | null,
  or?: Array< ModelSubscriptionBatchKdeDataFilterInput | null > | null,
};

export type CreateUsersMutationVariables = {
  input: CreateUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type CreateUsersMutation = {
  createUsers?:  {
    __typename: "users",
    email: string,
    search_key?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    phone?: string | null,
    role_id?: string | null,
    role?:  {
      __typename: "roles",
      id: string,
      name: string,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: string | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUsersMutationVariables = {
  input: UpdateUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type UpdateUsersMutation = {
  updateUsers?:  {
    __typename: "users",
    email: string,
    search_key?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    phone?: string | null,
    role_id?: string | null,
    role?:  {
      __typename: "roles",
      id: string,
      name: string,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: string | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUsersMutationVariables = {
  input: DeleteUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type DeleteUsersMutation = {
  deleteUsers?:  {
    __typename: "users",
    email: string,
    search_key?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    phone?: string | null,
    role_id?: string | null,
    role?:  {
      __typename: "roles",
      id: string,
      name: string,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: string | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRolesMutationVariables = {
  input: CreateRolesInput,
  condition?: ModelRolesConditionInput | null,
};

export type CreateRolesMutation = {
  createRoles?:  {
    __typename: "roles",
    id: string,
    name: string,
    is_active?: boolean | null,
    privileges?:  Array< {
      __typename: "privilegeType",
      id?: number | null,
      name?: string | null,
      selected?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRolesMutationVariables = {
  input: UpdateRolesInput,
  condition?: ModelRolesConditionInput | null,
};

export type UpdateRolesMutation = {
  updateRoles?:  {
    __typename: "roles",
    id: string,
    name: string,
    is_active?: boolean | null,
    privileges?:  Array< {
      __typename: "privilegeType",
      id?: number | null,
      name?: string | null,
      selected?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRolesMutationVariables = {
  input: DeleteRolesInput,
  condition?: ModelRolesConditionInput | null,
};

export type DeleteRolesMutation = {
  deleteRoles?:  {
    __typename: "roles",
    id: string,
    name: string,
    is_active?: boolean | null,
    privileges?:  Array< {
      __typename: "privilegeType",
      id?: number | null,
      name?: string | null,
      selected?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductsMutationVariables = {
  input: CreateProductsInput,
  condition?: ModelProductsConditionInput | null,
};

export type CreateProductsMutation = {
  createProducts?:  {
    __typename: "products",
    id: string,
    name: string,
    code?: string | null,
    variety_name?: string | null,
    variety_code?: string | null,
    traceability_flow_id?: string | null,
    traceability_flow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductsMutationVariables = {
  input: UpdateProductsInput,
  condition?: ModelProductsConditionInput | null,
};

export type UpdateProductsMutation = {
  updateProducts?:  {
    __typename: "products",
    id: string,
    name: string,
    code?: string | null,
    variety_name?: string | null,
    variety_code?: string | null,
    traceability_flow_id?: string | null,
    traceability_flow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductsMutationVariables = {
  input: DeleteProductsInput,
  condition?: ModelProductsConditionInput | null,
};

export type DeleteProductsMutation = {
  deleteProducts?:  {
    __typename: "products",
    id: string,
    name: string,
    code?: string | null,
    variety_name?: string | null,
    variety_code?: string | null,
    traceability_flow_id?: string | null,
    traceability_flow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCtesMutationVariables = {
  input: CreateCtesInput,
  condition?: ModelCtesConditionInput | null,
};

export type CreateCtesMutation = {
  createCtes?:  {
    __typename: "ctes",
    id: string,
    index?: number | null,
    name: string,
    kde_metadata?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCtesMutationVariables = {
  input: UpdateCtesInput,
  condition?: ModelCtesConditionInput | null,
};

export type UpdateCtesMutation = {
  updateCtes?:  {
    __typename: "ctes",
    id: string,
    index?: number | null,
    name: string,
    kde_metadata?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCtesMutationVariables = {
  input: DeleteCtesInput,
  condition?: ModelCtesConditionInput | null,
};

export type DeleteCtesMutation = {
  deleteCtes?:  {
    __typename: "ctes",
    id: string,
    index?: number | null,
    name: string,
    kde_metadata?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTraceabilityFlowMutationVariables = {
  input: CreateTraceabilityFlowInput,
  condition?: ModelTraceabilityFlowConditionInput | null,
};

export type CreateTraceabilityFlowMutation = {
  createTraceabilityFlow?:  {
    __typename: "traceabilityFlow",
    id: string,
    name: string,
    is_active?: boolean | null,
    observers?: Array< string | null > | null,
    inputters?: Array< string | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTraceabilityFlowMutationVariables = {
  input: UpdateTraceabilityFlowInput,
  condition?: ModelTraceabilityFlowConditionInput | null,
};

export type UpdateTraceabilityFlowMutation = {
  updateTraceabilityFlow?:  {
    __typename: "traceabilityFlow",
    id: string,
    name: string,
    is_active?: boolean | null,
    observers?: Array< string | null > | null,
    inputters?: Array< string | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTraceabilityFlowMutationVariables = {
  input: DeleteTraceabilityFlowInput,
  condition?: ModelTraceabilityFlowConditionInput | null,
};

export type DeleteTraceabilityFlowMutation = {
  deleteTraceabilityFlow?:  {
    __typename: "traceabilityFlow",
    id: string,
    name: string,
    is_active?: boolean | null,
    observers?: Array< string | null > | null,
    inputters?: Array< string | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCteFlowMutationVariables = {
  input: CreateCteFlowInput,
  condition?: ModelCteFlowConditionInput | null,
};

export type CreateCteFlowMutation = {
  createCteFlow?:  {
    __typename: "cteFlow",
    id: string,
    traceability_flow_id: string,
    cte_id: string,
    traceabilityFlow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    position?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCteFlowMutationVariables = {
  input: UpdateCteFlowInput,
  condition?: ModelCteFlowConditionInput | null,
};

export type UpdateCteFlowMutation = {
  updateCteFlow?:  {
    __typename: "cteFlow",
    id: string,
    traceability_flow_id: string,
    cte_id: string,
    traceabilityFlow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    position?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCteFlowMutationVariables = {
  input: DeleteCteFlowInput,
  condition?: ModelCteFlowConditionInput | null,
};

export type DeleteCteFlowMutation = {
  deleteCteFlow?:  {
    __typename: "cteFlow",
    id: string,
    traceability_flow_id: string,
    cte_id: string,
    traceabilityFlow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    position?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBatchMutationVariables = {
  input: CreateBatchInput,
  condition?: ModelBatchConditionInput | null,
};

export type CreateBatchMutation = {
  createBatch?:  {
    __typename: "batch",
    id: string,
    batch_number?: string | null,
    batch_date?: string | null,
    product_id: string,
    status?: string | null,
    batchKdeData?:  {
      __typename: "ModelbatchKdeDataConnection",
      nextToken?: string | null,
    } | null,
    product?:  {
      __typename: "products",
      id: string,
      name: string,
      code?: string | null,
      variety_name?: string | null,
      variety_code?: string | null,
      traceability_flow_id?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBatchMutationVariables = {
  input: UpdateBatchInput,
  condition?: ModelBatchConditionInput | null,
};

export type UpdateBatchMutation = {
  updateBatch?:  {
    __typename: "batch",
    id: string,
    batch_number?: string | null,
    batch_date?: string | null,
    product_id: string,
    status?: string | null,
    batchKdeData?:  {
      __typename: "ModelbatchKdeDataConnection",
      nextToken?: string | null,
    } | null,
    product?:  {
      __typename: "products",
      id: string,
      name: string,
      code?: string | null,
      variety_name?: string | null,
      variety_code?: string | null,
      traceability_flow_id?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBatchMutationVariables = {
  input: DeleteBatchInput,
  condition?: ModelBatchConditionInput | null,
};

export type DeleteBatchMutation = {
  deleteBatch?:  {
    __typename: "batch",
    id: string,
    batch_number?: string | null,
    batch_date?: string | null,
    product_id: string,
    status?: string | null,
    batchKdeData?:  {
      __typename: "ModelbatchKdeDataConnection",
      nextToken?: string | null,
    } | null,
    product?:  {
      __typename: "products",
      id: string,
      name: string,
      code?: string | null,
      variety_name?: string | null,
      variety_code?: string | null,
      traceability_flow_id?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBatchKdeDataMutationVariables = {
  input: CreateBatchKdeDataInput,
  condition?: ModelBatchKdeDataConditionInput | null,
};

export type CreateBatchKdeDataMutation = {
  createBatchKdeData?:  {
    __typename: "batchKdeData",
    id: string,
    batch_id: string,
    batch_number?: string | null,
    inputted_by?: string | null,
    inputter_id?: string | null,
    cte_id: string,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    inputter?:  {
      __typename: "users",
      email: string,
      search_key?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      phone?: string | null,
      role_id?: string | null,
      status?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    position: number,
    kdes?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBatchKdeDataMutationVariables = {
  input: UpdateBatchKdeDataInput,
  condition?: ModelBatchKdeDataConditionInput | null,
};

export type UpdateBatchKdeDataMutation = {
  updateBatchKdeData?:  {
    __typename: "batchKdeData",
    id: string,
    batch_id: string,
    batch_number?: string | null,
    inputted_by?: string | null,
    inputter_id?: string | null,
    cte_id: string,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    inputter?:  {
      __typename: "users",
      email: string,
      search_key?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      phone?: string | null,
      role_id?: string | null,
      status?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    position: number,
    kdes?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBatchKdeDataMutationVariables = {
  input: DeleteBatchKdeDataInput,
  condition?: ModelBatchKdeDataConditionInput | null,
};

export type DeleteBatchKdeDataMutation = {
  deleteBatchKdeData?:  {
    __typename: "batchKdeData",
    id: string,
    batch_id: string,
    batch_number?: string | null,
    inputted_by?: string | null,
    inputter_id?: string | null,
    cte_id: string,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    inputter?:  {
      __typename: "users",
      email: string,
      search_key?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      phone?: string | null,
      role_id?: string | null,
      status?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    position: number,
    kdes?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetUsersQueryVariables = {
  email: string,
};

export type GetUsersQuery = {
  getUsers?:  {
    __typename: "users",
    email: string,
    search_key?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    phone?: string | null,
    role_id?: string | null,
    role?:  {
      __typename: "roles",
      id: string,
      name: string,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: string | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  email?: string | null,
  filter?: ModelUsersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUsersConnection",
    items:  Array< {
      __typename: "users",
      email: string,
      search_key?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      phone?: string | null,
      role_id?: string | null,
      status?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRolesQueryVariables = {
  id: string,
};

export type GetRolesQuery = {
  getRoles?:  {
    __typename: "roles",
    id: string,
    name: string,
    is_active?: boolean | null,
    privileges?:  Array< {
      __typename: "privilegeType",
      id?: number | null,
      name?: string | null,
      selected?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRolesQueryVariables = {
  filter?: ModelRolesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRolesQuery = {
  listRoles?:  {
    __typename: "ModelRolesConnection",
    items:  Array< {
      __typename: "roles",
      id: string,
      name: string,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductsQueryVariables = {
  id: string,
};

export type GetProductsQuery = {
  getProducts?:  {
    __typename: "products",
    id: string,
    name: string,
    code?: string | null,
    variety_name?: string | null,
    variety_code?: string | null,
    traceability_flow_id?: string | null,
    traceability_flow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductsConnection",
    items:  Array< {
      __typename: "products",
      id: string,
      name: string,
      code?: string | null,
      variety_name?: string | null,
      variety_code?: string | null,
      traceability_flow_id?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCtesQueryVariables = {
  id: string,
};

export type GetCtesQuery = {
  getCtes?:  {
    __typename: "ctes",
    id: string,
    index?: number | null,
    name: string,
    kde_metadata?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCtesQueryVariables = {
  filter?: ModelCtesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCtesQuery = {
  listCtes?:  {
    __typename: "ModelCtesConnection",
    items:  Array< {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTraceabilityFlowQueryVariables = {
  id: string,
};

export type GetTraceabilityFlowQuery = {
  getTraceabilityFlow?:  {
    __typename: "traceabilityFlow",
    id: string,
    name: string,
    is_active?: boolean | null,
    observers?: Array< string | null > | null,
    inputters?: Array< string | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTraceabilityFlowsQueryVariables = {
  filter?: ModelTraceabilityFlowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTraceabilityFlowsQuery = {
  listTraceabilityFlows?:  {
    __typename: "ModelTraceabilityFlowConnection",
    items:  Array< {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCteFlowQueryVariables = {
  id: string,
};

export type GetCteFlowQuery = {
  getCteFlow?:  {
    __typename: "cteFlow",
    id: string,
    traceability_flow_id: string,
    cte_id: string,
    traceabilityFlow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    position?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCteFlowsQueryVariables = {
  filter?: ModelCteFlowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCteFlowsQuery = {
  listCteFlows?:  {
    __typename: "ModelCteFlowConnection",
    items:  Array< {
      __typename: "cteFlow",
      id: string,
      traceability_flow_id: string,
      cte_id: string,
      position?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBatchQueryVariables = {
  id: string,
};

export type GetBatchQuery = {
  getBatch?:  {
    __typename: "batch",
    id: string,
    batch_number?: string | null,
    batch_date?: string | null,
    product_id: string,
    status?: string | null,
    batchKdeData?:  {
      __typename: "ModelbatchKdeDataConnection",
      nextToken?: string | null,
    } | null,
    product?:  {
      __typename: "products",
      id: string,
      name: string,
      code?: string | null,
      variety_name?: string | null,
      variety_code?: string | null,
      traceability_flow_id?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBatchesQueryVariables = {
  filter?: ModelBatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBatchesQuery = {
  listBatches?:  {
    __typename: "ModelBatchConnection",
    items:  Array< {
      __typename: "batch",
      id: string,
      batch_number?: string | null,
      batch_date?: string | null,
      product_id: string,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBatchKdeDataQueryVariables = {
  id: string,
};

export type GetBatchKdeDataQuery = {
  getBatchKdeData?:  {
    __typename: "batchKdeData",
    id: string,
    batch_id: string,
    batch_number?: string | null,
    inputted_by?: string | null,
    inputter_id?: string | null,
    cte_id: string,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    inputter?:  {
      __typename: "users",
      email: string,
      search_key?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      phone?: string | null,
      role_id?: string | null,
      status?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    position: number,
    kdes?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBatchKdeDataQueryVariables = {
  filter?: ModelBatchKdeDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBatchKdeDataQuery = {
  listBatchKdeData?:  {
    __typename: "ModelBatchKdeDataConnection",
    items:  Array< {
      __typename: "batchKdeData",
      id: string,
      batch_id: string,
      batch_number?: string | null,
      inputted_by?: string | null,
      inputter_id?: string | null,
      cte_id: string,
      position: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CteFlowsByTraceability_flow_idAndPositionQueryVariables = {
  traceability_flow_id: string,
  position?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelcteFlowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CteFlowsByTraceability_flow_idAndPositionQuery = {
  cteFlowsByTraceability_flow_idAndPosition?:  {
    __typename: "ModelcteFlowConnection",
    items:  Array< {
      __typename: "cteFlow",
      id: string,
      traceability_flow_id: string,
      cte_id: string,
      position?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CteFlowsByCte_idQueryVariables = {
  cte_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelcteFlowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CteFlowsByCte_idQuery = {
  cteFlowsByCte_id?:  {
    __typename: "ModelcteFlowConnection",
    items:  Array< {
      __typename: "cteFlow",
      id: string,
      traceability_flow_id: string,
      cte_id: string,
      position?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BatchesByBatch_numberAndCreatedAtQueryVariables = {
  batch_number: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelbatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BatchesByBatch_numberAndCreatedAtQuery = {
  batchesByBatch_numberAndCreatedAt?:  {
    __typename: "ModelbatchConnection",
    items:  Array< {
      __typename: "batch",
      id: string,
      batch_number?: string | null,
      batch_date?: string | null,
      product_id: string,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BatchKdeDataByBatch_idAndPositionQueryVariables = {
  batch_id: string,
  position?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelbatchKdeDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BatchKdeDataByBatch_idAndPositionQuery = {
  batchKdeDataByBatch_idAndPosition?:  {
    __typename: "ModelbatchKdeDataConnection",
    items:  Array< {
      __typename: "batchKdeData",
      id: string,
      batch_id: string,
      batch_number?: string | null,
      inputted_by?: string | null,
      inputter_id?: string | null,
      cte_id: string,
      position: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
};

export type OnCreateUsersSubscription = {
  onCreateUsers?:  {
    __typename: "users",
    email: string,
    search_key?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    phone?: string | null,
    role_id?: string | null,
    role?:  {
      __typename: "roles",
      id: string,
      name: string,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: string | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
};

export type OnUpdateUsersSubscription = {
  onUpdateUsers?:  {
    __typename: "users",
    email: string,
    search_key?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    phone?: string | null,
    role_id?: string | null,
    role?:  {
      __typename: "roles",
      id: string,
      name: string,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: string | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
};

export type OnDeleteUsersSubscription = {
  onDeleteUsers?:  {
    __typename: "users",
    email: string,
    search_key?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    phone?: string | null,
    role_id?: string | null,
    role?:  {
      __typename: "roles",
      id: string,
      name: string,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: string | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRolesSubscriptionVariables = {
  filter?: ModelSubscriptionRolesFilterInput | null,
};

export type OnCreateRolesSubscription = {
  onCreateRoles?:  {
    __typename: "roles",
    id: string,
    name: string,
    is_active?: boolean | null,
    privileges?:  Array< {
      __typename: "privilegeType",
      id?: number | null,
      name?: string | null,
      selected?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRolesSubscriptionVariables = {
  filter?: ModelSubscriptionRolesFilterInput | null,
};

export type OnUpdateRolesSubscription = {
  onUpdateRoles?:  {
    __typename: "roles",
    id: string,
    name: string,
    is_active?: boolean | null,
    privileges?:  Array< {
      __typename: "privilegeType",
      id?: number | null,
      name?: string | null,
      selected?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRolesSubscriptionVariables = {
  filter?: ModelSubscriptionRolesFilterInput | null,
};

export type OnDeleteRolesSubscription = {
  onDeleteRoles?:  {
    __typename: "roles",
    id: string,
    name: string,
    is_active?: boolean | null,
    privileges?:  Array< {
      __typename: "privilegeType",
      id?: number | null,
      name?: string | null,
      selected?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductsSubscriptionVariables = {
  filter?: ModelSubscriptionProductsFilterInput | null,
};

export type OnCreateProductsSubscription = {
  onCreateProducts?:  {
    __typename: "products",
    id: string,
    name: string,
    code?: string | null,
    variety_name?: string | null,
    variety_code?: string | null,
    traceability_flow_id?: string | null,
    traceability_flow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductsSubscriptionVariables = {
  filter?: ModelSubscriptionProductsFilterInput | null,
};

export type OnUpdateProductsSubscription = {
  onUpdateProducts?:  {
    __typename: "products",
    id: string,
    name: string,
    code?: string | null,
    variety_name?: string | null,
    variety_code?: string | null,
    traceability_flow_id?: string | null,
    traceability_flow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductsSubscriptionVariables = {
  filter?: ModelSubscriptionProductsFilterInput | null,
};

export type OnDeleteProductsSubscription = {
  onDeleteProducts?:  {
    __typename: "products",
    id: string,
    name: string,
    code?: string | null,
    variety_name?: string | null,
    variety_code?: string | null,
    traceability_flow_id?: string | null,
    traceability_flow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    is_active?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCtesSubscriptionVariables = {
  filter?: ModelSubscriptionCtesFilterInput | null,
};

export type OnCreateCtesSubscription = {
  onCreateCtes?:  {
    __typename: "ctes",
    id: string,
    index?: number | null,
    name: string,
    kde_metadata?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCtesSubscriptionVariables = {
  filter?: ModelSubscriptionCtesFilterInput | null,
};

export type OnUpdateCtesSubscription = {
  onUpdateCtes?:  {
    __typename: "ctes",
    id: string,
    index?: number | null,
    name: string,
    kde_metadata?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCtesSubscriptionVariables = {
  filter?: ModelSubscriptionCtesFilterInput | null,
};

export type OnDeleteCtesSubscription = {
  onDeleteCtes?:  {
    __typename: "ctes",
    id: string,
    index?: number | null,
    name: string,
    kde_metadata?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTraceabilityFlowSubscriptionVariables = {
  filter?: ModelSubscriptionTraceabilityFlowFilterInput | null,
};

export type OnCreateTraceabilityFlowSubscription = {
  onCreateTraceabilityFlow?:  {
    __typename: "traceabilityFlow",
    id: string,
    name: string,
    is_active?: boolean | null,
    observers?: Array< string | null > | null,
    inputters?: Array< string | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTraceabilityFlowSubscriptionVariables = {
  filter?: ModelSubscriptionTraceabilityFlowFilterInput | null,
};

export type OnUpdateTraceabilityFlowSubscription = {
  onUpdateTraceabilityFlow?:  {
    __typename: "traceabilityFlow",
    id: string,
    name: string,
    is_active?: boolean | null,
    observers?: Array< string | null > | null,
    inputters?: Array< string | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTraceabilityFlowSubscriptionVariables = {
  filter?: ModelSubscriptionTraceabilityFlowFilterInput | null,
};

export type OnDeleteTraceabilityFlowSubscription = {
  onDeleteTraceabilityFlow?:  {
    __typename: "traceabilityFlow",
    id: string,
    name: string,
    is_active?: boolean | null,
    observers?: Array< string | null > | null,
    inputters?: Array< string | null > | null,
    cteFlow?:  {
      __typename: "ModelcteFlowConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCteFlowSubscriptionVariables = {
  filter?: ModelSubscriptionCteFlowFilterInput | null,
};

export type OnCreateCteFlowSubscription = {
  onCreateCteFlow?:  {
    __typename: "cteFlow",
    id: string,
    traceability_flow_id: string,
    cte_id: string,
    traceabilityFlow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    position?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCteFlowSubscriptionVariables = {
  filter?: ModelSubscriptionCteFlowFilterInput | null,
};

export type OnUpdateCteFlowSubscription = {
  onUpdateCteFlow?:  {
    __typename: "cteFlow",
    id: string,
    traceability_flow_id: string,
    cte_id: string,
    traceabilityFlow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    position?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCteFlowSubscriptionVariables = {
  filter?: ModelSubscriptionCteFlowFilterInput | null,
};

export type OnDeleteCteFlowSubscription = {
  onDeleteCteFlow?:  {
    __typename: "cteFlow",
    id: string,
    traceability_flow_id: string,
    cte_id: string,
    traceabilityFlow?:  {
      __typename: "traceabilityFlow",
      id: string,
      name: string,
      is_active?: boolean | null,
      observers?: Array< string | null > | null,
      inputters?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    position?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBatchSubscriptionVariables = {
  filter?: ModelSubscriptionBatchFilterInput | null,
};

export type OnCreateBatchSubscription = {
  onCreateBatch?:  {
    __typename: "batch",
    id: string,
    batch_number?: string | null,
    batch_date?: string | null,
    product_id: string,
    status?: string | null,
    batchKdeData?:  {
      __typename: "ModelbatchKdeDataConnection",
      nextToken?: string | null,
    } | null,
    product?:  {
      __typename: "products",
      id: string,
      name: string,
      code?: string | null,
      variety_name?: string | null,
      variety_code?: string | null,
      traceability_flow_id?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBatchSubscriptionVariables = {
  filter?: ModelSubscriptionBatchFilterInput | null,
};

export type OnUpdateBatchSubscription = {
  onUpdateBatch?:  {
    __typename: "batch",
    id: string,
    batch_number?: string | null,
    batch_date?: string | null,
    product_id: string,
    status?: string | null,
    batchKdeData?:  {
      __typename: "ModelbatchKdeDataConnection",
      nextToken?: string | null,
    } | null,
    product?:  {
      __typename: "products",
      id: string,
      name: string,
      code?: string | null,
      variety_name?: string | null,
      variety_code?: string | null,
      traceability_flow_id?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBatchSubscriptionVariables = {
  filter?: ModelSubscriptionBatchFilterInput | null,
};

export type OnDeleteBatchSubscription = {
  onDeleteBatch?:  {
    __typename: "batch",
    id: string,
    batch_number?: string | null,
    batch_date?: string | null,
    product_id: string,
    status?: string | null,
    batchKdeData?:  {
      __typename: "ModelbatchKdeDataConnection",
      nextToken?: string | null,
    } | null,
    product?:  {
      __typename: "products",
      id: string,
      name: string,
      code?: string | null,
      variety_name?: string | null,
      variety_code?: string | null,
      traceability_flow_id?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBatchKdeDataSubscriptionVariables = {
  filter?: ModelSubscriptionBatchKdeDataFilterInput | null,
};

export type OnCreateBatchKdeDataSubscription = {
  onCreateBatchKdeData?:  {
    __typename: "batchKdeData",
    id: string,
    batch_id: string,
    batch_number?: string | null,
    inputted_by?: string | null,
    inputter_id?: string | null,
    cte_id: string,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    inputter?:  {
      __typename: "users",
      email: string,
      search_key?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      phone?: string | null,
      role_id?: string | null,
      status?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    position: number,
    kdes?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBatchKdeDataSubscriptionVariables = {
  filter?: ModelSubscriptionBatchKdeDataFilterInput | null,
};

export type OnUpdateBatchKdeDataSubscription = {
  onUpdateBatchKdeData?:  {
    __typename: "batchKdeData",
    id: string,
    batch_id: string,
    batch_number?: string | null,
    inputted_by?: string | null,
    inputter_id?: string | null,
    cte_id: string,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    inputter?:  {
      __typename: "users",
      email: string,
      search_key?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      phone?: string | null,
      role_id?: string | null,
      status?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    position: number,
    kdes?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBatchKdeDataSubscriptionVariables = {
  filter?: ModelSubscriptionBatchKdeDataFilterInput | null,
};

export type OnDeleteBatchKdeDataSubscription = {
  onDeleteBatchKdeData?:  {
    __typename: "batchKdeData",
    id: string,
    batch_id: string,
    batch_number?: string | null,
    inputted_by?: string | null,
    inputter_id?: string | null,
    cte_id: string,
    cte?:  {
      __typename: "ctes",
      id: string,
      index?: number | null,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    inputter?:  {
      __typename: "users",
      email: string,
      search_key?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      phone?: string | null,
      role_id?: string | null,
      status?: string | null,
      is_active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    position: number,
    kdes?:  Array< {
      __typename: "kdeMetaData",
      index?: number | null,
      input_id?: string | null,
      is_required?: boolean | null,
      label?: string | null,
      place_holder?: string | null,
      default_value?: string | null,
      value?: string | null,
      input_type?: string | null,
      help_message?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
