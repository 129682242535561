import { INPUT_OPTIONS } from "@/constants/options";
import Input from "../forms/Input";
import Checkbox from "../forms/Checkbox";
import Select from "../forms/Select";
import Textarea from "../forms/Textarea";
import Date from "../forms/Date";
import Switch from "../forms/Switch";
import MultiSelect from "../forms/MultiSelect";
import Number from "../forms/Number";
import RichText from "../forms/RichText";

type RequiredProps = {
  type: string;
  id: string;
  label: string;
};
type OptionalProps = {
  isRequired: boolean;
  placeholder: string;
  options: any[];
  helperText: string;
  value: any;
};

type Props = RequiredProps & Partial<OptionalProps>;

export default function renderFormInput({
  type,
  id,
  label,
  isRequired,
  placeholder,
  options,
  helperText,
  value,
}: Props) {
  switch (type) {
    case INPUT_OPTIONS.TEXT_INPUT:
      return (
        <Input
          id={id}
          label={label}
          isRequired={isRequired}
          placeholder={placeholder}
          helperText={helperText}
          value={value}
        />
      );

    case INPUT_OPTIONS.NUMBER_INPUT:
      return (
        <Number
          id={id}
          label={label}
          isRequired={isRequired}
          placeholder={placeholder}
          helperText={helperText}
          value={value}
        />
      );

    case INPUT_OPTIONS.SELECT:
      return (
        <Select
          id={id}
          label={label}
          isRequired={isRequired}
          options={options}
          helperText={helperText}
          value={value}
        />
      );

    case INPUT_OPTIONS.MULTISELECT:
      return (
        <MultiSelect
          id={id}
          label={label}
          isRequired={isRequired}
          options={options}
          helperText={helperText}
          value={value}
        />
      );

    case INPUT_OPTIONS.TEXT_AREA:
      return (
        <Textarea
          id={id}
          label={label}
          isRequired={isRequired}
          helperText={helperText}
          value={value}
        />
      );

    case INPUT_OPTIONS.DATE:
      return (
        <Date
          id={id}
          label={label}
          isRequired={isRequired}
          placeholder={placeholder}
          helperText={helperText}
          value={value as unknown as Date}
        />
      );

    case INPUT_OPTIONS.CHECKBOX:
      return (
        <Checkbox
          id={id}
          label={label}
          isRequired={isRequired}
          helperText={helperText}
          checked={value ?? false}
        />
      );

    case INPUT_OPTIONS.SWITCH:
      return (
        <Switch
          id={id}
          label={label}
          isRequired={isRequired}
          helperText={helperText}
          checked={value ?? false}
        />
      );

    case INPUT_OPTIONS.RICHTEXT:
      return (
        <RichText
          id={id}
          label={label}
          isRequired={isRequired}
          helperText={helperText}
        />
      );



    default:
      return null;
  }
}
