import Container from "@/components/shared/Container";
import Header from "@/components/shared/Header";
import Table from "@/components/shared/Table";
import { trackingColumns } from "@/constants/columns";
import { useResetPagination } from "@/hooks/useResetPagination";
import { filterByName, filterByStartandEndDate } from "@/utils/filters";
import { useCombinedStores } from "@/zustand/stores";
import { BATCH_ACTION_TYPES_ENUM } from "@/zustand/types/batchTypes";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { OverlayPanel } from "primereact/overlaypanel";
import { Nullable } from "primereact/ts-helpers";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { pivot } from "@/utils/helpers/pivot";

const { convertArrayToCSV } = require('convert-array-to-csv');

export default function History() {

  //zustand hooks
  const BATCH_ACTION_TYPE = useCombinedStores((state) => state.BATCH_ACTION_TYPE);
  const products = useCombinedStores((state) => state.products);
  const batches = useCombinedStores((state) => state.batches);
  const batch = useCombinedStores((state) => state.batch);
  const getProducts = useCombinedStores((state) => state.getProducts);
  const getBatches = useCombinedStores((state) => state.getBatches);
  const deleteBatch = useCombinedStores((state) => state.deleteBatch);
  const addBatch = useCombinedStores((state) => state.addBatch);
  const isAddBatchLoading = useCombinedStores((state) => state.isAddBatchLoading);
  const isGetBatchesLoading = useCombinedStores((state) => state.isGetBatchesLoading);
  const updateZustandStateBatch = useCombinedStores((state) => state.updateZustandStateBatch);
  const errorMessageBatch = useCombinedStores((state) => state.errorMessageBatch);
  const resetActionTypeBatch = useCombinedStores((state) => state.resetActionTypeBatch);

  const datePanel = React.useRef<any>(null);
  const navigate = useNavigate();

  const [dates, setDates] = React.useState<Nullable<(Date | null)[]>>(null);
  const [showSearch, toggleSearch] = React.useReducer((state) => !state, false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [history, setHistory] = React.useState<any>([]);
  const [filter, setFilter] = React.useState<string>("");
  const resetPagination = useResetPagination(filter);

  const filteredData = filterByName(batches, filter, "batch_number");



  React.useEffect(() => {
    getBatches();
  }, []);

  React.useEffect(() => {
    switch (BATCH_ACTION_TYPE) {

      case BATCH_ACTION_TYPES_ENUM.GET_BATCHES_ERROR:
        toast.error(errorMessageBatch);
        resetActionTypeBatch();
        break;

      default:
        break;
    }

  }, [BATCH_ACTION_TYPE]);

  return (
    <div>
      <Header
        title="History"
        // onFilter={(e) => datePanel.current.toggle(e)}
        onDownload={() => {
          const csv = convertArrayToCSV(pivot(batches));
          const a = document.createElement("a");
          a.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
          a.setAttribute("download", "history.csv");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
         }}
        showSearch={showSearch}
        onSearch={toggleSearch}
        value={filter}
        onChange={(e: any) => setFilter(e.target.value)}
        onCloseSearch={() => {
          toggleSearch();
          setFilter("");
        }}
        menuComponent={
          <OverlayPanel ref={datePanel}>
            <div className="flex flex-column gap-2">
              <label>Select date range</label>

              <Calendar
                value={dates}
                onChange={(e) => setDates(e.value)}
                selectionMode="range"
                readOnlyInput
              />

              {/* reset filter */}
              <Button
                label="Reset"
                className="p-button-secondary"
                onClick={() => {
                  setDates(null);
                }}
              />
            </div>
          </OverlayPanel>
        }
      />

      <Container loading={isGetBatchesLoading}>
        <Table
          //@ts-ignore
          data={filteredData}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          //@ts-ignore
          columns={[
            ...trackingColumns(),
            {
              selector: (row: any) => row.id,
              grow: 1,
              center: true,
              cell: (row: any) => (
                <Button
                  icon="pi pi-eye"
                  className="p-button-text"
                  onClick={() =>
                    navigate(`/admin/history/${row.id}`)
                  }
                />
              ),
            },
          ]}
          resetPagination={resetPagination}
          emptyIcon="pi-history"
          emptyMessage="There are no captured traceability process to show at the moment"
        />
      </Container>
    </div>
  );
}
