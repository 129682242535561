import React from "react";
import If from "./If";
import { ProgressSpinner } from "primereact/progressspinner";

interface Props {
  children: React.ReactNode;
  loading?: boolean;
  loader?: React.ReactNode;
}

export default function Container({
  children,
  loading = false,
  loader = (
    <div className="min-h-screen flex justify-content-center">
      <ProgressSpinner
        style={{ width: "70px", height: "70px", marginTop: "30vh" }}
      />
    </div>
  ),
}: Props) {
  return <If condition={loading} then={loader} else={children} />;
}
