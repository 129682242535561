import { useState, useCallback, useMemo, useEffect } from "react";

export const useContainerWidth = (id: string) => {
  const [width, setWidth] = useState(0);

  const updateWidth = useCallback(() => {
    const container = document.getElementById(id);
    setWidth(container?.clientWidth || 0);
  }, [id]);

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [updateWidth, id, width]);


  return width;
};
