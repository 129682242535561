import { CreateUsersInput, UpdateUsersInput,DeleteUsersInput, users } from "@/graphql/API"

export const enum USER_ACTION_TYPES_ENUM  {
    INITIAL_VALUE = 300,
   

    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    
    GET_USER_BY_EMAIL_SUCCESS,
    GET_USER_BY_EMAIL_ERROR,
    GET_USER_BY_EMAIL_EMPTY,
   
    ADD_USER_SUCCESS,
    ADD_USER_ERROR,

    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,

    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    
}

export type UserType = Partial<users>

export interface UserSliceInterface {
    USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM,

    isGetUsersLoading: boolean,
    isAddUserLoading: boolean,
    isUpdateUserLoading: boolean,
    isDeleteUserLoading: boolean,

    user: UserType,
    users: UserType[],
    errorMessageUser:string,
    nextTokenUser:string,
  
    updateZustandStateUser : (object :Partial<UserSliceInterface>) => void
    getUsers: () => void
    getUserByEmail: (email:String) => void
    addUser: (user: CreateUsersInput) => void
    updateUser: (user:UpdateUsersInput) => void
    deleteUser: (user: DeleteUsersInput) => void
    updateUserStatus: (id: string, status:true|false) => void

    resetActionTypeUser: ()=>void
   
  }