export type RoutePropType={
  id:number,
  to: string,
  icon: string,
  label: string,
  isActive:string,
}

export const ADMIN_NAVIGATOR:RoutePropType[] = [
  {
    id:1,
    to: "admin",
    icon: "pi pi-chart-bar",
    label: "Dashboard",
    isActive: "admin",
  },
  {
    id:2,
    to: "/admin/users",
    icon: "pi pi-users",
    label: "User Management",
    isActive: "admin/users",
  },
  {
    id:3,
    to: "/admin/roles",
    icon: "pi pi-sitemap",
    label: "Roles",
    isActive: "admin/roles",
  },
  {
    id:4,
    to: "/admin/products",
    icon: "pi pi-box",
    label: "Products",
    isActive: "admin/products",
  },
  {
    id:5,
    to: "/admin/flow",
    icon: "pi pi-refresh",
    label: "Traceability Flow",
    isActive: "admin/flow",
  },
  {
    id:6,
    to: "/admin/cte",
    icon: "pi pi-bolt",
    label: "Critical Tracking Events",
    isActive: "admin/cte",
  },
  {
    id:7,
    to: "/admin/tracking",
    icon: "pi pi-compass",
    label: "Data Entry",
    isActive: "admin/tracking",
  },
  {
    id:8,
    to: "/admin/history",
    icon: "pi pi-history",
    label: "History",
    isActive: "admin/history",
  },
];

export const FOOTER_NAVIGATOR = [
  {
    to: "https://myrosswood.com/",
    label: "Home",
    isActive: "admin/home",
  },
  {
    to: "https://myrosswood.com/company/",
    label: "Company",
    isActive: "admin/company",
  },
  {
    to: "https://myrosswood.com/about-us/",
    label: "Our Story",
    isActive: "admin/our-story",
  },
  {
    to: "https://myrosswood.com/our-products/",
    label: "Products",
    isActive: "admin/products",
  },
  {
    to: "https://myrosswood.com/#:~:text=Our%20Story%0AProducts-,Impact,-Stories",
    label: "Impact",
    isActive: "admin/impact",
  },
  {
    to: "https://myrosswood.com/blogs/",
    label: "Stories",
    isActive: "admin/stories",
  },
];
