import { BreadCrumb } from 'primereact/breadcrumb'
import React from 'react'

const Header = () => {
    const items = [{ label: 'Tracking Detail' },];
  return (
      <BreadCrumb model={items} home={{ label: 'Tracking Product', url: "/" }} pt={{
        root: { className: 'bg-black-alpha-90 text-white-alpha-90' },
        label: ({ props, }) => ({
            className: `text-white-alpha-90`
        }),
        separatorIcon: ({ props, }) => ({
            className: 'text-white-alpha-90'
        })
    }} className="w-full bg-black-alpha-90 border-none border-noround text-white-alpha-90 md:px-8" style={{color: "white"}}  />
  )
}

export default Header
