import React from "react";
import { InputTextProps } from "primereact/inputtext";
import { useField } from "formik";
import { classNames } from "primereact/utils";
import OtpInput from "react-otp-input";

interface Props {
  id: string;
  label?: string;
  isRequired?: boolean;
  helperText?: string;
}

type InputProps = Props & InputTextProps;

const FormOtpInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { id, label, isRequired, helperText } = props;

    const [field, meta] = useField(id);
    const { value,  onChange } = field;
    const { error, touched } = meta;

    return (
      <div className="flex flex-column gap-2 p-fluid col">
        <label
          htmlFor={id}
          className={classNames({
            "p-error": touched && error,
          })}
        >
          {label} {isRequired && <span className="p-error">*</span>}
        </label>

        <OtpInput
          {...props}
          value={value}
          onChange={(e) => {
            onChange(id)(e);
          }}
          inputType="number"
          numInputs={6}
          shouldAutoFocus={true}
          renderSeparator={<span className="mx-2"> - </span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{ width: "3.5rem", height: "3.5rem" }}
        />
        {helperText && <small className="p-help text-500">{helperText}</small>}
        {touched && error && <small className="p-error">{error}</small>}
      </div>
    );
  }
);

FormOtpInput.displayName = "OTP Input";
export default FormOtpInput;
