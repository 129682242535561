import { batchKdeData } from "@/graphql/API";
import Timeline from "../shared/Timeline";

export default function Timeslines({ item }: { item: batchKdeData[] }) {
 
  return (
    <div className="mx-2 my-4 relative p-3" id="timeline">
      <Timeline data={item} /> 
    </div>
  );
}
