import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Hero = () => {

  const navigate = useNavigate()
  const [trackingNumber ,setTrackingNumber] = React.useState<string>("");

  return (
    <div
      className="flex flex-column justify-content-center align-items-center md:py-8 py-6 px-4 w-full"
      style={{ backgroundColor: "#EA088B" }}
    >
      <h2 className="text-center text-white text-5xl ">Trace your product</h2>
      <p className="text-center text-white text-lg  ">
        Trace your product by entering the lot number to find out the current
        status
      </p>
      <div className="flex md:flex-row flex-column gap-3 md:w-9 w-full md:px-8">
        <span className="p-input-icon-left w-full">
          <i className="pi pi-search text-white" />
          <InputText
            value={trackingNumber}
            placeholder="Enter Product Lot number"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.16)", color: "white" }}
            className="w-full track-search"
            onChange={(e)=>{
              setTrackingNumber(e.target.value)
            }}
          />
        </span>
        <Button label="Track" className="bg-white text-primary-500"
          onClick={() => {

            if(trackingNumber.length < 4){
              toast.info("Enter a valid tracking number")
              return;
            }
            navigate("/tracking/"+trackingNumber)}

          }
        />
      </div>
    </div>
  );
};

export default Hero;
