import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import React from "react";
import { Divider } from "primereact/divider";
import ChangePassword from "@/components/profile/ChangePassword";
import ProfileInfo from "@/components/profile/ProfileInfo";

interface Props {
  show: boolean;
  onHide: () => void;
  user: any;
}

export default function Profile({ show, onHide, user }: Props) {
  return (
    <Dialog
      visible={show}
      onHide={onHide}
      header="Your Profile"
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      blockScroll={true}
    >
      <div className="flex flex-column gap-5">
        <section className="flex justify-content-center">
          <Avatar label={user.initials} size="xlarge" />
        </section>
        <ProfileInfo user={user} />
        <Divider />
      </div>
      <ChangePassword />
    </Dialog>
  );
}
