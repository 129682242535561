import Details from "@/components/history/Details";
import KDEInputs from "@/components/history/KDEInputs";
import Container from "@/components/shared/Container";
import Header from "@/components/shared/Header";
import { useCombinedStores } from "@/zustand/stores";
import { BATCH_ACTION_TYPES_ENUM, BatchType } from "@/zustand/types/batchTypes";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { generateClient } from 'aws-amplify/api';
import * as subscriptions from "@/graphql/subscriptions";

const client = generateClient();

export default function TrackingDetails() {
  //zustand hooks
  const BATCH_ACTION_TYPE = useCombinedStores(
    (state) => state.BATCH_ACTION_TYPE
  );
  const batch = useCombinedStores((state) => state.batch);
  const getBatchById = useCombinedStores((state) => state.getBatchById);
  const updateZustandStateBatch = useCombinedStores((state) => state.updateZustandStateBatch);
  const isGetBatchLoading = useCombinedStores(
    (state) => state.isGetBatchLoading
  );
  const resetActionTypeBatch = useCombinedStores(
    (state) => state.resetActionTypeBatch
  );
  const errorMessageBatch = useCombinedStores(
    (state) => state.errorMessageBatch
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    getBatchById(batch.id);
  }, []);

  React.useEffect(() => {
    switch (BATCH_ACTION_TYPE) {
      case BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_SUCCESS:
        // toast.success("Batch  successfully");
        resetActionTypeBatch();
        break;
      case BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_ERROR:
        toast.error(errorMessageBatch);
        resetActionTypeBatch();
        break;

      default:
        break;
    }
  }, [BATCH_ACTION_TYPE]);

  //realtime listenere for create new batchKDE 
  React.useEffect(() => {
    const createSub = client.graphql({
      query: subscriptions.onCreateBatchKdeData,
      variables: { filter: { batch_id: { eq: batch.id } } },
    }).subscribe({
      next({ data }) {
        
        /**
         * merge this backeKDEData to the Batch data in memory
        */
       const createdBatchKdeData = data.onCreateBatchKdeData;

        const batchArrayItems = [createdBatchKdeData, ...batch?.batchKdeData?.items];

        const batchT: BatchType = {
          ...batch,
          batchKdeData: {
            ...batch.batchKdeData,
            items: batchArrayItems
          }
        };

        updateZustandStateBatch({ batch:batchT });

       
      },
      error(error) {
        console.log("--error from onCreateBatchKdeData subsription", error)
      },
    });
    return () => {
      createSub.unsubscribe();
    }
  }, [batch])

  //realtime listenere for update batchKDE 
  React.useEffect(() => {
    const updateBatchSub = client.graphql({
      query: subscriptions.onUpdateBatchKdeData,
      variables: { filter: { batch_id: { eq: batch.id } } },
    }).subscribe({
      next({ data }) {
        
        /**
         * merge this backeKDEData with the Batch data in zustand
        */
       const updatedBatchKdeData = data.onUpdateBatchKdeData;

        const batchArrayItems = [...batch?.batchKdeData?.items];

        const indexToUpdate = batch.batchKdeData.items.findIndex(value => value.id === data.onUpdateBatchKdeData.id);

        if (indexToUpdate !== -1) {
          batchArrayItems[indexToUpdate] = updatedBatchKdeData;

          const batchT: BatchType = {
            ...batch,
            batchKdeData: {
              ...batch.batchKdeData,
              items: batchArrayItems
            }
          };

          updateZustandStateBatch({ batch:batchT });
        }




      },
      error(error) {
        console.log("--error from onUpdateBatchKdeData subsription", error)
      },
    });

    return () => {
      updateBatchSub.unsubscribe();
    }
  }, [batch])


  return (
    <Container loading={isGetBatchLoading}>
      <div className="flex flex-column gap-4">
        <Header title="KDE Details" onBack={() => navigate(-1)} />
        <Details item={batch} />


        <KDEInputs
          traceabilityFlow={batch?.product?.traceability_flow}
          batchKdeData={batch?.batchKdeData?.items}
        />

      </div>
    </Container>
  );
}
