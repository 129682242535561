import { useUpdateEffect } from "primereact/hooks";
import { useState } from "react";

export const useResetPagination = (filter) => {
  const [resetPagination, setResetPagination] = useState(false);

  useUpdateEffect(() => {
    if (filter === "") {
      setResetPagination(true);
    }
  }, [filter]);

  return resetPagination;
};
