import Input from "@/components/forms/Input";
import Header from "@/components/shared/Header";
import { CreateTraceabilityFlowInput, UpdateCteFlowInput, UpdateTraceabilityFlowInput } from "@/graphql/API";
import { useCombinedStores } from "@/zustand/stores";
import { CteType } from "@/zustand/types/cteTypes";
import { FLOW_ACTION_TYPES_ENUM } from "@/zustand/types/flowTypes";
import { FormikProvider, useFormik } from "formik";
import { Reorder, useDragControls } from "framer-motion";
import { Button } from "primereact/button";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CreateEditFlow() {

  //zustand hooks
  const users = useCombinedStores((state) => state.users);
  const flow = useCombinedStores((state) => state.flow);
  const ctes = useCombinedStores((state) => state.ctes);
  const getUsers = useCombinedStores((state) => state.getUsers);
  const getCtes = useCombinedStores((state) => state.getCtes);
  const addFlow = useCombinedStores((state) => state.addFlow);
  const addCTEFlow = useCombinedStores((state) => state.addCTEFlow);
  const upsertCTEFlow = useCombinedStores((state) => state.upsertCTEFlow);
  const updateFlow = useCombinedStores((state) => state.updateFlow);
  const isAddCTEFlowLoading = useCombinedStores((state) => state.isAddCTEFlowLoading);
  const isAddFlowLoading = useCombinedStores((state) => state.isAddFlowLoading);
  const isUpdateFlowLoading = useCombinedStores((state) => state.isUpdateFlowLoading);
  const isUpsertCTEFlowLoading = useCombinedStores((state) => state.isUpsertCTEFlowLoading);
  const FLOW_ACTION_TYPE = useCombinedStores((state) => state.FLOW_ACTION_TYPE);
  const resetActionTypeFlow = useCombinedStores((state) => state.resetActionTypeFlow);
  const errorMessageFlow = useCombinedStores((state) => state.errorMessageFlow);

  const navigate = useNavigate();

  const isUpdate = flow?.id ? true : false;

  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
  const listwrapper =
    "border-1 border-gray-400 p-4 flex flex-column gap-2 md:gap-3 border-round";


  const dragControls = useDragControls();

  const formik = useFormik({
    initialValues: {
      name: flow?.name ?? "",
      observers: flow.observers ?? [],
      inputters: flow.inputters ?? [],
      ctes: flow.cteFlow?.items?.map( cteFlow => cteFlow.cte) ?? [],
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = "Name of the flow is required";
      }

      // if (values.observers.length < 1) {
      //   errors.observers = "Select at least 1 observer";
      // }
      // if (values.inputters.length < 1) {
      //   errors.inputters = "Select at least 1 inputter";
      // }

      return errors;
    },
    enableReinitialize: true,
    onSubmit: (values: { name: string, observers: string[], inputters: string[], ctes: CteType[] }) => {
      if (isUpdate) {

        const updatedTraceabilityFlow: UpdateTraceabilityFlowInput = {
          id: flow.id,
          name: values.name,
          inputters: values.inputters,
          observers: values.observers
        }
        updateFlow(updatedTraceabilityFlow);

      } else {
        const newTraceabilityFlow: CreateTraceabilityFlowInput = {
          name: values.name,
          inputters: values.inputters,
          observers: values.observers
        };

        addFlow(newTraceabilityFlow);

      }
    },
  });

  const { values, handleSubmit, setFieldValue, errors } = formik;


  React.useEffect(() => {
    getUsers();
    getCtes()
  }, []);

  //listener for FLOW_ACTION_TYPE
  React.useEffect(() => {
    
    switch (FLOW_ACTION_TYPE) {
      case FLOW_ACTION_TYPES_ENUM.ADD_FLOW_SUCCESS:
        toast.success("Traceability flow added successfully");
        resetActionTypeFlow();

        break;
      case FLOW_ACTION_TYPES_ENUM.ADD_CTE_FLOW_SUCCESS:
        toast.success("CTE flow updated successfully");
        resetActionTypeFlow();
        // navigate("/admin/cte")
        break;
      case FLOW_ACTION_TYPES_ENUM.ADD_CTE_FLOW_ERROR:
        toast.error(errorMessageFlow);
        resetActionTypeFlow();
        break;
      case FLOW_ACTION_TYPES_ENUM.ADD_FLOW_ERROR:
        toast.error(errorMessageFlow);
        resetActionTypeFlow();
        break;

      case FLOW_ACTION_TYPES_ENUM.UPDATE_FLOW_SUCCESS:
        toast.success("Traceability flow updated successfully");
        resetActionTypeFlow();

        break;
      case FLOW_ACTION_TYPES_ENUM.UPDATE_FLOW_ERROR:
        toast.error(errorMessageFlow);
        break;

      default:
        break;
    }
  }, [FLOW_ACTION_TYPE]);

  return (
    <div>
      <Header
        onBack={() => navigate(-1)}
        title={isUpdate ? "Edit Traceability Flow" : "Create Traceability Flow"}
        // extraAction="Save"
        extraActionIcon="pi pi-save"
        onExtraAction={() => handleSubmit()}
        extraActionLoading={saveLoading}
      />
      <ConfirmPopup />

      <FormikProvider value={formik}>
        <div className="flex flex-column gap-5 p-3 md:p-5">
          <section className={`${listwrapper} md:flex-row`}>
            <Input id="name" onChange={formik.handleChange} label="Name" placeholder="eg. Custom Flow 1" />
          </section>

          {/* Inputters */}
          {/* <section className={`${listwrapper}`}>
            <div className="flex flex-column  gap-2">
              <label htmlFor="users">Inputters (Who can Edit a CTE) *</label>

              <MultiSelect
                value={values.inputters}
                onChange={(e) => {

                  setFieldValue("inputters", e.value)
                }}
                options={users}
                optionLabel="email"
                optionValue="email"
                display="chip"
                placeholder="Select Inputters"
                className="w-full"
              />
              {errors.inputters && <small className="p-error">{errors.inputters}</small>}

            </div>
          </section> */}

          {/* Observers */}
          <section className={`${listwrapper}`}>
            {/* <div className="flex flex-column  gap-2">
              <label htmlFor="users">Observers (Who should be notified when a CTE is updated) </label>

              <MultiSelect
                id="observers"
                value={values.observers}
                onChange={(e) => {

                  setFieldValue("observers", e.value)
                }}
                options={users}
                optionLabel="email"
                optionValue="email"
                display="chip"
                placeholder="Select Observers"
                className="w-full"
              />
              {errors.observers && <small className="p-error">{errors.observers}</small>}

            </div> */}

            <Button
              loading={isAddFlowLoading || isUpdateFlowLoading}
              label={isUpdate ? "Edit Traceability Flow" : "Create Traceability Flow"}
              className=" text-white"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                handleSubmit();
              }}
            />
          </section>

          <Divider />

          {/* CTE FLOW */}
          <h1 className="text-xl">CTE Flow</h1>
          <section className={`${listwrapper}`}>
            <div className="flex flex-column align-items-center gap-2">
              <label htmlFor="ctes">Critical Tracking Events</label>
              <Dropdown
                optionLabel="name"
                className="w-12 md:w-8"
                options={ctes}
                onChange={(e) => {
                  // add item to ctes list but only if it doesn't already exist
                  if (!values.ctes?.some(cte => cte.id === e.value.id)) {
                    setFieldValue("ctes", [...values.ctes, e.value])
                  } else {
                    toast.warn("Item already exists in the list");
                  }
                }}
                filter
                placeholder="Select an item to add to the list"
                emptyMessage={
                  <div className="col p-fluid text-center">
                    <Button
                      label="ADD NEW CTE"
                      onClick={() => navigate("/admin/cte/new")}
                    />
                  </div>
                }
              />
            </div>

            <div className="flex flex-column align-items-center">
              <h4 className="mb-1">Critical Tracking Events</h4>
              <span>
                You can order the list by dragging the items up and down
              </span>

              <Reorder.Group
                axis="y"
                values={values.ctes}
                onReorder={(newOrder) => {
                  setFieldValue("ctes", newOrder);
                }}
                className="w-12 md:w-9 flex flex-column gap-3"
              >
                {values.ctes?.map((cte: CteType, index: number) => {
                  return (
                    <Reorder.Item
                      key={cte.id}
                      value={cte}
                      id={cte.id}
                      dragControls={dragControls}
                      className="flex flex-row relative align-items-center justify-content-between gap-4 px-4 py-3  border-1 border-primary-300 hover:border-primary-500 border-round shadow-1 md:text-xl cursor-pointer"
                    >
                      <div className="flex gap-4 align-items-center">
                        <span>{index + 1}</span>
                        <i className="pi pi-bars cursor-move" />
                        <span>{cte.name}</span>
                      </div>
                      <Button
                        icon="pi pi-trash"
                        className="p-button-sm p-button-text"
                        onClick={(event) => {
                          confirmPopup({
                            target: event.currentTarget,
                            message:
                              "Are you sure you want to remove this CTE from the list?",
                            icon: "pi pi-exclamation-triangle",
                            accept: () => {
                              setFieldValue("ctes", values.ctes.filter((item: any) => item !== cte));

                            },
                            reject: () => { },
                          });
                        }}
                      />
                    </Reorder.Item>
                  );
                })}
              </Reorder.Group>
            </div>

            <Button
              loading={isAddCTEFlowLoading ||isUpsertCTEFlowLoading}
              label="Update CTE List"
              className=" text-white"
              disabled={!isUpdate}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();

                const existingCTEFlowIds = flow.cteFlow.items.map(flows=>flows.id);
                

                let cteFlowArray: UpdateCteFlowInput[] = values.ctes?.map((cte, index, cteFlowArray) => {
                  const ID = flow.id + cte.id
                  const cteFlow: UpdateCteFlowInput = {
                    id: ID,
                    traceability_flow_id: flow.id,
                    cte_id: cte.id,
                    position: index,
                  }
                  return cteFlow;
                });
                
                upsertCTEFlow(cteFlowArray,existingCTEFlowIds );
                
                

              }}
            />
          </section>
        </div>
      </FormikProvider>
    </div>
  );
}
