/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateUsers = /* GraphQL */ `subscription OnCreateUsers($filter: ModelSubscriptionUsersFilterInput) {
  onCreateUsers(filter: $filter) {
    email
    search_key
    first_name
    last_name
    phone
    role_id
    role {
      id
      name
      is_active
      createdAt
      updatedAt
      __typename
    }
    status
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUsersSubscriptionVariables,
  APITypes.OnCreateUsersSubscription
>;
export const onUpdateUsers = /* GraphQL */ `subscription OnUpdateUsers($filter: ModelSubscriptionUsersFilterInput) {
  onUpdateUsers(filter: $filter) {
    email
    search_key
    first_name
    last_name
    phone
    role_id
    role {
      id
      name
      is_active
      createdAt
      updatedAt
      __typename
    }
    status
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUsersSubscriptionVariables,
  APITypes.OnUpdateUsersSubscription
>;
export const onDeleteUsers = /* GraphQL */ `subscription OnDeleteUsers($filter: ModelSubscriptionUsersFilterInput) {
  onDeleteUsers(filter: $filter) {
    email
    search_key
    first_name
    last_name
    phone
    role_id
    role {
      id
      name
      is_active
      createdAt
      updatedAt
      __typename
    }
    status
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUsersSubscriptionVariables,
  APITypes.OnDeleteUsersSubscription
>;
export const onCreateRoles = /* GraphQL */ `subscription OnCreateRoles($filter: ModelSubscriptionRolesFilterInput) {
  onCreateRoles(filter: $filter) {
    id
    name
    is_active
    privileges {
      id
      name
      selected
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRolesSubscriptionVariables,
  APITypes.OnCreateRolesSubscription
>;
export const onUpdateRoles = /* GraphQL */ `subscription OnUpdateRoles($filter: ModelSubscriptionRolesFilterInput) {
  onUpdateRoles(filter: $filter) {
    id
    name
    is_active
    privileges {
      id
      name
      selected
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRolesSubscriptionVariables,
  APITypes.OnUpdateRolesSubscription
>;
export const onDeleteRoles = /* GraphQL */ `subscription OnDeleteRoles($filter: ModelSubscriptionRolesFilterInput) {
  onDeleteRoles(filter: $filter) {
    id
    name
    is_active
    privileges {
      id
      name
      selected
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRolesSubscriptionVariables,
  APITypes.OnDeleteRolesSubscription
>;
export const onCreateProducts = /* GraphQL */ `subscription OnCreateProducts($filter: ModelSubscriptionProductsFilterInput) {
  onCreateProducts(filter: $filter) {
    id
    name
    code
    variety_name
    variety_code
    traceability_flow_id
    traceability_flow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductsSubscriptionVariables,
  APITypes.OnCreateProductsSubscription
>;
export const onUpdateProducts = /* GraphQL */ `subscription OnUpdateProducts($filter: ModelSubscriptionProductsFilterInput) {
  onUpdateProducts(filter: $filter) {
    id
    name
    code
    variety_name
    variety_code
    traceability_flow_id
    traceability_flow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductsSubscriptionVariables,
  APITypes.OnUpdateProductsSubscription
>;
export const onDeleteProducts = /* GraphQL */ `subscription OnDeleteProducts($filter: ModelSubscriptionProductsFilterInput) {
  onDeleteProducts(filter: $filter) {
    id
    name
    code
    variety_name
    variety_code
    traceability_flow_id
    traceability_flow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductsSubscriptionVariables,
  APITypes.OnDeleteProductsSubscription
>;
export const onCreateCtes = /* GraphQL */ `subscription OnCreateCtes($filter: ModelSubscriptionCtesFilterInput) {
  onCreateCtes(filter: $filter) {
    id
    index
    name
    kde_metadata {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCtesSubscriptionVariables,
  APITypes.OnCreateCtesSubscription
>;
export const onUpdateCtes = /* GraphQL */ `subscription OnUpdateCtes($filter: ModelSubscriptionCtesFilterInput) {
  onUpdateCtes(filter: $filter) {
    id
    index
    name
    kde_metadata {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCtesSubscriptionVariables,
  APITypes.OnUpdateCtesSubscription
>;
export const onDeleteCtes = /* GraphQL */ `subscription OnDeleteCtes($filter: ModelSubscriptionCtesFilterInput) {
  onDeleteCtes(filter: $filter) {
    id
    index
    name
    kde_metadata {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCtesSubscriptionVariables,
  APITypes.OnDeleteCtesSubscription
>;
export const onCreateTraceabilityFlow = /* GraphQL */ `subscription OnCreateTraceabilityFlow(
  $filter: ModelSubscriptionTraceabilityFlowFilterInput
) {
  onCreateTraceabilityFlow(filter: $filter) {
    id
    name
    is_active
    observers
    inputters
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTraceabilityFlowSubscriptionVariables,
  APITypes.OnCreateTraceabilityFlowSubscription
>;
export const onUpdateTraceabilityFlow = /* GraphQL */ `subscription OnUpdateTraceabilityFlow(
  $filter: ModelSubscriptionTraceabilityFlowFilterInput
) {
  onUpdateTraceabilityFlow(filter: $filter) {
    id
    name
    is_active
    observers
    inputters
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTraceabilityFlowSubscriptionVariables,
  APITypes.OnUpdateTraceabilityFlowSubscription
>;
export const onDeleteTraceabilityFlow = /* GraphQL */ `subscription OnDeleteTraceabilityFlow(
  $filter: ModelSubscriptionTraceabilityFlowFilterInput
) {
  onDeleteTraceabilityFlow(filter: $filter) {
    id
    name
    is_active
    observers
    inputters
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTraceabilityFlowSubscriptionVariables,
  APITypes.OnDeleteTraceabilityFlowSubscription
>;
export const onCreateCteFlow = /* GraphQL */ `subscription OnCreateCteFlow($filter: ModelSubscriptionCteFlowFilterInput) {
  onCreateCteFlow(filter: $filter) {
    id
    traceability_flow_id
    cte_id
    traceabilityFlow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    position
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCteFlowSubscriptionVariables,
  APITypes.OnCreateCteFlowSubscription
>;
export const onUpdateCteFlow = /* GraphQL */ `subscription OnUpdateCteFlow($filter: ModelSubscriptionCteFlowFilterInput) {
  onUpdateCteFlow(filter: $filter) {
    id
    traceability_flow_id
    cte_id
    traceabilityFlow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    position
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCteFlowSubscriptionVariables,
  APITypes.OnUpdateCteFlowSubscription
>;
export const onDeleteCteFlow = /* GraphQL */ `subscription OnDeleteCteFlow($filter: ModelSubscriptionCteFlowFilterInput) {
  onDeleteCteFlow(filter: $filter) {
    id
    traceability_flow_id
    cte_id
    traceabilityFlow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    position
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCteFlowSubscriptionVariables,
  APITypes.OnDeleteCteFlowSubscription
>;
export const onCreateBatch = /* GraphQL */ `subscription OnCreateBatch($filter: ModelSubscriptionBatchFilterInput) {
  onCreateBatch(filter: $filter) {
    id
    batch_number
    batch_date
    product_id
    status
    batchKdeData {
      nextToken
      __typename
    }
    product {
      id
      name
      code
      variety_name
      variety_code
      traceability_flow_id
      is_active
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBatchSubscriptionVariables,
  APITypes.OnCreateBatchSubscription
>;
export const onUpdateBatch = /* GraphQL */ `subscription OnUpdateBatch($filter: ModelSubscriptionBatchFilterInput) {
  onUpdateBatch(filter: $filter) {
    id
    batch_number
    batch_date
    product_id
    status
    batchKdeData {
      nextToken
      __typename
    }
    product {
      id
      name
      code
      variety_name
      variety_code
      traceability_flow_id
      is_active
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBatchSubscriptionVariables,
  APITypes.OnUpdateBatchSubscription
>;
export const onDeleteBatch = /* GraphQL */ `subscription OnDeleteBatch($filter: ModelSubscriptionBatchFilterInput) {
  onDeleteBatch(filter: $filter) {
    id
    batch_number
    batch_date
    product_id
    status
    batchKdeData {
      nextToken
      __typename
    }
    product {
      id
      name
      code
      variety_name
      variety_code
      traceability_flow_id
      is_active
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBatchSubscriptionVariables,
  APITypes.OnDeleteBatchSubscription
>;
export const onCreateBatchKdeData = /* GraphQL */ `subscription OnCreateBatchKdeData(
  $filter: ModelSubscriptionBatchKdeDataFilterInput
) {
  onCreateBatchKdeData(filter: $filter) {
    id
    batch_id
    batch_number
    inputted_by
    inputter_id
    cte_id
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    inputter {
      email
      search_key
      first_name
      last_name
      phone
      role_id
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
    position
    kdes {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBatchKdeDataSubscriptionVariables,
  APITypes.OnCreateBatchKdeDataSubscription
>;
export const onUpdateBatchKdeData = /* GraphQL */ `subscription OnUpdateBatchKdeData(
  $filter: ModelSubscriptionBatchKdeDataFilterInput
) {
  onUpdateBatchKdeData(filter: $filter) {
    id
    batch_id
    batch_number
    inputted_by
    inputter_id
    cte_id
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    inputter {
      email
      search_key
      first_name
      last_name
      phone
      role_id
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
    position
    kdes {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBatchKdeDataSubscriptionVariables,
  APITypes.OnUpdateBatchKdeDataSubscription
>;
export const onDeleteBatchKdeData = /* GraphQL */ `subscription OnDeleteBatchKdeData(
  $filter: ModelSubscriptionBatchKdeDataFilterInput
) {
  onDeleteBatchKdeData(filter: $filter) {
    id
    batch_id
    batch_number
    inputted_by
    inputter_id
    cte_id
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    inputter {
      email
      search_key
      first_name
      last_name
      phone
      role_id
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
    position
    kdes {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBatchKdeDataSubscriptionVariables,
  APITypes.OnDeleteBatchKdeDataSubscription
>;
