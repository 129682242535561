import Input from "@/components/forms/Input";
import Select from "@/components/forms/Select";
import { FormikProvider, useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";

const ProfileInfo = ({ user }: { user: any }) => {
  const formik = useFormik({
    initialValues: {
      ...user,
    },
    enableReinitialize: true,
    onSubmit: (values: any) => {},
  });

  const { handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <div className="flex flex-column gap-4">
        <section className="flex flex-row gap-4 grid">
          <Input id="firstname" label="First Name" disabled />
          <Input id="lastname" label="Last Name" disabled />
        </section>

        <section className="flex flex-row gap-4 grid">
          <Input id="email" icon="pi pi-envelope" label="Email" disabled />

          <Input id="phone" icon="pi pi-phone" label="Phone Number" disabled />
        </section>

        <Select
          id="role"
          placeholder="Select Role"
          label="Role"
          isRequired
          options={[
            { label: "Super Admin", value: "Super Admin" },
            { label: "Inputer", value: "Inputer" },
          ]}
          optionLabel="label"
          disabled
        />
      </div>
      {/* <div className="flex justify-content-end">
        <Button
          //   loading={isLoginWithPasswordLoading}
          label="Update"
          className=" text-white"
          disabled={!formik.isValid}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            handleSubmit();
          }}
        />
      </div> */}
    </FormikProvider>
  );
};

export default ProfileInfo;
