import useSettingsStore from "@/theme/settings";
import { Sidebar } from "react-pro-sidebar";
import { NavLink, useLocation } from "react-router-dom";
import { renderNavigator } from "../../utils/renderNavigator";
import { useCombinedStores } from "@/zustand/stores";

interface SideBarProps {
  role: string;
  onMenuClick?: () => void;
  show?: boolean;
}

export default function SideBar({
  role,
  onMenuClick,
  show = false,
}: SideBarProps) {
  const theme = useSettingsStore((state) => state.theme);
  const authUser = useCombinedStores((state) => state.authUser);

  const location = useLocation();

  /**
   * @description style for active and inactive menu
   **/
  const activeStyle = `w-full p-3 gap-3 flex align-items-center cursor-pointer no-underline
  bg-${theme === "light" ? "black" : "white"}-alpha-10 
  border-round font-bold
  ${theme === "light" ? " text-primary-600" : "text-primary-50"}
  mb-1`;
  const style = `w-full p-3 gap-3 flex no-underline text-color border-round align-items-center cursor-pointer 
    hover:bg-${theme === "light" ? "black" : "white"}-alpha-10 
    ${theme === "light" ? "hover:text-primary-600" : "hover:text-primary-50"}
    mb-1`;

  /**
   * @description check if the path is active
   **/
  const isActive = (path: string) => {
    const pathname = location.pathname.replace(/^\/+/, "");
    return pathname === path ? activeStyle : style;
  };



  return (
    <Sidebar
      backgroundColor="transparent"
      className={`h-full ${
        show === false
          ? "lg:flex hidden border-gray-400  border-right-1"
          : "flex lg:hidden border-none"
      } flex-column col-fixed pt-2 min-h-screen`}
      style={{
        width: show === false ? "270px" : "auto",
        backgroundColor: "transparent",
      }}
    >
      {(renderNavigator(authUser?.role?.privileges ?? []) || []).map((route, index) => (
        <NavLink
          key={index}
          to={route.to}
          className={isActive(route.isActive)}
          onClick={onMenuClick}
        >
          <i className={`${route.icon}`} />
          <span>{route.label}</span>
        </NavLink>
      ))}

    </Sidebar>
  );
}
