import { StateCreator } from "zustand";

import { CreateBatchInput, CreateBatchKdeDataInput, DeleteBatchInput, UpdateBatchInput, UpdateBatchKdeDataInput } from "@/graphql/API";
import * as customQueries from "@/graphql/customQueries";
import * as mutations from "@/graphql/mutations";
import { amplifyCreateData, amplifyGetAllData, amplifyGetDataById, amplifyUpdateData } from "@/utils/amplify/api";
import { CombinedStoresTypes } from "@/zustand/types";
import { BATCH_ACTION_TYPES_ENUM, BatchSliceInterface } from "@/zustand/types/batchTypes";
import zukeeper from "zukeeper";

export const createBatchSlice: StateCreator<CombinedStoresTypes, [], [], BatchSliceInterface> = zukeeper((set, get) => ({
    BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.INITIAL_VALUE,

    isGetBatchLoading: false,
    isGetBatchesLoading: false,
    isGetBatchByIdLoading: false,
    isAddBatchLoading: false,
    isUpdateBatchLoading: false,
    isAddKDEDataLoading: false,
    isUpdateKDEDataLoading: false,
    isDeleteBatchLoading: false,
    errorMessageBatch: "",
    nextTokenBatch:"",

    batch: {},
    batches: [],

    resetActionTypeBatch: () => {
        set( {
            BATCH_ACTION_TYPE : BATCH_ACTION_TYPES_ENUM.INITIAL_VALUE
        });

    },

    updateZustandStateBatch : (object :Partial<BatchSliceInterface>)=>{
        set(object)
    },

    getBatches:async ()=>{
        set({
            isGetBatchesLoading: true,
          });
      
          try {
            const success = await amplifyGetAllData(customQueries.listBatches);
            
            const { items, nextToken } = success.data?.listBatches;
            set({
              isGetBatchesLoading: false,
              errorMessageBatch: "",
              BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.GET_BATCHES_SUCCESS,
              batches: items,
              nextTokenProduct: nextToken,
            });
          } catch (error) {
            set({
              isGetBatchesLoading: false,
              errorMessageBatch: error.message,
              BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.GET_BATCHES_ERROR            });
          }

    },


    getBatchByNumber: async (batchNumber:String) => {
        set({
            isGetBatchLoading: true
        });

       

        try {
            const success = await amplifyGetDataById(customQueries.batchesByBatch_numberAndCreatedAt , {batch_number:batchNumber});
            const getBatchByBatchnumber = success.data?.batchesByBatch_numberAndCreatedAt;

            
            if(getBatchByBatchnumber.items.length === 0){
                set({
                    batch:{},
                    isGetBatchLoading: false,
                    errorMessageBatch: "INVALID BATCH NUMBER",
                    BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_EMPTY,
                })
                return;
            }

            //getting here means search by batch number was successful
            set({
                isGetBatchLoading: false,
                errorMessageBatch: "",
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_SUCCESS,
                batch: getBatchByBatchnumber.items[0],
            })
            
        } catch (error) {
            const errorMessage = error.errors ? error.errors[0].message : error.message;

            set({
                isGetBatchLoading: false,
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_ERROR,
                errorMessageBatch: errorMessage
            });

        }
    },
    getBatchById: async (id:String) => {
        set({
            isGetBatchLoading: true
        });

       

        try {
            const success = await amplifyGetDataById(customQueries.getBatch , {id:id});
            const getBatch = success.data?.getBatch;
            set({
                isGetBatchLoading: false,
                errorMessageBatch: "",
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_SUCCESS,
                batch: getBatch,
            })
            
        } catch (error) {
            const errorMessage = error.errors ? error.errors[0].message : error.message;

            set({
                isGetBatchLoading: false,
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.GET_BATCH_BY_ID_ERROR,
                errorMessageBatch: errorMessage
            });

        }
    },

    addBatch: async(newBatch: CreateBatchInput) => {
        set( {
            isAddBatchLoading : true,
        });

        try {
            const successValue = await amplifyCreateData(mutations.createBatch, newBatch);
            const result = successValue.data.createBatch;
    
            set({
                isAddBatchLoading: false,
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.ADD_BATCH_SUCCESS,
                batch: result
            });
            
        } catch (error) {

            let errorMessage = error.errors ? error.errors[0].message : error.message;
            if(error.errors[0].errorType === "DynamoDB:ConditionalCheckFailedException" ){
                errorMessage ="Batch number already exists";
            }
            
            set({
                isAddBatchLoading: false,
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.ADD_BATCH_ERROR,
                errorMessageBatch: errorMessage
            });
        }

       
    },
    updateBatch: async (batch: UpdateBatchInput) => {
        set({
            isUpdateBatchLoading: true,
        });

        try {
            const successValue = await amplifyUpdateData(mutations.updateBatch, batch);
            const result = successValue.data.updateBatch;
            set({
                isUpdateBatchLoading: false,
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.UPDATE_BATCH_SUCCESS,
            });
            return result;
        } catch (error) {

            const errorMessage = error.errors ? error.errors[0].message : error.message;
            
            set({
                isUpdateBatchLoading: false,
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.UPDATE_BATCH_ERROR,
                errorMessageBatch: error.message
            });

        }

    },

    addKDEData:async (kdeData: CreateBatchKdeDataInput) => {
        set( {
            isAddKDEDataLoading: true,
        });

        try {
            const successValue = await amplifyCreateData(mutations.createBatchKdeData, kdeData);
            const result = successValue.data.createBatchKdeData;
    
            set({
                isAddKDEDataLoading: false,
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.ADD_KDE_DATA_SUCCESS,
            });
            
        } catch (error) {
            const errorMessage = error.errors ? error.errors[0].message : error.message;
            
            set({
                isAddKDEDataLoading: false,
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.ADD_KDE_DATA_ERROR,
                errorMessageBatch: errorMessage
            });
        }

    },
    updateKDEData:async (kdeData: UpdateBatchKdeDataInput) => {
        set( {
            isUpdateKDEDataLoading: true,
        });

        try {
            const successValue = await amplifyCreateData(mutations.updateBatchKdeData, kdeData);
            const result = successValue.data.updateBatchKdeData;
    
            set({
                isUpdateKDEDataLoading: false,
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.UPDATE_KDE_DATA_SUCCESS,
            });
            
        } catch (error) {
            const errorMessage = error.errors ? error.errors[0].message : error.message;

            set({
                isUpdateKDEDataLoading: false,
                BATCH_ACTION_TYPE: BATCH_ACTION_TYPES_ENUM.UPDATE_KDE_DATA_ERROR,
                errorMessageBatch: errorMessage
            });
        }
    },
    

   

    deleteBatch: (batch: DeleteBatchInput) => { },
}));
