import Input from "@/components/forms/Input";
import Header from "@/components/shared/Header";
import { INPUT_TYPES } from "@/constants/options";
import { CreateCtesInput, KdeMetaDataInput, UpdateCtesInput, kdeMetaData } from "@/graphql/API";
import { createKdeMetaDataInputArray } from "@/utils/graphqlInputArrayConversions";
import { useCombinedStores } from "@/zustand/stores";
import { CTE_ACTION_TYPES_ENUM } from "@/zustand/types/cteTypes";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


export default function CreateEditCte() {

  const cte = useCombinedStores((state) => state.cte);
  const addCte = useCombinedStores((state) => state.addCte);
  const updateCte = useCombinedStores((state) => state.updateCte);
  const resetActionTypeCte = useCombinedStores((state) => state.resetActionTypeCte);
  const isAddCteLoading = useCombinedStores((state) => state.isAddCteLoading);
  const isUpdateCteLoading = useCombinedStores((state) => state.isUpdateCteLoading);
  const errorMessageCte = useCombinedStores((state) => state.errorMessageCte);
  const CTE_ACTION_TYPE = useCombinedStores((state) => state.CTE_ACTION_TYPE);

  const navigate = useNavigate();

  const isUpdate = cte?.id ? true : false;

  const listwrapper =
    "border-1 border-gray-400 p-4 flex flex-column gap-2 md:gap-3 border-round";



  const initialKeteMetaData: KdeMetaDataInput = {
    index: 0,
    input_id: null,
    is_required: false,
    label: "",
    place_holder: "",
    default_value: "",
    value: null,
    input_type: "",
    help_message: "",
    options: []
  };

  const formik = useFormik({
    initialValues: {
      name: cte.name ?? "",
      kde_metadata: cte.kde_metadata ?? [initialKeteMetaData],
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = "Name of the flow is required";
      }

      return errors;
    },
    enableReinitialize: true,
    onSubmit: (values: CreateCtesInput) => {
     if(isUpdate){
       const updatedCteObject: UpdateCtesInput = {
          id: cte.id,
          name: values.name,
          kde_metadata:createKdeMetaDataInputArray(  values.kde_metadata),
        };
        updateCte(updatedCteObject);
      } else {
        const newCte: CreateCtesInput = {
          name: values.name,
          kde_metadata:createKdeMetaDataInputArray(  values.kde_metadata),

        };
        addCte(newCte);
        
      }
    },
  });

  const { values, handleSubmit,  setFieldValue } = formik;

//listener for CTE_ACTION_TYPE
React.useEffect(()=>{
  switch (CTE_ACTION_TYPE) {
    case CTE_ACTION_TYPES_ENUM.ADD_CTE_SUCCESS:
      toast.success("CTE added successfully");
      resetActionTypeCte();
      navigate("/admin/cte")

      break;
    case CTE_ACTION_TYPES_ENUM.ADD_CTE_ERROR:
      toast.error(errorMessageCte);
      resetActionTypeCte();
      break;
    
    case CTE_ACTION_TYPES_ENUM.UPDATE_CTE_SUCCESS:
      toast.success("CTE updated successfully");
      resetActionTypeCte();
      navigate("/admin/cte")

      break;
    case CTE_ACTION_TYPES_ENUM.UPDATE_CTE_ERROR:
      toast.error(errorMessageCte);
      resetActionTypeCte();
      break;
  
    default:
      break;
  }
},[CTE_ACTION_TYPE]);

  return (
    <div>
      <Header
        onBack={() => navigate(-1)}
        title={isUpdate ? "Edit CTE" : "Create CTE"}
        extraAction="Save"
        extraActionIcon="pi pi-save"
        onExtraAction={() => handleSubmit()}
        extraActionLoading={isAddCteLoading || isUpdateCteLoading}
      />
      <ConfirmDialog />

      <FormikProvider value={formik}>
        <div className="flex flex-column gap-5 p-3 md:p-5">
          <section className={`${listwrapper} md:flex-row`}>
            <div className="w-full md:w-8 flex flex-column  gap-2">
              {/* <label htmlFor="name">Name</label> */}
              <Input
                id="name"
                className="w-full"
                label="Name"
                placeholder="eg Packaging"
                isRequired
              />

            </div>
          </section>

     

          <h3 className="my-0">Configure Key Data Elements</h3>

          <span>
            Key Data Elements (KDEs) are the data elements that are required to
            be captured for the CTE.
          </span>

          {/* list of kde_metadata, with delete button */}

          <section className="flex flex-column gap-3">
            <FieldArray name="kde_metadata">
              {({ insert, remove, push }) =>
                <>

                  {values.kde_metadata?.map((kde: any, index: number) => {
                    return (
                      <section
                        key={index}
                        className="flex flex-column gap-4 px-5 py-2 border-1 border-gray-400 border-round"
                      >
                        {/* // ---------------- HEADER START ---------------- */}
                        <div className="flex flex-row justify-content-between align-items-center">
                          <span className="font-bold">KDE {index + 1}</span>

                          <Button
                            label="Add New KDE"
                            icon="pi pi-plus"
                            className="p-button-danger p-button-text"
                            onClick={() => {
                              push(initialKeteMetaData)
                            }}
                          />
                          <Button
                            icon="pi pi-trash"
                            className="p-button-danger p-button-text"
                            onClick={() => {
                              confirmDialog({
                                message: "Are you sure you want to delete this KDE?",
                                icon: "pi pi-exclamation-triangle",
                                accept: () => {
                                  remove(index)
                                },
                                reject: () => { },
                              });
                            }}
                          />
                        </div>
                        {/* // ---------------- HEADER END ---------------- */}

                        {/* ------------------- IS REQUIRED ------------------ */}

                        <section className="flex gap-3 align-items-center">
                          <span>Is this KDE required?</span>

                          <InputSwitch
                            id={`kde_metadata.${index}.is_required`}
                            name={`kde_metadata.${index}.is_required`}
                            checked={values?.kde_metadata[index]?.is_required}
                            onChange={formik.handleChange}
                          />
                        </section>

                        {/* ------------------- IS REQUIRED ------------------ */}

                        {/* // -------------- PLACEHOLDER AND LABEL ---------------- */}
                        <section className="flex flex-column md:flex-row gap-4 ">
                          <div className="w-full md:w-6 flex flex-column gap-2">
                            <label>Label</label>
                            <InputText
                              id={`kde_metadata.${index}.label`}
                              name={`kde_metadata.${index}.label`}
                              className="w-full"
                              placeholder="eg. Label for the KDE"
                              value={values?.kde_metadata[index]?.label}
                              onChange={formik.handleChange}
                            />

                          </div>

                          <div className="w-full md:w-6 flex flex-column gap-2">
                            <label>Place Holder</label>
                            <InputText
                              id={`kde_metadata.${index}.place_holder`}
                              name={`kde_metadata.${index}.place_holder`}
                              className="w-full"
                              placeholder="eg. Placeholder for the KDE"
                              value={values?.kde_metadata[index]?.place_holder}
                              onChange={formik.handleChange}
                            />

                          </div>
                        </section>
                        {/* // -------------- PLACEHOLDER AND LABEL ---------------- */}

                        {/* // ------------------- TYPE HELPER TEXT ------------------- */}
                        <section className="flex flex-column md:flex-row gap-4 ">
                          <div className="w-full md:w-6 flex flex-column gap-2">
                            <label>Input Type</label>
                            <Dropdown
                              id={`kde_metadata.${index}.input_type`}
                              name={`kde_metadata.${index}.input_type`}
                              className="w-full"
                              filter
                              placeholder="Select Input type"
                              value={values?.kde_metadata[index]?.input_type}
                              options={INPUT_TYPES}
                              onChange={formik.handleChange}
                            />
                          </div>

                          <div className="w-full md:w-6 flex flex-column gap-2">
                            <label>Help Message</label>
                            <InputText
                              id={`kde_metadata.${index}.help_message`}
                              name={`kde_metadata.${index}.help_message`}
                              className="w-full"
                              placeholder="eg. Help message for the KDE"
                              value={values?.kde_metadata[index]?.help_message}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </section>
                        {/* // ------------------- TYPE HELPER TEXT ------------------- */}
                        {/* ---------------- OPTIONS FOR THE INPUT IF ANY ---------------- */}
                        {/* options input with a remove button and a button to add a new option */}

                        <section className="flex flex-column gap-3">
                          <label>Options</label>

                          <FieldArray name={`kde_metadata.${index}.options`}>
                            {({ insert, remove, push }) => <>
                              {/* add new option button if no option exist */}
                              {values.kde_metadata[index]?.options.length < 1 && <section>
                                <Button
                                  label={"ADD AN OPTION"}
                                  icon="pi pi-plus"
                                  className="p-button-sm p-button-outlined mb-3"
                                  onClick={() => {
                                   push("")
                                  }}
                                />
                              </section>}
                              {values?.kde_metadata[index]?.options?.map((option: any, i: number, optionsArray: [string]) => {
                                return (
                                  <section
                                    key={i}
                                    className="flex flex-row justify-content-between align-items-center gap-3"
                                  >
                                    <InputText
                                      key={i.toString()}
                                      id={`kde_metadata.${index}.options.${i}`}
                                      name={`kde_metadata.${index}.options.${i}`}
                                      className="w-full"
                                      placeholder="eg. options for the input type"
                                      value={values.kde_metadata[index]?.options[i]}
                                      onChange={formik.handleChange}
                                    />

                                    <Button
                                      icon="pi pi-plus"
                                      className="p-button-danger p-button-text"
                                      onClick={() => {
                                        push('')
                                      }}
                                    />
                                    <Button
                                      icon="pi pi-trash"
                                      className="p-button-danger p-button-text"
                                      onClick={() => {
                                        confirmDialog({
                                          message:
                                            "Are you sure you want to delete this option?",
                                          icon: "pi pi-exclamation-triangle",
                                          accept: () => {
                                            remove(i);
                                          },
                                          reject: () => { },
                                        });
                                      }}
                                    />
                                  </section>
                                );
                              })}
                            </>

                            }
                          </FieldArray>


                        </section>

                        {/* ---------------- OPTIONS FOR THE INPUT IF ANY ---------------- */}
                      </section>
                    );
                  })}

                </>

              }

            </FieldArray>


          </section>
        </div>
      </FormikProvider>
    </div>
  );
}
