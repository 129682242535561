import EmptyState from "@/components/dashboard/EmptyState";
import RecentCtes from "@/components/dashboard/RecentCtes";
import RecentDataEntry from "@/components/dashboard/RecentDataEntry";
import RecentProducts from "@/components/dashboard/RecentProducts";
import RecentTraceability from "@/components/dashboard/RecentTraceability";
import RecentUsers from "@/components/dashboard/RecentUsers";
import StatisticsCard from "@/components/dashboard/StatisticsCard";
import { emptyStates, statistics } from "@/constants/content";
import { useCombinedStores } from "@/zustand/stores";
import React from "react";

export default function Dashboard() {
  const getAuthenticatedUser = useCombinedStores(
    (state) => state.getAuthenticatedUser
  );
  const user_id = useCombinedStores((state) => state.user_id);

  // React.useEffect(() => {
  //   getAuthenticatedUser(user_id);
  // }, []);

  // get all data
  const users = useCombinedStores((state) => state.users);
  const roles = useCombinedStores((state) => state.roles);
  const products = useCombinedStores((state) => state.products);
  const flows = useCombinedStores((state) => state.flows);
  const ctes = useCombinedStores((state) => state.ctes);
  const batches = useCombinedStores((state) => state.batches);

  // get active and inactive data
  const getActiveUsers = users?.filter((item) => item?.is_active === true);
  const getInActiveUsers = users?.filter((item) => item?.is_active === false);

  const getActiveRoles = roles?.filter((item) => item?.is_active === true);
  const getInActiveRoles = roles?.filter((item) => item?.is_active === false);

  const getActiveProducts = products?.filter(
    (item) => item?.is_active === true
  );
  const getInActiveProducts = products?.filter(
    (item) => item?.is_active === false
  );

  const getActiveFlows = flows?.filter((item) => item?.is_active === true);
  const getInActiveFlows = flows?.filter((item) => item?.is_active === false);

  // get total count
  const totalCounts = {
    users: {
      length: users?.length,
      active: getActiveUsers?.length,
      inactive: getInActiveUsers?.length,
    },
    roles: {
      length: roles?.length,
      active: getActiveRoles?.length,
      inactive: getInActiveRoles?.length,
    },
    products: {
      length: products?.length,
      active: getActiveProducts?.length,
      inactive: getInActiveProducts?.length,
    },
    flows: {
      length: flows?.length,
      active: getActiveFlows?.length,
      inactive: getInActiveFlows?.length,
    },
    ctes: {
      length: ctes?.length,
      active: "N/A",
      inactive: "N/A",
    },
    batches: {
      length: batches?.length,
      active: "N/A",
      inactive: "N/A",
    },
  };


  return (
    <div className="px-5 py-3 flex flex-column h-full overflow-auto gap-3">
      <div className="grid">
        {statistics(totalCounts)?.map((stat) => (
          <div key={stat.title} className="md:col-4 col-12">
            <StatisticsCard item={stat} />
          </div>
        ))}
      </div>
      {/* <RecentTraceability  /> */}
      {/* <div className="grid mb-8">
          <div className="md:col-6 col-12">
          <RecentCtes />
        </div>
        <div className="md:col-6 col-12">
          <RecentProducts />
        </div>
         <div className="md:col-6 col-12">
          <RecentUsers/>
        </div>
         <div className="md:col-6 col-12">
          <RecentDataEntry/>
        </div>
      </div> */}
    </div>
  );
}
