/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUsers = /* GraphQL */ `mutation CreateUsers(
  $input: CreateUsersInput!
  $condition: ModelUsersConditionInput
) {
  createUsers(input: $input, condition: $condition) {
    email
    search_key
    first_name
    last_name
    phone
    role_id
    role {
      id
      name
      is_active
      createdAt
      updatedAt
      __typename
    }
    status
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUsersMutationVariables,
  APITypes.CreateUsersMutation
>;
export const updateUsers = /* GraphQL */ `mutation UpdateUsers(
  $input: UpdateUsersInput!
  $condition: ModelUsersConditionInput
) {
  updateUsers(input: $input, condition: $condition) {
    email
    search_key
    first_name
    last_name
    phone
    role_id
    role {
      id
      name
      is_active
      createdAt
      updatedAt
      __typename
    }
    status
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUsersMutationVariables,
  APITypes.UpdateUsersMutation
>;
export const deleteUsers = /* GraphQL */ `mutation DeleteUsers(
  $input: DeleteUsersInput!
  $condition: ModelUsersConditionInput
) {
  deleteUsers(input: $input, condition: $condition) {
    email
    search_key
    first_name
    last_name
    phone
    role_id
    role {
      id
      name
      is_active
      createdAt
      updatedAt
      __typename
    }
    status
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUsersMutationVariables,
  APITypes.DeleteUsersMutation
>;
export const createRoles = /* GraphQL */ `mutation CreateRoles(
  $input: CreateRolesInput!
  $condition: ModelRolesConditionInput
) {
  createRoles(input: $input, condition: $condition) {
    id
    name
    is_active
    privileges {
      id
      name
      selected
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRolesMutationVariables,
  APITypes.CreateRolesMutation
>;
export const updateRoles = /* GraphQL */ `mutation UpdateRoles(
  $input: UpdateRolesInput!
  $condition: ModelRolesConditionInput
) {
  updateRoles(input: $input, condition: $condition) {
    id
    name
    is_active
    privileges {
      id
      name
      selected
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRolesMutationVariables,
  APITypes.UpdateRolesMutation
>;
export const deleteRoles = /* GraphQL */ `mutation DeleteRoles(
  $input: DeleteRolesInput!
  $condition: ModelRolesConditionInput
) {
  deleteRoles(input: $input, condition: $condition) {
    id
    name
    is_active
    privileges {
      id
      name
      selected
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRolesMutationVariables,
  APITypes.DeleteRolesMutation
>;
export const createProducts = /* GraphQL */ `mutation CreateProducts(
  $input: CreateProductsInput!
  $condition: ModelProductsConditionInput
) {
  createProducts(input: $input, condition: $condition) {
    id
    name
    code
    variety_name
    variety_code
    traceability_flow_id
    traceability_flow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductsMutationVariables,
  APITypes.CreateProductsMutation
>;
export const updateProducts = /* GraphQL */ `mutation UpdateProducts(
  $input: UpdateProductsInput!
  $condition: ModelProductsConditionInput
) {
  updateProducts(input: $input, condition: $condition) {
    id
    name
    code
    variety_name
    variety_code
    traceability_flow_id
    traceability_flow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductsMutationVariables,
  APITypes.UpdateProductsMutation
>;
export const deleteProducts = /* GraphQL */ `mutation DeleteProducts(
  $input: DeleteProductsInput!
  $condition: ModelProductsConditionInput
) {
  deleteProducts(input: $input, condition: $condition) {
    id
    name
    code
    variety_name
    variety_code
    traceability_flow_id
    traceability_flow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    is_active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductsMutationVariables,
  APITypes.DeleteProductsMutation
>;
export const createCtes = /* GraphQL */ `mutation CreateCtes(
  $input: CreateCtesInput!
  $condition: ModelCtesConditionInput
) {
  createCtes(input: $input, condition: $condition) {
    id
    index
    name
    kde_metadata {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCtesMutationVariables,
  APITypes.CreateCtesMutation
>;
export const updateCtes = /* GraphQL */ `mutation UpdateCtes(
  $input: UpdateCtesInput!
  $condition: ModelCtesConditionInput
) {
  updateCtes(input: $input, condition: $condition) {
    id
    index
    name
    kde_metadata {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCtesMutationVariables,
  APITypes.UpdateCtesMutation
>;
export const deleteCtes = /* GraphQL */ `mutation DeleteCtes(
  $input: DeleteCtesInput!
  $condition: ModelCtesConditionInput
) {
  deleteCtes(input: $input, condition: $condition) {
    id
    index
    name
    kde_metadata {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCtesMutationVariables,
  APITypes.DeleteCtesMutation
>;
export const createTraceabilityFlow = /* GraphQL */ `mutation CreateTraceabilityFlow(
  $input: CreateTraceabilityFlowInput!
  $condition: ModelTraceabilityFlowConditionInput
) {
  createTraceabilityFlow(input: $input, condition: $condition) {
    id
    name
    is_active
    observers
    inputters
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTraceabilityFlowMutationVariables,
  APITypes.CreateTraceabilityFlowMutation
>;
export const updateTraceabilityFlow = /* GraphQL */ `mutation UpdateTraceabilityFlow(
  $input: UpdateTraceabilityFlowInput!
  $condition: ModelTraceabilityFlowConditionInput
) {
  updateTraceabilityFlow(input: $input, condition: $condition) {
    id
    name
    is_active
    observers
    inputters
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTraceabilityFlowMutationVariables,
  APITypes.UpdateTraceabilityFlowMutation
>;
export const deleteTraceabilityFlow = /* GraphQL */ `mutation DeleteTraceabilityFlow(
  $input: DeleteTraceabilityFlowInput!
  $condition: ModelTraceabilityFlowConditionInput
) {
  deleteTraceabilityFlow(input: $input, condition: $condition) {
    id
    name
    is_active
    observers
    inputters
    cteFlow {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTraceabilityFlowMutationVariables,
  APITypes.DeleteTraceabilityFlowMutation
>;
export const createCteFlow = /* GraphQL */ `mutation CreateCteFlow(
  $input: CreateCteFlowInput!
  $condition: ModelCteFlowConditionInput
) {
  createCteFlow(input: $input, condition: $condition) {
    id
    traceability_flow_id
    cte_id
    traceabilityFlow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    position
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCteFlowMutationVariables,
  APITypes.CreateCteFlowMutation
>;
export const updateCteFlow = /* GraphQL */ `mutation UpdateCteFlow(
  $input: UpdateCteFlowInput!
  $condition: ModelCteFlowConditionInput
) {
  updateCteFlow(input: $input, condition: $condition) {
    id
    traceability_flow_id
    cte_id
    traceabilityFlow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    position
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCteFlowMutationVariables,
  APITypes.UpdateCteFlowMutation
>;
export const deleteCteFlow = /* GraphQL */ `mutation DeleteCteFlow(
  $input: DeleteCteFlowInput!
  $condition: ModelCteFlowConditionInput
) {
  deleteCteFlow(input: $input, condition: $condition) {
    id
    traceability_flow_id
    cte_id
    traceabilityFlow {
      id
      name
      is_active
      observers
      inputters
      createdAt
      updatedAt
      __typename
    }
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    position
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCteFlowMutationVariables,
  APITypes.DeleteCteFlowMutation
>;
export const createBatch = /* GraphQL */ `mutation CreateBatch(
  $input: CreateBatchInput!
  $condition: ModelBatchConditionInput
) {
  createBatch(input: $input, condition: $condition) {
    id
    batch_number
    batch_date
    product_id
    status
    batchKdeData {
      nextToken
      __typename
    }
    product {
      id
      name
      code
      variety_name
      variety_code
      traceability_flow_id
      is_active
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBatchMutationVariables,
  APITypes.CreateBatchMutation
>;
export const updateBatch = /* GraphQL */ `mutation UpdateBatch(
  $input: UpdateBatchInput!
  $condition: ModelBatchConditionInput
) {
  updateBatch(input: $input, condition: $condition) {
    id
    batch_number
    batch_date
    product_id
    status
    batchKdeData {
      nextToken
      __typename
    }
    product {
      id
      name
      code
      variety_name
      variety_code
      traceability_flow_id
      is_active
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBatchMutationVariables,
  APITypes.UpdateBatchMutation
>;
export const deleteBatch = /* GraphQL */ `mutation DeleteBatch(
  $input: DeleteBatchInput!
  $condition: ModelBatchConditionInput
) {
  deleteBatch(input: $input, condition: $condition) {
    id
    batch_number
    batch_date
    product_id
    status
    batchKdeData {
      nextToken
      __typename
    }
    product {
      id
      name
      code
      variety_name
      variety_code
      traceability_flow_id
      is_active
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBatchMutationVariables,
  APITypes.DeleteBatchMutation
>;
export const createBatchKdeData = /* GraphQL */ `mutation CreateBatchKdeData(
  $input: CreateBatchKdeDataInput!
  $condition: ModelBatchKdeDataConditionInput
) {
  createBatchKdeData(input: $input, condition: $condition) {
    id
    batch_id
    batch_number
    inputted_by
    inputter_id
    cte_id
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    inputter {
      email
      search_key
      first_name
      last_name
      phone
      role_id
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
    position
    kdes {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBatchKdeDataMutationVariables,
  APITypes.CreateBatchKdeDataMutation
>;
export const updateBatchKdeData = /* GraphQL */ `mutation UpdateBatchKdeData(
  $input: UpdateBatchKdeDataInput!
  $condition: ModelBatchKdeDataConditionInput
) {
  updateBatchKdeData(input: $input, condition: $condition) {
    id
    batch_id
    batch_number
    inputted_by
    inputter_id
    cte_id
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    inputter {
      email
      search_key
      first_name
      last_name
      phone
      role_id
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
    position
    kdes {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBatchKdeDataMutationVariables,
  APITypes.UpdateBatchKdeDataMutation
>;
export const deleteBatchKdeData = /* GraphQL */ `mutation DeleteBatchKdeData(
  $input: DeleteBatchKdeDataInput!
  $condition: ModelBatchKdeDataConditionInput
) {
  deleteBatchKdeData(input: $input, condition: $condition) {
    id
    batch_id
    batch_number
    inputted_by
    inputter_id
    cte_id
    cte {
      id
      index
      name
      createdAt
      updatedAt
      __typename
    }
    inputter {
      email
      search_key
      first_name
      last_name
      phone
      role_id
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
    position
    kdes {
      index
      input_id
      is_required
      label
      place_holder
      default_value
      value
      input_type
      help_message
      options
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBatchKdeDataMutationVariables,
  APITypes.DeleteBatchKdeDataMutation
>;
