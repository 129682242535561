import React from "react";

import Container from "@/components/shared/Container";
import Header from "@/components/shared/Header";
import If from "@/components/shared/If";
import Table from "@/components/shared/Table";
import TableMenu from "@/components/shared/TableMenu";
import { roleColumns } from "@/constants/columns";
import { useResetPagination } from "@/hooks/useResetPagination";
import { filterByName } from "@/utils/filters";
import { useCombinedStores } from "@/zustand/stores";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const { convertArrayToCSV } = require('convert-array-to-csv');

export default function Roles() {
  //zustand hooks
  const ROLE_ACTION_TYPE = useCombinedStores( (state) => state.ROLE_ACTION_TYPE );
  const roles = useCombinedStores( (state) => state.roles );
  const role = useCombinedStores( (state) => state.role );
  const getRoles = useCombinedStores( (state) => state.getRoles );
  const isGetRolesLoading = useCombinedStores( (state) => state.isGetRolesLoading );
  const errorMessageRole = useCombinedStores( (state) => state.errorMessageRole );
  const resetActionTypeRole = useCombinedStores( (state) => state.resetActionTypeRole );
  const updateZustandStateRole = useCombinedStores( (state) => state.updateZustandStateRole );
 
  const navigate = useNavigate();

  const [showDelete, setShowDelete] = React.useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
  const [showSearch, toggleSearch] = React.useReducer((state) => !state, false);
  const [filter, setFilter] = React.useState<string>("");
  const resetPagination = useResetPagination(filter);
  const filteredRoles = filterByName(roles, filter, "name");

  const handleDelete = (id: any) => {
    // simulate delete
    setDeleteLoading(true);
    setTimeout(() => {
      setDeleteLoading(false);
      setShowDelete(false);
      toast.success("Role deleted successfully");
    }, 500);
  };

 React.useEffect(()=>{
  getRoles();
 },[]);

  return (
    <div>
      <Header
        title="Roles"
        onDownload={() => {
          const csv = convertArrayToCSV(roles);
          const a = document.createElement("a");
          a.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
          a.setAttribute("download", "roles.csv");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }}
        extraAction="Create Role"
        extraActionIcon="pi pi-plus"
        onExtraAction={() => {
          navigate("/admin/roles/new");
          updateZustandStateRole({role:{}});


        }}
        onSearch={toggleSearch}
        showSearch={showSearch}
        value={filter}
        onChange={(e: any) => setFilter(e.target.value)}
        onCloseSearch={() => {
          toggleSearch();
          setFilter("");
        }}
      />

      <Container loading={isGetRolesLoading}>
        <Table
          data={filteredRoles}
          columns={[
            ...roleColumns(),
            {
              name: "Active",
              selector: (row: any) => row.active,
              cell: (row: any) => (
                <span
                  className={`
                py-1 px-3 border-round
                bg-${row.is_active  ? "green" : "red"}-100
                text-${row.is_active  ? "green" : "red"}-600
                `}
                >
                  {row.is_active ? "ACTIVE" : "INACTIVE"}
                </span>
              )
            },
            {
              selector: (row: any) => row.id,
              grow: 1,
              center: true,
              cell: (row: any) => (
                <If
                  condition={deleteLoading && row.id === role.id}
                  then={<i className="pi pi-spin pi-spinner" />}
                  else={
                    <TableMenu
                      menuType="editDelete"
                      onEdit={() => {
                        updateZustandStateRole({role:row});
                        navigate(`/admin/roles/${row.id}`, { state: row });
                      }}
                      onDelete={() => {
                        updateZustandStateRole({role:row});
                        setShowDelete(true);
                      }}
                    />
                  }
                />
              ),
            },
          ]}
          resetPagination={resetPagination}
          emptyIcon="pi-sitemap"
          emptyMessage="There are no roles to show at the moment"
        />

        <ConfirmDialog
          visible={showDelete}
          onHide={() => setShowDelete(false)}
          message="Are you sure you want to delete this role?"
          header="Delete Role"
          accept={() => {
            handleDelete(role.id);
          }}
          reject={() => setShowDelete(false)}
        />
      </Container>
    </div>
  );
}
