import { StateCreator } from "zustand";

import {
  CreateUsersInput,
  DeleteUsersInput,
  ListUsersQueryVariables,
  UpdateUsersInput,
} from "@/graphql/API";
import * as customQueries from "@/graphql/customQueries";
import * as mutations from "@/graphql/mutations";
import * as queries from "@/graphql/queries";
import {
  amplifyCreateData,
  amplifyGetAllData,
  amplifyGetAllDataWithFilter,
  amplifyUpdateData,
} from "@/utils/amplify/api";
import { CombinedStoresTypes } from "@/zustand/types";
import {
  USER_ACTION_TYPES_ENUM,
  UserSliceInterface,
  UserType,
} from "@/zustand/types/userTypes";
import zukeeper from "zukeeper";
import { amplifySignUp } from "@/utils/amplify/auth";

export const createUserSlice: StateCreator<
  CombinedStoresTypes,
  [],
  [],
  UserSliceInterface
> = zukeeper((set, get) => ({
  USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.INITIAL_VALUE,

  isGetUsersLoading: false,
  isGetUserByEmailLoading: false,
  isAddUserLoading: false,
  isUpdateUserLoading: false,
  isDeleteUserLoading: false,
  errorMessageUser: "",
  nextTokenUser: "",

  user: {},
  users: [],

  resetActionTypeUser: () => {
    set({
      USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.INITIAL_VALUE,
    });
  },

  updateZustandStateUser: (object: Partial<UserSliceInterface>) => {
    set(object);
  },
  getUserByEmail: (email: string) => {

    set({
      isGetUserByEmailLoading: true,
      user: {},
    });

    const filterVariable: ListUsersQueryVariables = {
      limit: 10000,
      filter: {
        email: {
          eq: email,
        },
      },
    };

    amplifyGetAllDataWithFilter(queries.listUsers, filterVariable)
      .then((successValue) => {
        const { items, nextToken } = successValue.data.listUsers;


        if (items.length === 0) {
          set({
            isGetUserByEmailLoading: false,
            USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.GET_USER_BY_EMAIL_EMPTY,
          });
          return;
        } else if (items.length > 0) {
          set({
            isGetUserByEmailLoading: false,
            USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.GET_USER_BY_EMAIL_SUCCESS,
            user: items[0],
          });
          return;
        }

        set({
          isGetUserByEmailLoading: false,
          USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.GET_USER_BY_EMAIL_SUCCESS,
        });
      })
      .catch((errorValue) => {

        set({
          isGetUserByEmailLoading: false,
          USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.GET_USER_BY_EMAIL_ERROR,
        });
      });
  },
  getUsers: async () => {
    set({
      isGetUsersLoading: true,
    });


    try {
      const success = await amplifyGetAllData(customQueries.listUsers);
      const { items, nextToken } = success.data?.listUsers;
      set({
        isGetUsersLoading: false,
        errorMessageUser: "",
        USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.GET_USERS_SUCCESS,
        users: items,
        nextTokenUser: nextToken,
      });
    } catch (error) {
      set({
        isGetUsersLoading: false,
        USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.GET_USERS_ERROR,
        errorMessageUser: error.message,
      });
    }
  },

  addUser: async (newUser: CreateUsersInput) => {
    set({ isAddUserLoading: true });

    try {
      const newPassword =
        newUser?.email?.split("@")[0] + "@" + newUser?.first_name;

      // Sign up the user first
      const signupResponse = await amplifySignUp({
        username: newUser.email,
        password: newPassword,
      });


      //  create user data in the database
      const createDataResult = await amplifyCreateData(
        mutations.createUsers,
        newUser
      );

      set({
        isAddUserLoading: false,
        USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.ADD_USER_SUCCESS,
        user: createDataResult.data.createUsers,
      });
    } catch (error) {
      set({
        isAddUserLoading: false,
        USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.ADD_USER_ERROR,
        errorMessageUser: error.message,
      });
    }
  },

  updateUser: async (user: UpdateUsersInput) => {
    set({
      isUpdateUserLoading: true,
    });

    try {
      const successValue = await amplifyUpdateData(mutations.updateUsers, user);
      const result = successValue.data.updateUsers;
      const newUsers = updateUserStore(get().users, result);
      set({
        isUpdateUserLoading: false,
        USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.UPDATE_USER_SUCCESS,
        user: result,
        users: newUsers,
      });
      return result;
    } catch (error) {
      set({
        isUpdateUserLoading: false,
        USER_ACTION_TYPE: USER_ACTION_TYPES_ENUM.UPDATE_USER_ERROR,
        errorMessageUser: error.message,
      });
    }
  },

  updateUserStatus: async (id: string, status: true | false) => {},

  deleteUser: (user: DeleteUsersInput) => {},
}));

const updateUserStore = (items: UserType[], updatedItem: UserType) => {
  const index = items.findIndex((item) => {
    return item.email === updatedItem.email;
  });
  if (index == -1) return items;

  // Update the item in the local list
  const newitems = [...items];
  newitems[index] = updatedItem;
  return newitems;
};
