import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import If from "../../../components/shared/If";

interface Props {
  show: boolean;
  onHide: () => void;
  flow: any;
  onEdit: () => void;
}

export default function FlowDetails({ show, onHide, flow, onEdit }: Props) {
  /*
   *
   * //TODO: Replace data object with correct flow object
   *
   */

  const data = {
    ID: flow.id,
    Name: flow.name,
    Product: flow.product,
  };

  const observers = flow?.observers ?? [];

  const ctes = flow.ctes ?? [];

  return (
    <Dialog
      header="Traceability Flow Details"
      visible={show}
      onHide={onHide}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      blockScroll={true}
    >
      <section className="flex flex-column gap-3">
        {Object.values(data).map((value, index) => {
          return (
            <div
              key={index}
              className="flex flex-row justify-content-between align-items-center gap-5"
            >
              <span>{Object.keys(data)[index]}</span>
              <span>{value}</span>
            </div>
          );
        })}
      </section>

      <div className="border-none w-full mt-5 mb-4 p-1 border-round bg-gray-400 align-self-center" />



      <section>

        <h3 className="font-bold">OBSERVERS</h3>

        <If
          condition={observers.length === 0}
          then={<p>No observers assigned</p>}
          else={
            <div className="flex flex-column gap-3">
              {observers.map((observer, index) => {
                return (
                  <div key={index} className="flex flex-row gap-1">
                    <span>{index + 1}.</span>
                    <span>{observer}</span>
                  </div>
                );
              })}
            </div>
          }
        />

        <h3 className="font-bold">TRACEABILITY FLOW</h3>
        <If
          condition={ctes.length === 0}
          then={<p>No CTEs assigned</p>}
          else={
            <div className="flex flex-column gap-3">
              {ctes.map((cte, index) => {
                return (
                  <div key={index} className="flex flex-row gap-1">
                    <span>{index + 1}.</span>
                    <span>{cte}</span>
                  </div>
                );
              })}
            </div>
          }
        />
      </section>

      <div className="flex flex-row justify-content-center mt-5">
        <Button
          className="align-self-center p-button-outlined"
          label="Edit Traceability Flow"
          icon="pi pi-pencil"
          onClick={onEdit}
        />
      </div>
    </Dialog>
  );
}
