export const loginInitialValues = {
  email: "",
  password: "",
};



export const verifyOtpInitialValues = {
  otp: "",
  password: "",
};

export const changePasswordInitialValues = {
  old_password: "",
  new_password: "",
  confirm_password: "",
};
