import { TieredMenu } from "primereact/tieredmenu";
import React from "react";

import Header from "@/components/shared/Header";
import Table from "@/components/shared/Table";
import UserDetails from "@/pages/app/users/UserDetails";
import EditUser from "./CreateEditUser";

import Container from "@/components/shared/Container";
import { userColumns } from "@/constants/columns";
import { useResetPagination } from "@/hooks/useResetPagination";
import { filterUsers } from "@/utils/filters";
import { usersFilterMenu } from "@/utils/usersFilterMenu";
import { toast } from "react-toastify";
import ResetPassword from "./ResetPassword";

import { useCombinedStores } from "@/zustand/stores/index";
import { USER_ACTION_TYPES_ENUM } from "@/zustand/types/userTypes";

const { convertArrayToCSV } = require('convert-array-to-csv');

export default function ManageUsers() {
  //zustand hooks
  const getUsers = useCombinedStores( (state) => state.getUsers );
  const getRoles = useCombinedStores( (state) => state.getRoles );
  const isGetUsersLoading = useCombinedStores( (state) => state.isGetUsersLoading );
  const users = useCombinedStores( (state) => state.users );
  const user = useCombinedStores( (state) => state.user );
  const USER_ACTION_TYPE  = useCombinedStores((state) => state.USER_ACTION_TYPE);
  const errorMessageUser = useCombinedStores( (state) => state.errorMessageUser);
  const resetActionTypeUser = useCombinedStores( (state) => state.resetActionTypeUser );
  const updateZustandStateUser = useCombinedStores( (state) => state.updateZustandStateUser );

  const menu = React.useRef<TieredMenu>(null);

  const [filter, setFilter] = React.useState<string>("");
  const [showSearch, toggleSearch] = React.useReducer((s) => !s, false);
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const [showView, setShowView] = React.useState<boolean>(false);
  const [showResetPassword, setShowResetPassword] =
    React.useState<boolean>(false);
  const resetPagination = useResetPagination(filter);
  const filteredUsers = filterUsers(users, filter);



  React.useEffect(() => {
  
    getUsers();
    getRoles()
  }, []);

  React.useEffect(()=>{
    switch (USER_ACTION_TYPE) {
      case USER_ACTION_TYPES_ENUM.ADD_USER_SUCCESS:
        // toast.success("Login successful");
        resetActionTypeUser();
        break;
      case USER_ACTION_TYPES_ENUM.ADD_USER_ERROR:
        toast.error(errorMessageUser);
        resetActionTypeUser();
        break;
    
      default:
        break;
    }
  }, [USER_ACTION_TYPE]);



  return (
    <div>
      <Header
        title={"User Management"}
        showSearch={showSearch}
        onDownload={() => {
          const csv = convertArrayToCSV(users);
          const a = document.createElement("a");
          a.href = `data:text/csv;charset=utf-8,${csv}`;
          a.download = "users.csv";
          a.click();
        }}
        onSearch={toggleSearch}
        onCloseSearch={() => {
          toggleSearch();
          setFilter("");
        }}
        extraAction="Add User"
        extraActionIcon="pi pi-plus"
        onExtraAction={() => {
          updateZustandStateUser({
            user: {}
          })
          setShowEdit(true);
        }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        menuComponent={
          <TieredMenu
            ref={menu}
            popup
            model={usersFilterMenu(setFilter)}
            breakpoint="767px"
          />
        }
      />

      <Container loading={isGetUsersLoading}>
        <Table
          // @ts-ignore
          data={filteredUsers}
          columns={userColumns(
            setShowEdit,
            setShowView,
            setShowResetPassword,
            updateZustandStateUser
          )}
          resetPagination={resetPagination}
          emptyIcon="pi-users"
          emptyMessage="No users found at the moment"
        />

        <UserDetails
          show={showView}
          onHide={() => setShowView(false)}
          onEdit={() => {
            setShowResetPassword(false);
            setShowView(false);
            setShowEdit(true);
          }}
        />

        <EditUser
          show={showEdit}
          onHide={() => setShowEdit(false)}
          setShowEdit={setShowEdit}
        />

        <ResetPassword
          show={showResetPassword}
          onHide={() => setShowResetPassword(false)}
        />
      </Container>
    </div>
  );
}
