import React from "react";
import { useFormik, FormikProvider } from "formik";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordValidationSchema } from "@constants/validationSchema";
import { IForgotPassword } from "@constants/interfaces";
import Input from "@/components/forms/Input";
import AuthLayout from "@/layouts/AuthLayout";

import { useCombinedStores } from "../../zustand/stores/index";
import { AUTH_ACTION_TYPES_ENUM } from "@/zustand/types/authTypes";

export default function ForgotPassword() {
  //zustand hooks
  const AUTH_ACTION_TYPE = useCombinedStores((state) => state.AUTH_ACTION_TYPE);
  const forgotPassword = useCombinedStores( (state) => state.forgotPassword );
  const isForgotPasswordLoading = useCombinedStores( (state) => state.isForgotPasswordLoading );
  const errorMessageAuth = useCombinedStores( (state) => state.errorMessageAuth );
  const reseActionTypeAuth = useCombinedStores( (state) => state.reseActionTypeAuth );


  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email:""
    },
    validationSchema: ForgotPasswordValidationSchema,
    onSubmit: (values: IForgotPassword) => {

      forgotPassword(values.email)
      
    },
  });

  const { handleSubmit } = formik;

  React.useEffect(()=>{
    switch (AUTH_ACTION_TYPE) {
      case AUTH_ACTION_TYPES_ENUM.FORGOT_PASSWORD_SUCCESS:
        toast.success("Password reset link sent to your email");
        navigate("/admin/verify-otp")

        reseActionTypeAuth();
        break;
      case AUTH_ACTION_TYPES_ENUM.FORGOT_PASSWORD_ERROR:
        toast.error(errorMessageAuth);
        reseActionTypeAuth();
        break;
    
      default:
        break;
    }
  },[AUTH_ACTION_TYPE]);

  return (
    <AuthLayout>
      <div className="flex flex-column gap-4">
        <div>
          <Button
            className="text-white border-circle"
            icon="pi pi-arrow-left"
            onClick={() => navigate(-1)}
          />
        </div>

        <section>
          <h1>Forgot Password</h1>

          <p>
            Enter the email address associated with your account, and we’ll
            email you an OTP to reset your password.
          </p>
        </section>

        <FormikProvider value={formik}>
          <div className="flex flex-column gap-5">
            <Input
              icon="pi pi-envelope"
              label="Email"
              id="email"
              placeholder="example@company.com"
              isRequired
            />

            <Button
              loading={isForgotPasswordLoading}
              label="Send OTP"
              className="w-full text-white"
              disabled={!formik.isValid}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                handleSubmit();
              }}
            />
          </div>
        </FormikProvider>
      </div>
    </AuthLayout>
  );
}
