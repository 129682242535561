import { batchKdeData } from "@/graphql/API";
import React from "react";

interface Props {
  data: batchKdeData[];
}

export default function Timeline({ data }: Props) {
  return (
    <React.Fragment>
      {data?.map((item, index) => {
        return (
          <div key={index.toString()} className=" flex gap-3 w-full">
            <section className="flex flex-column align-items-center">
              <div
                className={`flex align-items-center justify-content-center text-white border-circle bg-green-500`}
              >
                <i className={"pi pi-reply p-2"} />
              </div>

              <div
                className={`bg-gray-300 ${
                  data.length - 1 === index ? "h-0" : "h-full"
                } `}
                style={{
                  width: 1.5,
                }}
              />
            </section>

            <section
              className=" border-1 border-gray-400 border-round flex flex-column gap-2 mb-4 w-full"
              style={{
                marginBottom: data.length - 1 === index ? "0" : "4rem",
              }}
            >
              <div className="flex flex-column gap-2 p-3">
                <span className="text-lg font-bold">{item?.cte?.name}</span>

                <span className="text-sm">Recorded by {item?.inputted_by ?? "N/A"}</span>
              </div>

              <div className="bg-gray-100 p-3 border-round-bottom">
                <span>
                  <i className="pi pi-clock mr-2 text-sm " />
                  <span className="text-sm">{item?.createdAt ?? "N/A"}</span>
                </span>
              </div>
            </section>
          </div>
        );
      })}
    </React.Fragment>
  );
}
