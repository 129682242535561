import {
    signIn, type SignInInput, type SignInOutput, signOut, getCurrentUser, type AuthUser, resetPassword, type ResetPasswordOutput, type ResetPasswordInput, confirmResetPassword, type ConfirmResetPasswordInput,
    updatePassword, type UpdatePasswordInput,
    signUp, type SignUpInput, SignUpOutput,
    confirmSignUp, type ConfirmSignUpInput, ConfirmSignUpOutput,
    confirmSignIn, type ConfirmSignInInput, ConfirmSignInOutput,
} from 'aws-amplify/auth';

/**
 * You pass an EMAIL and password to the signIn API to sign-in your end-users.
 * @param param0 
 * @returns 
 */
export async function amplifySignUp({ username, password }: SignUpInput): Promise<SignUpOutput> {
    const results: SignUpOutput = await signUp({ username, password });
    return results;
}

/**
 * You pass an EMAIL and password to the signIn API to sign-in your end-users.
 * @param param0 
 * @returns 
 */
export async function amplifySignIn({ username, password }: SignInInput): Promise<SignInOutput> {
    const results: SignInOutput = await signIn({ username, password });
    return results;
}

/**
 * To sign a user out of your application you can use the signOut API.
 */
export async function amplifySignOut(): Promise<void> {
    await signOut();

}

/**
 * You can use the getCurrentUser API to get information about the currently 
 * authenticated user including the username, userId and signInDetails.
 * @returns 
 */
export async function amplifyGetAuthenticatedUser(): Promise<AuthUser> {
    return await getCurrentUser();

}
/**
 * 
 * To reset a user's password, use the resetPassword API 
 * which will send a reset code to the destination (e.g. email or SMS) based on the user's settings.
 * @returns 
 */
export async function amplifyForgotPassword(username: string): Promise<ResetPasswordOutput> {

    const results: ResetPasswordOutput = await resetPassword({ username });

    switch (results.nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
            const codeDeliveryDetails = results.nextStep.codeDeliveryDetails;
            // Collect the confirmation code from the user and pass to confirmResetPassword.
            break;
        case 'DONE':
            break;
    }
    return results;
}

/**
 * 
 * To complete the password reset process, invoke the confirmResetPassword 
 * API with the code your user received and the new password they want to set.
 */
export async function amplifyConfirmResetPassword({
    username,
    confirmationCode,
    newPassword
}: ConfirmResetPasswordInput) {
    await confirmResetPassword({ username, confirmationCode, newPassword });

}


/**
 * You can update a signed in user's password using the updatePassword API.
 * @param param0 
 */
export async function amplifyUpdatePassword({
    oldPassword,
    newPassword
}: UpdatePasswordInput) {
    await updatePassword({ oldPassword, newPassword });

}

/**
 * 
 * By default, each user that signs up remains 
 * in the unconfirmed status until they verify 
 * with a confirmation code that was sent to their 
 * email or phone number. You can confirm the sign-up 
 * after retrieving a confirmation code from the user as shown here.
 */
export async function amplifyConfirmSignUp({
    username,
    confirmationCode
}: ConfirmSignUpInput): Promise<ConfirmSignUpOutput> {
    return await confirmSignUp({
        username,
        confirmationCode
    });

}

/**
 * 
 * Creating a new user in the AWS console will
 * require them to change the password before sigining in.
 * 
 */
export async function amplifyConfirmSignIn({
    newpassword,
    options={}
}): Promise<ConfirmSignInOutput> {
    return await confirmSignIn({
        challengeResponse: newpassword,
        options: options
    });

}