import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import If from "../../../components/shared/If";
import { useFormik, FormikProvider } from "formik";
import Password from "@/components/forms/Password";
import { toast } from "react-toastify";
import { useCombinedStores } from "@/zustand/stores/index";

interface Props {
  show: boolean;
  onHide: () => void;
}

export default function ResetPassword({ show, onHide }: Props) {
  const user = useCombinedStores( (state) => state.user );

  const data = {
    name: user?.first_name +" "+user?.last_name,
    email: user?.email,
    id: user?.id,
  };

  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate(values) {
      const errors: any = {};
      if (!values.password) {
        errors.password = "Required";
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = "Required";
      }
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Password does not match";
      }
      return errors;
    },
    onSubmit(values) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        toast.success("Password reset successful");
        onHide();
      }, 500);
    },
  });

  const { values, handleSubmit } = formik;

  return (
    <Dialog
      header="Reset User Password"
      visible={show}
      onHide={onHide}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      blockScroll={true}
    >
      <div className="flex flex-column gap-4">
        <p>
          Are you sure you want to reset password for {data.name} ({data.email}
          )?
        </p>

        <FormikProvider value={formik}>
          <div className="flex flex-column gap-5">
            <Password
              lockicon
              id="password"
              label="Password"
              placeholder="Enter a new password"
            />

            <Password
              lockicon
              id="confirmPassword"
              label="Confrim Password"
              placeholder="Re-enter the password"
              feedback={false}
            />

            <Button
              className="mt-5 mb-4"
              label="Reset Password"
              loading={loading}
              disabled={!formik.isValid}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                handleSubmit();
              }}
            />
          </div>
        </FormikProvider>
      </div>
    </Dialog>
  );
}
